import * as ActionTypes from '../action-types'
import Http from '../../Http'

const initialState = {
    isAuthenticated: false,
    isVerify: false,
    isForget: false,
    needToVerify: false,
    isReset: false,
    hasAlert: false,
    alertMessage: '',
    isAdministrator: false,
    subDomainUrl: '',
    token: '',
    changeMobileToken: '',
    userData: {},
    // stripe:{}
};

const Auth = (state = initialState, { type, payload = null }) => {
    switch (type) {
        case ActionTypes.AUTH_LOGIN:
            return authLogin(state, payload);
        case ActionTypes.AUTH_CHECK:
            return checkAuth(state);
        case ActionTypes.AUTH_LOGOUT:
            return logout(state);
        case ActionTypes.AUTH_VERIFY:
            return authVerify(state, payload);
        case ActionTypes.AUTH_VERIFY_STATUS:
            return checkVerificationStatus(state, payload);
        case ActionTypes.AUTH_FORGOT:
            return authForgot(state, payload);
        case ActionTypes.CHANGE_MOBILE:
            return changeMobile(state, payload);
        case ActionTypes.SET_CARD:
            return setCard(state, payload);
        case ActionTypes.SET_USERDATA:
            return setUserData(state, payload);
        case ActionTypes.SET_CARTDATA:
            return setCartData(state, payload);
        case ActionTypes.SET_NOTIFICATIONDATA:
            return setNotificationData(state, payload);
        case ActionTypes.SET_SEARCHDATA:
            return setSearchData(state, payload);
        case ActionTypes.SET_CHARITY_DATA:
            return setCharityDetailData(state, payload);
        // case ActionTypes.SET_STRIPEKEY:
        //     return setStripeKey(state, payload);
        default:
            return state;
    }
};

const authLogin = (state, payload) => {
    const jwtToken = payload.data.accessToken;
    const jwtType = payload.data.tokenType;

    localStorage.setItem('jwt_token', jwtToken);
    localStorage.setItem('jwt_type', jwtType);

    if (jwtType === 'auth') {
        Http.setBearerToken(jwtToken);
    } else {
        Http.setVerifyToken(jwtToken);
    }
    state = Object.assign({}, state, {
        isAuthenticated: true,
        isVerify: !!payload.data.is_verify,
        needToVerify: payload.data.is_verify ? false : true,
        isAdministrator: !!payload.data.is_administrator,
        userData: payload.data,
        subDomainUrl: payload.data.subdomain_url,
        token: payload.data.accessToken,
    });
    return state;

};

const authForgot = (state, payload) => {
    const jwtToken = payload.data.token;
    const jwtType = payload.data.tokenType;

    localStorage.setItem('jwt_token', jwtToken);
    localStorage.setItem('jwt_type', jwtType);

    if (jwtType === 'auth') {
        Http.setBearerToken(jwtToken);
    } else {
        Http.setVerifyToken(jwtToken);
    }

    state = Object.assign({}, state, {
        isAuthenticated: true,
        isVerify: false,
        token: payload.data.token,
    });
    return state;

};
const changeMobile = (state, payload) => {
    const jwtToken = payload.data.token;
    const jwtType = payload.data.tokenType;

    localStorage.setItem('jwt_token', jwtToken);
    localStorage.setItem('jwt_type', jwtType);
    if (jwtType === 'auth') {
        Http.setBearerToken(jwtToken);
    } else {
        Http.setVerifyToken(jwtToken);
    }
    state = Object.assign({}, state, {
        isAuthenticated: true,
        changeMobileToken: jwtToken,
    });
    return state;

};

const checkAuth = (state) => {
    const urlParams = new URLSearchParams(window.location.search);
    const token = urlParams.get('token')

    if (token != '' && token != null) {
        const jwtToken = token;
        const jwtType = 'verify';

        localStorage.setItem('jwt_token', jwtToken);
        localStorage.setItem('jwt_type', jwtType);

        state = Object.assign({}, state, {
            isVerify: true,
            isAuthenticated: !!localStorage.getItem('jwt_token'),
        });
    } else {
        state = Object.assign({}, state, {
            isAuthenticated: !!localStorage.getItem('jwt_token'),
        });
    }

    if (state.isAuthenticated) {
        if (localStorage.getItem('jwt_type') == 'verify' || localStorage.getItem('jwt_type') == 'forgot') {
            Http.setVerifyToken(`${localStorage.getItem('jwt_token')}`);
        } else {
            Http.setBearerToken(`${localStorage.getItem('jwt_token')}`);
        }
    }
    return state;
};

const logout = (state) => {
    localStorage.removeItem('jwt_token');
    localStorage.removeItem('token');
    localStorage.removeItem('persist:root')
    localStorage.clear();
    state = Object.assign({}, state, initialState);
    return state;
};

const authVerify = (state, payload) => {
    if (localStorage.getItem('jwt_type') === 'verify') {
        const jwtToken = payload.data.accessToken;
        const jwtType = payload.data.tokenType;

        localStorage.setItem('jwt_token', jwtToken);
        localStorage.setItem('jwt_type', jwtType);

        if (jwtType === 'auth') {
            Http.setBearerToken(jwtToken);
        } else {
            Http.setVerifyToken(jwtToken);
        }

        state = Object.assign({}, state, {
            isAuthenticated: true,
            isVerify: true,
            needToVerify: payload.data.is_verify ? false : true,
            isAdministrator: !!payload.data.is_administrator,
            userData: payload.data,
        });

    } else if (localStorage.getItem('jwt_type') === 'change_mobile') {

        const jwtToken = payload.data.accessToken;
        const jwtType = payload.data.token_type;

        localStorage.setItem('jwt_token', jwtToken);
        localStorage.setItem('jwt_type', jwtType);

        if (jwtType === 'auth') {
            Http.setBearerToken(jwtToken);
        } else {
            Http.setVerifyToken(jwtToken);
        }

        state = Object.assign({}, state, {
            isVerify: true,
            needToVerify: payload.data.is_verify ? false : true,
            userData: payload.data,
        });

    } else {
        state = Object.assign({}, state, {
            isVerify: false,
        });
    }
    return state;
};

const checkVerificationStatus = (state, payload) => {
    let updatedValue = {};
    if (payload) {
        updatedValue = {
            isVerify: true
        }
    } else {
        updatedValue = {
            isVerify: false,
            isAuthenticated: true
        }
    }
    state = { ...state, ...updatedValue };
    return state;
};

const setCard = (state, payload) => {
    state = Object.assign({}, state, {
        selectedCard: payload.id
    });

    return state;
};

const setUserData = (state, payload) => {
    state = Object.assign({}, state, {
        userData: payload.data,
    });
    return state;
}

const setCartData = (state, payload) => {
    state = Object.assign({}, state, {
        cartItems: payload.data.cart_data,
        billDetail: payload.data.BillDetails
    });
    return state;
}

const setNotificationData = (state, payload) => {
    let totalData = payload.data;
    let notificationDatats = payload.data.filter((data) => {
        if (data.isRead == 0) {
            return data;
        }
    })
    state = Object.assign({}, state, {
        notificationDatas: notificationDatats,
        totalData: totalData
    });
    return state;
}

const setSearchData = (state, payload) => {
    state = Object.assign({}, state, {
        searchData: payload.data
    });
    return state;
}
const setCharityDetailData = (state, payload) => {
    state = Object.assign({}, state, {
        charityDetailData: payload
    });
    return state;
}

export default Auth;
