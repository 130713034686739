import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { connect, useDispatch } from 'react-redux';
import AuthService from '../../services/auth';
import { Location, Message } from 'iconsax-react';
import { Button, Input } from 'antd';
import ToastMe from '../../common/ToastMe';
import eventService from '../../services/events';

const OrderFooter = (props) => {
    const dispatch = useDispatch();
    const [data, setData] = useState();
    const location = useLocation();
    const [subscribeEmail, setSubscribemail] = useState("");

    const getAdminSetting = () => {
        dispatch(AuthService.getAdminSetting())
            .then((res) => {
                setData(res.data)
            })
            .catch((errors) => {
                console.log({ errors })
            })
    }

    const getValue = (e) => {
        console.log(e.target.value)
        e.preventDefault();
        setSubscribemail(e.target.value);
    }
    useEffect(() => {
        getAdminSetting();
    }, []);

    const addSubscriptions = (e) => {
        e.preventDefault();
        if (subscribeEmail == '') {
            ToastMe('Please Enter EmailId', 'error')
        } else {
            let data = {
                email: subscribeEmail
            }
            dispatch(eventService.addSubscriptions(data))
                .then((res) => {
                    ToastMe("Subscription Added", 'success')
                    setSubscribemail("");
                    //    form.setFieldsValue({
                    //         email:""
                    //     })
                })
                .catch((errors) => {
                    ToastMe(errors.errorData.errors.email, 'error')
                })
        }

    }

    return (
        <>
            <footer className="no-bottom footer">
                <div className="container">
                    <div className="footer-upper">
                        <div className="row gy-5">
                            <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6">
                                <div className="footer_menu_wrapper">
                                    <h4 className='menu_title'>Company</h4>
                                    <div className="menu">
                                        <ul>
                                            <li>
                                                <Link to={"/download-app"}>
                                                    App Download
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to={"/about-us"}>
                                                    About Binox
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to={"/become-partner"}>
                                                    Partner with us
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to={"/order"}>
                                                    See all offers
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to={"/contact"}>
                                                    Contact Us
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to={"/faq"}>
                                                    FAQ
                                                </Link>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6">
                                <div className="footer_menu_wrapper">
                                    <h4 className='menu_title'>Useful links</h4>
                                    <div className="menu">
                                        <ul>
                                            <li>
                                                <Link to={"/"}>
                                                    Find Services
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to={"/"}>
                                                    Home
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to={"/categories"} state={{ flug: "food" }}>
                                                    Restaurants
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to={"/categories"} state={{ flug: "grocery" }}>
                                                    Groceries
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to={"/events"}>
                                                    Events
                                                </Link>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6">
                                <div className="footer_menu_wrapper">
                                    <h4 className='menu_title'>Connect</h4>
                                    <div className="menu">
                                        <ul>
                                            <li>
                                                <Link to="https://www.facebook.com/BinoxBargains" target={'_blank'}>
                                                    Facebook
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to="https://instagram.com/binox_bargains?igshid=NTc4MTIwNjQ2YQ==" target={'_blank'}>
                                                    Instagram
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to="https://twitter.com/binoxbargains?s=21&t=yzOOdLSiMrpGs9qS1QC18Q" target={'_blank'}>
                                                    Twitter
                                                </Link>
                                            </li>
                                            {/* <li>
                                                <Link to="https://www.linkedin.com/" target={'_blank'}>
                                                    LinkedIn
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to="https://www.linkedin.com/" target={'_blank'}>
                                                    YouTube
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to="https://www.linkedin.com/" target={'_blank'}>
                                                    TikTok
                                                </Link>
                                            </li> */}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6">
                                <div className="footer_menu_wrapper">
                                    <h4 className='menu_title'>Legal</h4>
                                    <div className="menu">
                                        <ul>
                                            <li>
                                                <Link to="/terms-and-conditions">
                                                    Terms & conditions
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to="/privacy-policy">
                                                    Privacy Policy
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to="/return-policy">
                                                    Returns policy
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to="/eula">
                                                    EULA
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to="/use-acceptable-policy">
                                                    Acceptable use policy
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to="/disclaimer">
                                                    Disclaimer
                                                </Link>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            {/* <div className="col-lg-3 col-xl-3 col-md-12 col-sm-6">
                                <div className="footer_menu_wrapper">
                                    <h4 className='menu_title'>Contacts</h4>
                                    <div className="menu">
                                        <ul>
                                            <li>
                                                <Link to={""} className='flex_item_ss'>
                                                    <span className='icon flex-shrink-0'><svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <g clip-path="url(#clip0_4988_8908)">
                                                            <path d="M20.6144 7.19024C19.4876 2.2322 15.1627 0 11.3637 0H11.353C7.56469 0 3.22908 2.22146 2.10225 7.17951C0.846644 12.7171 4.23786 17.4068 7.30713 20.358C8.39503 21.411 9.84969 21.9998 11.3637 22C12.8232 22 14.2827 21.4527 15.4096 20.358C18.4788 17.4068 21.8701 12.7278 20.6144 7.19024ZM11.3637 12.5668C10.9198 12.5668 10.4802 12.4794 10.0701 12.3095C9.65992 12.1396 9.28726 11.8906 8.97335 11.5767C8.65944 11.2628 8.41044 10.8901 8.24055 10.48C8.07067 10.0699 7.98323 9.63027 7.98323 9.18634C7.98323 8.74241 8.07067 8.30282 8.24055 7.89269C8.41044 7.48255 8.65944 7.10988 8.97335 6.79598C9.28726 6.48207 9.65992 6.23306 10.0701 6.06318C10.4802 5.89329 10.9198 5.80585 11.3637 5.80585C12.2603 5.80585 13.1201 6.16201 13.7541 6.79598C14.388 7.42994 14.7442 8.28978 14.7442 9.18634C14.7442 10.0829 14.388 10.9427 13.7541 11.5767C13.1201 12.2107 12.2603 12.5668 11.3637 12.5668Z" fill="#818181" />
                                                        </g>
                                                        <defs>
                                                            <clipPath id="clip0_4988_8908">
                                                                <rect width="22" height="22" fill="white" />
                                                            </clipPath>
                                                        </defs>
                                                    </svg>
                                                    </span>
                                                    85 Great Portland Street, London W1W 7LT
                                                </Link>
                                            </li>

                                            <li>
                                                {
                                                    data?.email &&
                                                    <Link to={`mailto:${data?.email}`} style={{ cursor: "pointer" }} className='flex_item_ss'>
                                                        <span className='icon flex-shrink-0'>
                                                            <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <g clip-path="url(#clip0_4988_8913)">
                                                                    <path d="M12.8386 13.4793C12.2913 13.8442 11.6555 14.0371 11 14.0371C10.3446 14.0371 9.70879 13.8442 9.16145 13.4793L0.14648 7.46913C0.0964648 7.43579 0.0477383 7.40103 0 7.36528V17.2136C0 18.3427 0.916309 19.2388 2.02525 19.2388H19.9747C21.1038 19.2388 22 18.3225 22 17.2136V7.36523C21.9521 7.40107 21.9033 7.43592 21.8532 7.46931L12.8386 13.4793Z" fill="#818181" />
                                                                    <path d="M0.861523 6.39653L9.8765 12.4067C10.2178 12.6343 10.6089 12.748 11 12.748C11.3911 12.748 11.7822 12.6342 12.1235 12.4067L21.1385 6.39653C21.6779 6.0371 22 5.43554 22 4.78628C22 3.66991 21.0918 2.76172 19.9754 2.76172H2.02456C0.908231 2.76176 0 3.66995 0 4.78735C0 5.43554 0.322094 6.0371 0.861523 6.39653Z" fill="#818181" />
                                                                </g>
                                                                <defs>
                                                                    <clipPath id="clip0_4988_8913">
                                                                        <rect width="22" height="22" fill="white" />
                                                                    </clipPath>
                                                                </defs>
                                                            </svg>
                                                        </span>
                                                        support@binoxbargains.com
                                                    </Link>
                                                }

                                            </li>
                                            <li>
                                                {
                                                    data?.phone &&
                                                    <Link to={`tel:${data?.phone}`} style={{ cursor: "pointer" }} className='flex_item_ss'>
                                                        <span className='icon flex-shrink-0'>
                                                            <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <g clip-path="url(#clip0_4988_8920)">
                                                                    <path d="M21.4016 16.1458L18.3314 13.0756C17.2349 11.9791 15.3709 12.4178 14.9323 13.8432C14.6033 14.83 13.5068 15.3783 12.52 15.1589C10.327 14.6107 7.36651 11.7598 6.81827 9.45721C6.48932 8.47033 7.14722 7.37384 8.13406 7.04493C9.55949 6.60634 9.99808 4.74231 8.9016 3.64582L5.83143 0.575656C4.95424 -0.191885 3.63846 -0.191885 2.87091 0.575656L0.787588 2.65898C-1.29574 4.85196 1.00689 10.6633 6.16038 15.8168C11.3139 20.9703 17.1253 23.3826 19.3182 21.1896L21.4016 19.1063C22.1691 18.2291 22.1691 16.9133 21.4016 16.1458Z" fill="#818181" />
                                                                </g>
                                                                <defs>
                                                                    <clipPath id="clip0_4988_8920">
                                                                        <rect width="22" height="22" fill="white" />
                                                                    </clipPath>
                                                                </defs>
                                                            </svg>
                                                        </span>
                                                        02031372886
                                                    </Link>
                                                }
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div> */}
                            <div className="col-xl-3 col-lg-6 col-md-12 col-12 d-sm-block d-none">
                                <div className="footer_menu_wrapper">
                                    <h4 className='menu_title'>Newsletter</h4>
                                    <div className="menu">
                                        <ul>
                                            <li>
                                                <div className="search_item_form subscribe_form">
                                                    <div className="form-group mb-0">
                                                        <label htmlFor="" className='mb-2'>Join our weekly mailing list.</label>
                                                        <Input value={subscribeEmail} name="email" placeholder="Your Mail" onChange={(e) => getValue(e)} suffix={<Button className="button button-2" onClick={(e) => addSubscriptions(e)} >Subscribe
                                                        </Button>} className="form-control" />
                                                    </div>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* <div className="col-lg-4 col-xl-5 col-md-6">
                            <div className="footer-description">
                                <a href="index.html">
                                    <img src={require('../../../assets/img/logo_dark.png')} width={"154px"} height={"auto"} />
                                </a>
                                <h2>The Best Restaurants
                                    in Your Home</h2>
                                <p>Get the best deals with a few clicks and discover thousands of discounted products to support and put a smile on your face</p>
                            </div>
                        </div>
                        <div className="col-lg-3 col-xl-2 col-md-6 ">
                            <div className="menu ">
                                <h4>Menu</h4>
                                <ul className="footer-menu">
                                    <li><Link to="/">Home</Link></li>
                                    <li className={location.hash == "#howItWorks" ? "active" : ""}>
                                        <a href="/about-us">About Us</a>
                                    </li>
                                    <li className={location.hash == "#appJourney" ? "active" : ""}>
                                        <Link to="/categories" state={{ flug: "food" }}>Restaurants</Link>
                                    </li>
                                    <li><Link to="/contact">Contacts</Link>
                                    </li>
                                    <li><Link to="/become-partner">Partner with us</Link>
                                    </li>
                                </ul>
                            </div>
                        </div> */}
                        {/* <div className="col-lg-3 col-xl-2 col-md-6 col-sm-12">
                            <div className="menu mt-5 mt-lg-0">
                                <h4>Menu</h4>
                                <ul className="footer-menu">
                                    <li><Link to="/privacy-policy">Privacy Policy<i className="fa-solid fa-arrow-right" /></Link></li>
                                    <li><Link to="/terms-and-conditions">Terms &amp; Conditions<i className="fa-solid fa-arrow-right" /></Link></li>
                                    <li><Link to="/return-policy">Return Policy<i className="fa-solid fa-arrow-right" /></Link></li>
                                    <li><Link to="/cookies-policy">Cookies Policy<i className="fa-solid fa-arrow-right" /></Link></li>
                                    <li><Link to="/disclaimer">Disclaimer<i className="fa-solid fa-arrow-right" /></Link></li>
                                    <li><Link to="/eula">EULA<i className="fa-solid fa-arrow-right" /></Link></li>
                                </ul>
                            </div>
                        </div> */}
                        {/* <div className="col-xl-5 col-lg-5 col-md-12 ">
                                <div className="menu contacts">
                                    <h4 >Contacts</h4>
                                    <ul className='contact_us_details_list'>
                                        <li className='flex_item_ss'>
                                        <img src={require("../../../assets/img/icn_location2.svg").default} alt='' className='flex-shrink-0' />
                                        1717 Harrison St, San Francisco, CA 94103,
                                        <br />United States
                                    </li>
                                        {
                                            data?.email ?
                                                <Link to={`mailto:${data?.email}`} style={{ cursor: "pointer" }}>
                                                    <li className="flex_item_ss">
                                                        <img src={require("../../../assets/img/icn_Email.svg").default} alt='' className='flex-shrink-0' />
                                                        {data?.email}
                                                    </li></Link> : ''
                                        }
                                        {
                                            data?.phone ?
                                                <Link to={`tel:${data?.phone}`} style={{ cursor: "pointer" }}>
                                                    <li className="flex_item_ss">
                                                        <img src={require("../../../assets/img/icn_call.svg").default} alt='' className='flex-shrink-0' />
                                                        {data?.phone}
                                                    </li></Link> : ''
                                        }
                                    </ul>
                                </div>
                            </div> */}
                    </div>
                    <div className="footer-lower text-center">
                        <p className='fs14 fw500 text_dark mb-0 text-center'>©2023-Binox Bargains <span className='ms-3'>All right reserved</span> </p>
                    </div>
                </div>
            </footer>
        </>
    )
}

export default OrderFooter;