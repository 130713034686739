import { Steps } from 'antd';
import React from 'react'
const {Step} = Steps ; 
const CustomSteps = ({setCurrentStep,currentStep}) => {

    const onChange = (value) => {
        setCurrentStep(value);
    };
    
    return (
        <div className="custom_steps mb-4">
            <Steps current={currentStep}
                onChange={onChange}
                labelPlacement="vertical"
                responsive={false}>
                <Step icon={<span>1</span>} title="Download the app" />
                <Step icon={<span>2</span>} title="Find the Best Deal near you" />
                <Step icon={<span>3</span>} title="Select And Place Order" />
                <Step icon={<span>4</span>} title="Enjoy Your Savings" />
            </Steps>
        </div>
    )
}

export default CustomSteps