import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Heart, Location } from "iconsax-react";
import restaurantService from "../../services/restruants";
import eventService from "../../services/events";
import { useDispatch } from "react-redux";
import { connect } from "react-redux";
import { Button, Empty, Input, Steps } from "antd";
import groceryService from "../../services/groceries";
import DefaultImage from "../../../assets/images/default_image.jpg";
import CustomSteps from "../../common/CustomSteps";
import HowitsWorkStep1 from "../../common/HowitsWorkStep1";
import HowitsWorkStep2 from "../../common/HowitsWorkStep2";
import HowitsWorkStep3 from "../../common/HowitsWorkStep3";
import HowitsWorkStep4 from "../../common/HowitsWorkStep4";
import PageLoader from "../../common/PageLoader";
const { Step } = Steps;
const BaseUrl = process.env.REACT_APP_PROFILE_URL;

const Home = (props) => {

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [restruants, setRestrunts] = useState([]);
  const [search, setSearch] = useState("");
  const [events, setEvents] = useState([]);
  const [charities, setCharities] = useState([]);
  const [groceries, setGroceries] = useState([]);
  const [grocerieRestaurant, setGrocerieRestaurant] = useState([]);
  const [filterDataNew, setfilterDataNew] = useState([]);
  const [favoriteData, setFavoriteData] = useState([]);
  const [specialOffer, setSpecialOffer] = useState([]);
  const [bestBargainsNearYou, setBestBargainsNearYou] = useState([]);
  const [familySavings, setFamilySavings] = useState([]);
  const [drinksAndMore, setDrinksAndMore] = useState([]);
  const [fastestFingers, setFastestFingers] = useState([]);
  const [currentStep, setCurrentStep] = useState(0);
  const [loading, setLoding] = useState(true);
  const [mainCategory, setMainCategory] = useState([]);

  function getWidthCont(params) {
    let containerOutWidth = document.getElementsByClassName('container');
    containerOutWidth = containerOutWidth[0].clientWidth

    let mainWidth = window.outerWidth
    mainWidth = ((mainWidth - containerOutWidth) / 2)

    let allSlider = document.querySelectorAll('.offers_slider_section .slick-track');
    allSlider.forEach(element => {
      element.style.paddingRight = `${mainWidth}px`
    });
  }

  useEffect(() => {
    getWidthCont()
    window.addEventListener('resize', (e) => {
      getWidthCont()
    })
  }, [])

  const getrestrunts = (category_id = "") => {
    dispatch(restaurantService.getRestaurants(category_id))
      .then((res) => {
        setRestrunts(res.data);
      })
      .catch((errors) => {
        console.log({ errors });
      });
  };

  const getGroceryies = (searchData) => {
    dispatch(groceryService.getGroceryies(searchData))
      .then((res) => {
        setGroceries(res.data);
      })
      .catch((errors) => {
        console.log({ errors });
      });
  };

  const getResGroceryies = (searchData) => {
    dispatch(groceryService.getResGroceryies(searchData))
      .then((res) => {
        setGrocerieRestaurant(res.data);
      })
      .catch((errors) => {
        console.log({ errors });
      });
  };

  const getSpecialOffers = () => {
    const type = 5;
    dispatch(groceryService.getSpecialOffer(type))
      .then((res) => {
        setSpecialOffer(res.data);
      })
      .catch((errors) => {
        console.log({ errors });
      });
  };

  const getBestbargainsNearYou = () => {
    const type = 4;
    dispatch(groceryService.getSpecialOffer(type))
      .then((res) => {
        setBestBargainsNearYou(res.data);
      })
      .catch((errors) => {
        console.log({ errors });
      });
  };

  const getFamilySavings = () => {
    const type = 1;
    dispatch(groceryService.getSpecialOffer(type))
      .then((res) => {
        setFamilySavings(res.data);
      })
      .catch((errors) => {
        console.log({ errors });
      });
  };

  const getDrinksAndMore = () => {
    const type = 2;
    dispatch(groceryService.getSpecialOffer(type))
      .then((res) => {
        setDrinksAndMore(res.data);
      })
      .catch((errors) => {
        console.log({ errors });
      });
  };

  const getFastestFinger = () => {
    const type = 3;
    dispatch(groceryService.getSpecialOffer(type))
      .then((res) => {
        setFastestFingers(res.data);
      })
      .catch((errors) => {
        console.log({ errors });
      });
  };

  //main category
  const getMainCategory = () => {
    dispatch(eventService.getMainCategory())
      .then((res) => {
        setMainCategory(res.data);
      })
      .catch((errors) => {
        console.log({ errors });
      });
  };

  //events
  const getevents = (searchData) => {
    dispatch(eventService.getevents(searchData))
      .then((res) => {
        setEvents(res.data);
      })
      .catch((errors) => {
        console.log({ errors });
      });
  };

  // charity
  const getCharity = (searchData) => {
    dispatch(eventService.getCharity(searchData))
      .then((res) => {
        setCharities(res.data);
      })
      .catch((errors) => {
        console.log({ errors });
      });
  };

  //wishlist add
  const wishlistAdd = (data, isFound) => {
    var data_ = {};
    data_["vendor_id"] = data._id;
    data_["is_fav"] = isFound == false ? true : false;
    dispatch(restaurantService.addToFavorite(data_))
      .then((res) => {
        // ToastMe(res.data.message);
        getFavorite();
      })
      .catch((errors) => {
        console.log(errors);
      });
  };
  const getFavorite = () => {
    dispatch(restaurantService.getFavorite())
      .then((res) => {
        setFavoriteData(res.data);
      })
      .catch((errors) => {
        console.log({ errors });
      });
  };
  const RedirectToLogin = () => {
    navigate("/login");
  };

  const showCharityDetails = (id) => {
    dispatch(eventService.getCharityWithId(id))
      .then((res) => {
        navigate("/charity-details", { state: res.data });
      })
      .catch((errors) => {
        console.log({ errors });
      });
  };
  const showEventDetails = (id) => {
    dispatch(eventService.getEventById(id))
      .then((res) => {
        navigate("/event-details", { state: res.data });
      })
      .catch((errors) => {
        console.log({ errors });
      });
  };
  // if(searchDatas && searchDatas.length>0){
  //   let filteredData =[];
  //   let RfilteredData =[];
  //   let GfilteredData =[];
  //   if(searchDatas?.restaurant?.length){
  //     RfilteredData = searchDatas?.restaurant
  //   }
  //   if(searchDatas?.grocery?.length){
  //     GfilteredData = searchDatas?.grocery
  //   }

  //   filteredData = RfilteredData.concat(GfilteredData)
  //   setfilterDataNew(filteredData)
  // }
  const filterData = (e) => {
    if (e) {
      setSearch(e.target.value)
    }
    let data = {
      // type:1,
      search: search,
    }
    // searchData.search = search
    dispatch(eventService.filterData(data))
      .then((res) => {
        // console.log("Filter data")
        let filteredData = [];
        let RfilteredData = [];
        let GfilteredData = [];
        if (res?.data?.restaurant?.length) {
          RfilteredData = res?.data?.restaurant
        }
        if (res?.data?.grocery?.length) {
          GfilteredData = res?.data?.grocery
        }

        filteredData = RfilteredData.concat(GfilteredData)
        setfilterDataNew(filteredData)
      })
      .catch((errors) => {
        console.log({ errors })
      })
  }
  useEffect(() => {
    getMainCategory();
    getrestrunts();
    getevents();
    getCharity();
    getrestrunts();
    getSpecialOffers();
    getFavorite();
    getBestbargainsNearYou();
    getFamilySavings();
    getDrinksAndMore();
    getFastestFinger();
    getResGroceryies();
    if (search && search != "") {
      let data = {
        // type:1,
        search: search,
      }
      filterData(data)
    } else {
      getGroceryies();
    }
    // navigate("/orders", {});
  }, []);

  setTimeout(() => {
    setLoding(false);
  }, [800])

  const onChange = (value) => {
    setCurrentStep(value);
  };

  function nearYou(e) {
    const section = document.getElementById("near_you");
    if (section) {
      window.scrollTo({
        top: section.offsetTop - 100,
        behavior: 'smooth'
      });
    }
  }

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      const section = document.getElementById("near_you");
      if (section) {
        window.scrollTo({
          top: section.offsetTop - 100,
          behavior: 'smooth'
        });
      }
    }
  };

  return (
    <>
      <PageLoader loading={loading} />
      <section className="section gap60 landing_section">
        <div className="container">
          <div className="landing_section_main">
            <h1 className="fs48 fw700 text-center lh_1_3 mb-4 maxw_60 mx-auto"><span className="text-white">Find The Best</span> Food, Groceries, Events <span className="text-white"> Around You</span></h1>
            <div className="search_item_form maxw_60 mb-4">
              <div className="form-group mb-0">
                <Input
                  onChange={(e) => filterData(e)}
                  onKeyPress={handleKeyPress}
                  placeholder="Search your Items" suffix={<Button className="button button-2" onClick={e => nearYou(e)} ><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M21.7043 20.3458L17.6523 16.2938L17.7726 16.1365C19.1289 14.3677 19.7625 12.1495 19.545 9.93115C19.3276 7.71277 18.2753 5.6599 16.6012 4.18814C14.9272 2.71638 12.7564 1.93569 10.5285 2.00415C8.30049 2.07261 6.18178 2.9851 4.60124 4.55686C3.0207 6.12861 2.09642 8.24221 2.01556 10.4698C1.93469 12.6973 2.70326 14.8724 4.16567 16.5546C5.62808 18.2368 7.67505 19.3005 9.89219 19.5304C12.1093 19.7602 14.331 19.139 16.1074 17.7925L16.2739 17.6722L20.3259 21.7242C20.5117 21.9013 20.7585 22 21.0151 22C21.2717 22 21.5185 21.9013 21.7043 21.7242C21.7952 21.6339 21.8673 21.5266 21.9166 21.4083C21.9658 21.29 21.9912 21.1631 21.9912 21.035C21.9912 20.9069 21.9658 20.78 21.9166 20.6617C21.8673 20.5434 21.7952 20.4361 21.7043 20.3458V20.3458ZM15.6263 15.6462C14.516 16.7619 13.0531 17.4579 11.4869 17.6155C9.92084 17.773 8.34859 17.3823 7.03836 16.5101C5.72813 15.6378 4.76112 14.338 4.30223 12.8324C3.84334 11.3267 3.92102 9.70849 4.52202 8.25373C5.12302 6.79897 6.21011 5.59779 7.59787 4.85504C8.98562 4.1123 10.5881 3.87403 12.1319 4.18086C13.6757 4.48768 15.0653 5.3206 16.0635 6.53755C17.0618 7.7545 17.607 9.28011 17.606 10.8541C17.607 12.6507 16.8949 14.3742 15.6263 15.6462V15.6462Z" fill="#333030" />
                  </svg>
                  </Button>} className="form-control" />
              </div>
            </div>
            <div className="order_ctg_filter_wrapper">
              <div className="row justify-content-center">
                <div className="col-lg-7 col-xl-7 col-xxl-6 col-md-10">
                  <div className="">
                    <div className="row">
                      {
                        mainCategory?.map((data, i) => {
                          return (
                            <div className="col-4" key={i}>
                              <div className="ctg_item">
                                <Link
                                  role={"button"}
                                  to={data.name === 'Event' ? "/events" : "/categories"}
                                  state={{ flug: data.name.toLowerCase() }}>
                                  <div className="ctg_icon mx-auto mb-3 bg_white">
                                    <img
                                      // src={require("../../../assets/images/BinoxHompageBurger.jpg")}
                                      src={process.env.REACT_APP_PROFILE_URL + 'mainCategory/' + data.image}
                                      className="img-fluid"
                                      alt="icn_food"
                                      loading="lazy"
                                    />
                                  </div>
                                  <div className="ctg_text">
                                    <h4 className="mb-0 text-center fs18 text-white">{data.name}</h4>
                                  </div>
                                </Link>
                              </div>
                            </div>
                          )
                        })
                      }
                      {/* <div className="col-4">
                        <div className="ctg_item">
                          <Link
                            role={"button"}
                            to="/categories"
                            state={{ flug: "food" }}>
                            <div className="ctg_icon mx-auto mb-3 bg_white">
                              <img
                                src={require("../../../assets/images/BinoxHompageBurger.jpg")}
                                className="img-fluid"
                                alt="icn_food"
                                loading="lazy"
                              />
                            </div>
                            <div className="ctg_text">
                              <h4 className="mb-0 text-center fs18 text-white">Food</h4>
                            </div>
                          </Link>
                        </div>
                      </div>
                      <div className="col-4">
                        <div className="ctg_item">
                          <Link
                            role={"button"}
                            to="/categories"
                            state={{ flug: "grocery" }}>
                            <div className="ctg_icon mx-auto mb-3 bg_white">
                              <img
                                src={require("../../../assets/images/basket.jpg")}
                                className="img-fluid"
                                alt="icn_food"
                                loading="lazy"
                              />
                            </div>
                            <div className="ctg_text">
                              <h4 className="mb-0 text-center fs18 text-white">Groceries</h4>
                            </div>
                          </Link>
                        </div>
                      </div>
                      <div className="col-4">
                        <div className="ctg_item">
                          <Link role={"button"} to="/events">
                            <div className="ctg_icon mx-auto mb-3 bg_white">
                              <img
                                src={require("../../../assets/images/BBEventhomepageimage.jpg")}
                                className="img-fluid"
                                alt="icn_food"
                              />
                            </div>
                            <div className="ctg_text">
                              <h4 className="mb-0 text-center fs18 text-white">Event</h4>
                            </div>
                          </Link>
                        </div>
                      </div> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <section>
        <div className="container">
          <div className="img_content_resp text-center gap60">
            <img src={require("../../../assets/images/BinoxBargainBannerAugust2023.webp")} alt="" className="img-fluid w-100 rounded" />
          </div>
        </div>
      </section> */}
      <section className="section gap60 how_its_work">
        <div className="container">
          <div className="section_title mb-3">
            <h2 className="text-center">How it Works</h2>
          </div>
          <div className="how_its_work_main">
            <h5 className="fs16 fw-600 text-center text_dark mb-3 maxw_60 mx-auto">Binox Bargains offers exclusive bargain deals at already discounted prices directly from the sellers, all at your fingertips, so you won't need to search any further.
            </h5>
            <p className="fs16 fw-600 text-center text_dark mb-4">Find the best discounted deals in 4 easy steps</p>
            <CustomSteps setCurrentStep={setCurrentStep} currentStep={currentStep} />
            <div className="steps_content_wrapper">
              {
                currentStep === 0 ? (<HowitsWorkStep1 />)
                  :
                  currentStep === 1 ? (<HowitsWorkStep2 />)
                    :
                    currentStep === 2 ? (<HowitsWorkStep3 />)
                      :
                      currentStep === 3 ? (<HowitsWorkStep4 />)
                        :
                        null
              }
            </div>
          </div>
        </div>
      </section>
      <section className="section gap60 partner_with_us">
        <div className="container">
          <div className="row align-items-center gy-4 flex-column-reverse flex-md-row">
            <div className="col-md-6">
              <div className="text_content">
                <h2 className="fs34 fw600 text_dark mb-3  lh_1_3">Partner with us</h2>
                <p className="fs16 fw500 text_dark mb-3">Join the thousands of vendors who offer quality products to our extensive customer base and experience the advantages of partnering with Binox Bargains today.</p>
                <a href="/become-partner" role="button" className="button button-2">Partner with us</a>
              </div>
            </div>
            <div className="col-md-6">
              <div className="img_content_resp text-center">
                <img src={require("../../../assets/images/partner.png")} alt="" className="img-fluid" loading="lazy" />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="section gap60 bargains_near_you" id="near_you">
        <div className="container">
          <div className="section_title">
            <h2 className="text-center">Bargains Near You</h2>
          </div>
          <div className="bargains_card_wrapper">
            <h4 className="text-center">Restaurants</h4>
            <div className="row gy-4">
              {restruants && restruants.length > 0 && filterDataNew.length == 0 ?
                restruants.slice(0, 6).map((pData, ind) => {
                  const isFound = favoriteData?.some((element) => {
                    if (element._id == pData._id && element.is_fav == 1) {
                      return true;
                    }
                  });
                  return (
                    <div className="col-lg-4 col-6" key={ind}>
                      <div className="products_cards">
                        <div className="products_card_image mb20">
                          <Link
                            role={"button"}
                            to={"/shop/" + pData.businessName + ""}
                            state={{
                              shopDetails: pData,
                              type: pData.category_type == '1' ? "food" : "grocery",
                            }}>
                            <img
                              src={
                                pData.image != null
                                  ? BaseUrl + "vendor/" + pData.image
                                  : DefaultImage
                              }
                              className="img-fluid"
                              alt=""
                              loading="lazy"
                            />
                          </Link>
                          <div className="product_action">
                            {localStorage.getItem("jwt_token") ? (
                              <a
                                role={"buttton"}
                                onClick={(e) =>
                                  wishlistAdd(pData, isFound)
                                }
                                className="wishlist_icon">
                                {isFound === true ? (
                                  <Heart
                                    size="16"
                                    color="#f0140c"
                                    variant="Bold"
                                  />
                                ) : (
                                  <Heart
                                    size="16"
                                    color="#AAAAAA"
                                    variant="Bold"
                                  />
                                )}
                              </a>
                            ) : (
                              <a
                                role={"buttton"}
                                onClick={RedirectToLogin}
                                className="wishlist_icon">
                                <Heart
                                  size="16"
                                  color="#AAAAAA"
                                  variant="Bold"
                                />
                              </a>
                            )}
                          </div>
                        </div>
                        <div className="products_card_text flex_item_sb gap-2">
                          <div className="left_part">
                            <h5 className="fs20">
                              <Link
                                to={"/shop/" + pData.businessName + ""}
                                state={{
                                  shopDetails: pData,
                                  type: "grocery",
                                }}>
                                {pData.businessName}
                              </Link>
                            </h5>
                            <p>{pData.shopDesc}</p>
                            <p className="flex_item_ss gap-2">
                              <Location size="20px" color="#818181" />{" "}
                              {pData.businessAddress}
                            </p>
                          </div>
                          <div className="right_part">
                            <div className="rating_badge flex_item_cc gap-1">
                              <img
                                src={
                                  require("../../../assets/images/icons/icn_rate.svg")
                                    .default
                                }
                                className="img-fluid"
                                alt=""
                              />
                              <p>{pData.rating}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                }) :
                filterDataNew && filterDataNew.length > 0 ?
                  filterDataNew.map((pData, ind) => {
                    const isFound = favoriteData?.some((element) => {
                      if (element._id == pData._id && element.is_fav == 1) {
                        return true;
                      }
                    });
                    return (
                      <div className="col-lg-4 col-6" key={ind}>
                        <div className="products_cards">
                          <div className="products_card_image mb20">
                            <Link
                              role={"button"}
                              to={"/shop/" + pData.businessName + ""}
                              state={{
                                shopDetails: pData,
                                type: "grocery",
                              }}>
                              <img
                                src={
                                  pData.image != null
                                    ? BaseUrl + "vendor/" + pData.image
                                    : DefaultImage
                                }
                                className="img-fluid"
                                alt=""
                                loading="lazy"
                              />
                            </Link>
                            <div className="product_action">
                              {localStorage.getItem("jwt_token") ? (
                                <a
                                  role={"buttton"}
                                  onClick={(e) =>
                                    wishlistAdd(pData, isFound)
                                  }
                                  className="wishlist_icon">
                                  {isFound === true ? (
                                    <Heart
                                      size="16"
                                      color="#f0140c"
                                      variant="Bold"
                                    />
                                  ) : (
                                    <Heart
                                      size="16"
                                      color="#AAAAAA"
                                      variant="Bold"
                                    />
                                  )}
                                </a>
                              ) : (
                                <a
                                  role={"buttton"}
                                  onClick={RedirectToLogin}
                                  className="wishlist_icon">
                                  <Heart
                                    size="16"
                                    color="#AAAAAA"
                                    variant="Bold"
                                  />
                                </a>
                              )}
                            </div>
                          </div>
                          <div className="products_card_text flex_item_sb gap-2">
                            <div className="left_part">
                              <h5 className="fs20">
                                <Link
                                  to={"/shop/" + pData.businessName + ""}
                                  state={{
                                    shopDetails: pData,
                                    type: "grocery",
                                  }}>
                                  {pData.businessName}
                                </Link>
                              </h5>
                              <p>{pData.shopDesc}</p>
                              <p className="flex_item_ss gap-2">
                                <Location size="20px" color="#818181" />{" "}
                                {pData.businessAddress}
                              </p>
                            </div>
                            <div className="right_part">
                              <div className="rating_badge flex_item_cc gap-1">
                                <img
                                  src={
                                    require("../../../assets/images/icons/icn_rate.svg")
                                      .default
                                  }
                                  className="img-fluid"
                                  alt=""
                                />
                                <p>{pData.rating}</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  }) :
                  (
                    <div>
                      <Empty
                        description={<p>Not Found</p>}
                        image={Empty.PRESENTED_IMAGE_SIMPLE}
                      />
                    </div>
                  )}
            </div>
            <h4 className="text-center">Groceries</h4>
            <div className="row gy-4">
              {groceries && groceries.length > 0 && filterDataNew.length == 0 ?
                groceries.slice(0, 6).map((pData, ind) => {
                  const isFound = favoriteData?.some((element) => {
                    if (element._id == pData._id && element.is_fav == 1) {
                      return true;
                    }
                  });
                  return (
                    <div className="col-lg-4 col-6" key={ind}>
                      <div className="products_cards">
                        <div className="products_card_image mb20">
                          <Link
                            role={"button"}
                            to={"/shop/" + pData.businessName + ""}
                            state={{
                              shopDetails: pData,
                              type: pData.category_type == '1' ? "food" : "grocery",
                            }}>
                            <img
                              src={
                                pData.image != null
                                  ? BaseUrl + "vendor/" + pData.image
                                  : DefaultImage
                              }
                              className="img-fluid"
                              alt=""
                              loading="lazy"
                            />
                          </Link>
                          <div className="product_action">
                            {localStorage.getItem("jwt_token") ? (
                              <a
                                role={"buttton"}
                                onClick={(e) =>
                                  wishlistAdd(pData, isFound)
                                }
                                className="wishlist_icon">
                                {isFound === true ? (
                                  <Heart
                                    size="16"
                                    color="#f0140c"
                                    variant="Bold"
                                  />
                                ) : (
                                  <Heart
                                    size="16"
                                    color="#AAAAAA"
                                    variant="Bold"
                                  />
                                )}
                              </a>
                            ) : (
                              <a
                                role={"buttton"}
                                onClick={RedirectToLogin}
                                className="wishlist_icon">
                                <Heart
                                  size="16"
                                  color="#AAAAAA"
                                  variant="Bold"
                                />
                              </a>
                            )}
                          </div>
                        </div>
                        <div className="products_card_text flex_item_sb gap-2">
                          <div className="left_part">
                            <h5 className="fs20">
                              <Link
                                to={"/shop/" + pData.businessName + ""}
                                state={{
                                  shopDetails: pData,
                                  type: "grocery",
                                }}>
                                {pData.businessName}
                              </Link>
                            </h5>
                            <p>{pData.shopDesc}</p>
                            <p className="flex_item_ss gap-2">
                              <Location size="20px" color="#818181" />{" "}
                              {pData.businessAddress}
                            </p>
                          </div>
                          <div className="right_part">
                            <div className="rating_badge flex_item_cc gap-1">
                              <img
                                src={
                                  require("../../../assets/images/icons/icn_rate.svg")
                                    .default
                                }
                                className="img-fluid"
                                alt=""
                              />
                              <p>{pData.rating}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                }) :
                filterDataNew && filterDataNew.length > 0 ?
                  filterDataNew.map((pData, ind) => {
                    const isFound = favoriteData?.some((element) => {
                      if (element._id == pData._id && element.is_fav == 1) {
                        return true;
                      }
                    });
                    return (
                      <div className="col-lg-4 col-6" key={ind}>
                        <div className="products_cards">
                          <div className="products_card_image mb20">
                            <Link
                              role={"button"}
                              to={"/shop/" + pData.businessName + ""}
                              state={{
                                shopDetails: pData,
                                type: "grocery",
                              }}>
                              <img
                                src={
                                  pData.image != null
                                    ? BaseUrl + "vendor/" + pData.image
                                    : DefaultImage
                                }
                                className="img-fluid"
                                alt=""
                                loading="lazy"
                              />
                            </Link>
                            <div className="product_action">
                              {localStorage.getItem("jwt_token") ? (
                                <a
                                  role={"buttton"}
                                  onClick={(e) =>
                                    wishlistAdd(pData, isFound)
                                  }
                                  className="wishlist_icon">
                                  {isFound === true ? (
                                    <Heart
                                      size="16"
                                      color="#f0140c"
                                      variant="Bold"
                                    />
                                  ) : (
                                    <Heart
                                      size="16"
                                      color="#AAAAAA"
                                      variant="Bold"
                                    />
                                  )}
                                </a>
                              ) : (
                                <a
                                  role={"buttton"}
                                  onClick={RedirectToLogin}
                                  className="wishlist_icon">
                                  <Heart
                                    size="16"
                                    color="#AAAAAA"
                                    variant="Bold"
                                  />
                                </a>
                              )}
                            </div>
                          </div>
                          <div className="products_card_text flex_item_sb gap-2">
                            <div className="left_part">
                              <h5 className="fs20">
                                <Link
                                  to={"/shop/" + pData.businessName + ""}
                                  state={{
                                    shopDetails: pData,
                                    type: "grocery",
                                  }}>
                                  {pData.businessName}
                                </Link>
                              </h5>
                              <p>{pData.shopDesc}</p>
                              <p className="flex_item_ss gap-2">
                                <Location size="20px" color="#818181" />{" "}
                                {pData.businessAddress}
                              </p>
                            </div>
                            <div className="right_part">
                              <div className="rating_badge flex_item_cc gap-1">
                                <img
                                  src={
                                    require("../../../assets/images/icons/icn_rate.svg")
                                      .default
                                  }
                                  className="img-fluid"
                                  alt=""
                                />
                                <p>{pData.rating}</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  }) :
                  (
                    <div>
                      <Empty
                        description={<p>Not Found</p>}
                        image={Empty.PRESENTED_IMAGE_SIMPLE}
                      />
                    </div>
                  )}
            </div>
            <h4 className="text-center">Events</h4>
            <div className="row gy-4">
              {events && events.length > 0 && filterDataNew.length == 0 ?
                events.slice(0, 6).map((pData, ind) => {
                  const isFound = favoriteData?.some((element) => {
                    if (element._id == pData._id && element.is_fav == 1) {
                      return true;
                    }
                  });
                  return (
                    <div className="col-lg-4 col-6" key={ind}>
                      <div className="products_cards">
                        <div className="products_card_image mb20">
                          <Link
                            role={"button"}
                            to={"/shop/" + pData.businessName + ""}
                            state={{
                              shopDetails: pData,
                              type: pData.category_type == '1' ? "food" : "grocery",
                            }}>
                            <img
                              src={
                                pData.image != null
                                  ? BaseUrl + "eventImage/" + pData.image
                                  : DefaultImage
                              }
                              className="img-fluid"
                              alt=""
                              loading="lazy"
                            />
                          </Link>
                          <div className="product_action">
                            {localStorage.getItem("jwt_token") ? (
                              <a
                                role={"buttton"}
                                onClick={(e) =>
                                  wishlistAdd(pData, isFound)
                                }
                                className="wishlist_icon">
                                {isFound === true ? (
                                  <Heart
                                    size="16"
                                    color="#f0140c"
                                    variant="Bold"
                                  />
                                ) : (
                                  <Heart
                                    size="16"
                                    color="#AAAAAA"
                                    variant="Bold"
                                  />
                                )}
                              </a>
                            ) : (
                              <a
                                role={"buttton"}
                                onClick={RedirectToLogin}
                                className="wishlist_icon">
                                <Heart
                                  size="16"
                                  color="#AAAAAA"
                                  variant="Bold"
                                />
                              </a>
                            )}
                          </div>
                        </div>
                        <div className="products_card_text flex_item_sb gap-2">
                          <div className="left_part">
                            <h5 className="fs20">
                              <Link
                                to={"/shop/" + pData.businessName + ""}
                                state={{
                                  shopDetails: pData,
                                  type: "grocery",
                                }}>
                                {pData.businessName}
                              </Link>
                            </h5>
                            <p>{pData.shopDesc}</p>
                            <p className="flex_item_ss gap-2">
                              <Location size="20px" color="#818181" />{" "}
                              {pData.businessAddress}
                            </p>
                          </div>
                          <div className="right_part">
                            <div className="rating_badge flex_item_cc gap-1">
                              <img
                                src={
                                  require("../../../assets/images/icons/icn_rate.svg")
                                    .default
                                }
                                className="img-fluid"
                                alt=""
                              />
                              <p>{pData.rating}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                }) :
                filterDataNew && filterDataNew.length > 0 ?
                  filterDataNew.map((pData, ind) => {
                    const isFound = favoriteData?.some((element) => {
                      if (element._id == pData._id && element.is_fav == 1) {
                        return true;
                      }
                    });
                    return (
                      <div className="col-lg-4 col-6" key={ind}>
                        <div className="products_cards">
                          <div className="products_card_image mb20">
                            <Link
                              role={"button"}
                              to={"/shop/" + pData.businessName + ""}
                              state={{
                                shopDetails: pData,
                                type: "grocery",
                              }}>
                              <img
                                src={
                                  pData.image != null
                                    ? BaseUrl + "eventImage/" + pData.image
                                    : DefaultImage
                                }
                                className="img-fluid"
                                alt=""
                                loading="lazy"
                              />
                            </Link>
                            <div className="product_action">
                              {localStorage.getItem("jwt_token") ? (
                                <a
                                  role={"buttton"}
                                  onClick={(e) =>
                                    wishlistAdd(pData, isFound)
                                  }
                                  className="wishlist_icon">
                                  {isFound === true ? (
                                    <Heart
                                      size="16"
                                      color="#f0140c"
                                      variant="Bold"
                                    />
                                  ) : (
                                    <Heart
                                      size="16"
                                      color="#AAAAAA"
                                      variant="Bold"
                                    />
                                  )}
                                </a>
                              ) : (
                                <a
                                  role={"buttton"}
                                  onClick={RedirectToLogin}
                                  className="wishlist_icon">
                                  <Heart
                                    size="16"
                                    color="#AAAAAA"
                                    variant="Bold"
                                  />
                                </a>
                              )}
                            </div>
                          </div>
                          <div className="products_card_text flex_item_sb gap-2">
                            <div className="left_part">
                              <h5 className="fs20">
                                <Link
                                  to={"/shop/" + pData.businessName + ""}
                                  state={{
                                    shopDetails: pData,
                                    type: "grocery",
                                  }}>
                                  {pData.businessName}
                                </Link>
                              </h5>
                              <p>{pData.shopDesc}</p>
                              <p className="flex_item_ss gap-2">
                                <Location size="20px" color="#818181" />{" "}
                                {pData.businessAddress}
                              </p>
                            </div>
                            <div className="right_part">
                              <div className="rating_badge flex_item_cc gap-1">
                                <img
                                  src={
                                    require("../../../assets/images/icons/icn_rate.svg")
                                      .default
                                  }
                                  className="img-fluid"
                                  alt=""
                                />
                                <p>{pData.rating}</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  }) :
                  (
                    <div>
                      <Empty
                        description={<p>Not Found</p>}
                        image={Empty.PRESENTED_IMAGE_SIMPLE}
                      />
                    </div>
                  )}
            </div>
            {/* <div className="row gy-4">
              {grocerieRestaurant && grocerieRestaurant.length > 0 && filterDataNew.length == 0 ?
                grocerieRestaurant.slice(0, 9).map((pData, ind) => {
                  const isFound = favoriteData?.some((element) => {
                    if (element._id == pData._id && element.is_fav == 1) {
                      return true;
                    }
                  });
                  return (
                    <div className="col-lg-4 col-6" key={ind}>
                      <div className="products_cards">
                        <div className="products_card_image mb20">
                          <Link
                            role={"button"}
                            to={"/shop/" + pData.businessName + ""}
                            state={{
                              shopDetails: pData,
                              type: pData.category_type == '1' ? "food" : "grocery",
                            }}>
                            <img
                              src={
                                pData.image != null
                                  ? BaseUrl + "vendor/" + pData.image
                                  : DefaultImage
                              }
                              className="img-fluid"
                              alt=""
                            />
                          </Link>
                          <div className="product_action">
                            {localStorage.getItem("jwt_token") ? (
                              <a
                                role={"buttton"}
                                onClick={(e) =>
                                  wishlistAdd(pData, isFound)
                                }
                                className="wishlist_icon">
                                {isFound === true ? (
                                  <Heart
                                    size="16"
                                    color="#f0140c"
                                    variant="Bold"
                                  />
                                ) : (
                                  <Heart
                                    size="16"
                                    color="#AAAAAA"
                                    variant="Bold"
                                  />
                                )}
                              </a>
                            ) : (
                              <a
                                role={"buttton"}
                                onClick={RedirectToLogin}
                                className="wishlist_icon">
                                <Heart
                                  size="16"
                                  color="#AAAAAA"
                                  variant="Bold"
                                />
                              </a>
                            )}
                          </div>
                        </div>
                        <div className="products_card_text flex_item_sb gap-2">
                          <div className="left_part">
                            <h5 className="fs20">
                              <Link
                                to={"/shop/" + pData.businessName + ""}
                                state={{
                                  shopDetails: pData,
                                  type: "grocery",
                                }}>
                                {pData.businessName}
                              </Link>
                            </h5>
                            <p>{pData.shopDesc}</p>
                            <p className="flex_item_ss gap-2">
                              <Location size="20px" color="#818181" />{" "}
                              {pData.businessAddress}
                            </p>
                          </div>
                          <div className="right_part">
                            <div className="rating_badge flex_item_cc gap-1">
                              <img
                                src={
                                  require("../../../assets/images/icons/icn_rate.svg")
                                    .default
                                }
                                className="img-fluid"
                                alt=""
                              />
                              <p>{pData.rating}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                }) :
                filterDataNew && filterDataNew.length > 0 ?
                  filterDataNew.map((pData, ind) => {
                    const isFound = favoriteData?.some((element) => {
                      if (element._id == pData._id && element.is_fav == 1) {
                        return true;
                      }
                    });
                    return (
                      <div className="col-lg-4 col-6" key={ind}>
                        <div className="products_cards">
                          <div className="products_card_image mb20">
                            <Link
                              role={"button"}
                              to={"/shop/" + pData.businessName + ""}
                              state={{
                                shopDetails: pData,
                                type: "grocery",
                              }}>
                              <img
                                src={
                                  pData.image != null
                                    ? BaseUrl + "vendor/" + pData.image
                                    : DefaultImage
                                }
                                className="img-fluid"
                                alt=""
                              />
                            </Link>
                            <div className="product_action">
                              {localStorage.getItem("jwt_token") ? (
                                <a
                                  role={"buttton"}
                                  onClick={(e) =>
                                    wishlistAdd(pData, isFound)
                                  }
                                  className="wishlist_icon">
                                  {isFound === true ? (
                                    <Heart
                                      size="16"
                                      color="#f0140c"
                                      variant="Bold"
                                    />
                                  ) : (
                                    <Heart
                                      size="16"
                                      color="#AAAAAA"
                                      variant="Bold"
                                    />
                                  )}
                                </a>
                              ) : (
                                <a
                                  role={"buttton"}
                                  onClick={RedirectToLogin}
                                  className="wishlist_icon">
                                  <Heart
                                    size="16"
                                    color="#AAAAAA"
                                    variant="Bold"
                                  />
                                </a>
                              )}
                            </div>
                          </div>
                          <div className="products_card_text flex_item_sb gap-2">
                            <div className="left_part">
                              <h5 className="fs20">
                                <Link
                                  to={"/shop/" + pData.businessName + ""}
                                  state={{
                                    shopDetails: pData,
                                    type: "grocery",
                                  }}>
                                  {pData.businessName}
                                </Link>
                              </h5>
                              <p>{pData.shopDesc}</p>
                              <p className="flex_item_ss gap-2">
                                <Location size="20px" color="#818181" />{" "}
                                {pData.businessAddress}
                              </p>
                            </div>
                            <div className="right_part">
                              <div className="rating_badge flex_item_cc gap-1">
                                <img
                                  src={
                                    require("../../../assets/images/icons/icn_rate.svg")
                                      .default
                                  }
                                  className="img-fluid"
                                  alt=""
                                />
                                <p>{pData.rating}</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  }) :
                  (
                    <div>
                      <Empty
                        description={<p>Not Found</p>}
                        image={Empty.PRESENTED_IMAGE_SIMPLE}
                      />
                    </div>
                  )}
            </div> */}
          </div>
          <div className="text-center">
            <Link to={"/order"} className="button button-2">
              See All Offer
            </Link>
          </div>
        </div>
      </section>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    isAuthenticated: state.Auth.isAuthenticated,
    isVerify: state.Auth.isVerify,
    needToVerify: state.Auth.needToVerify,
    isAdministrator: state.Auth.isAdministrator,
    searchData: state.Auth.searchData,
  };
};

export default connect(mapStateToProps)(Home);
