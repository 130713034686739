import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { Add, Heart, Location, Minus } from 'iconsax-react';
import Slider from "react-slick";
import apiData from '../../../common/apiData/ProductData';
import FeedbackAskModal from '../../../modals/FeedbackAskModal';
import ThanksForFeedbackModal from '../../../modals/ThanksForFeedbackModal';
import { DownloadOutlined } from '@ant-design/icons';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import orderservice from '../../../services/orders';
import { Button, Empty } from 'antd';
import TicketPlaceholder from "../../../../assets/images/ticket_placeholder.svg"
import { saveAs } from 'file-saver'
const BaseUrl = process.env.REACT_APP_PROFILE_URL;

const EventBookDetail = (props) => {
    const routeParams = useParams();
    const dispatch = useDispatch();
    const { state } = useLocation();
    const [orderData, setOrderData] = useState();
    const [giveFeedBackModalOpen, setGiveFeedBackModalOpen] = useState(false);
    const [thanksFeedBackModalOpen, setThanksFeedBackModalOpen] = useState(false);

    const orderDetails = () => {
        dispatch(orderservice.getOrderDetail(state?.data))
            .then((res) => {
                if (res.data) {
                    setOrderData(res.data)
                }
            })
            .catch((errors) => {
                console.log({ errors })
            })
    }

    const downloadImage = (url) => {
        saveAs(url, 'image.jpg') // Put your image url here.
    }

    useEffect(() => {
        orderDetails();
    }, [])
    return (
        <>
            <section className='section order_details_page gap50'>
                <div className="container">
                    <div className="row">
                        <div className="col-xl-8 col-lg-7">
                            <div className="menu_items_card pe-xl-5 pe-lg-4">
                                <div className="section_title mb30">
                                    <h2 className="fs24">Your Tickets</h2>
                                </div>
                                <ul className="menu_items_list past_order_list custom-scrollbar">
                                    {orderData?.tickets.length != 0
                                        ? orderData?.tickets?.map((item, i) => {
                                            return (
                                                <li className='mb-3'>
                                                    <div className="product_list_item flex_item_cb gap-3" key={i}>
                                                        <div className="product_card flex-shrink-0">
                                                            <div className='flex_item_cb mb-2'>
                                                                <p className="fs16 lh-1 fw500 mb-0 text-dark">Ticket Number</p>
                                                                <h6 className="fs14 fw600 lh-1 mb-0">{item.ticket_number}</h6>
                                                            </div>
                                                            {item?.image != null ?
                                                                <div className='flex_item_cs gap-2'>
                                                                    <div className='img_100px_wrapper'>
                                                                        <img src={BaseUrl + 'tickets/' + item.image} className="img-fluid" alt="" height='80px' width="80px" loading="lazy"/>
                                                                    </div>
                                                                    <Button type="" icon={<DownloadOutlined />} size={"large"} className="button button-2 flex_item_cc gap-0" onClick={() => downloadImage(BaseUrl + 'tickets/' + item.image)}>
                                                                        Download
                                                                    </Button>
                                                                </div>
                                                                : <img src={TicketPlaceholder} className="img-fluid" alt="" height='80px' width="80px" loading="lazy"/>
                                                            }
                                                        </div>
                                                    </div>
                                                </li>
                                            )
                                        }) : <div>
                                            <Empty
                                                description={<p>Tickets not available..Kindly wait for tickets confirmation</p>}
                                                image={Empty.PRESENTED_IMAGE_SIMPLE}
                                            />
                                        </div>}
                                </ul>
                            </div>
                        </div>
                        <div className="col-xl-4 col-lg-5">
                            <div className="cart_sidebar sidebar_wrapper event-details-sidebar">
                                <div className="sidebar_widget">
                                    <h5 className='mb-3 fs18 fw600 lh_1_5'>{orderData?.event_data?.name}</h5>
                                    <div className='img_100px_wrapper mb-3'>
                                        <img src={BaseUrl + 'eventImage/' + orderData?.event_data?.image} className="img-fluid" loading="lazy"/>
                                    </div>
                                    <div className="flex_item_ss gap-2">
                                        <Location size="20" color='#FFBD05' /> <p className="fs16 lh_1_5 mb-0 fw400">{orderData?.event_data?.city}</p>
                                    </div>
                                </div>
                                <div className="sidebar_widget">
                                    <h5 className='mb-4 fs18 fw600 lh_1_5'>Booking Details</h5>
                                    <label className="text_c818181 fs16 lh-1 fw400 mb-2">Booking Id</label>
                                    <h6 className="fs16 fw600 lh_1_5 mb-3">{orderData?.booking_id}</h6>
                                    <label className="text_c818181 fs16 lh-1 fw400 mb-2">Payment Type</label>
                                    <h6 className="fs16 fw600 lh_1_5 mb-3">{orderData?.payment_type}</h6>
                                    <label className="text_c818181 fs16 lh-1 fw400 mb-2">Date</label>
                                    <h6 className="fs16 fw600 lh_1_5 mb-3">{moment(orderData?.createdAt).format('Do MMM YYYY')}</h6>
                                    {/* <label className="text_c818181 fs16 lh-1 fw400 mb-2">People</label>
                                    <h6 className="fs16 fw600 lh_1_5 mb-3">{orderData?.no_of_people}</h6> */}
                                </div>
                                <div className="sidebar_widget">
                                    <h5 className='mb-4 fs18 fw600 lh_1_5'>Bill Details</h5>
                                    <div className="billtotal_table_wrapper">
                                        <table className="table mb30">
                                            <tbody>
                                                <tr>
                                                    <th>People</th>
                                                    <td>{orderData?.no_of_people} x £{orderData?.event_data?.offerPrice.toFixed(2)}</td>
                                                </tr>
                                                <tr>
                                                    <th>Taxes</th>
                                                    <td>£{orderData?.vendor_data?.tax}</td>
                                                </tr>
                                            </tbody>
                                            <tfoot>
                                                <tr>
                                                    <th>TOTAL</th>
                                                    <td>£{orderData?.amount.toFixed(2)}</td>
                                                </tr>
                                            </tfoot>
                                        </table>
                                        {/* <button className="button button-2 w-100">Reorder</button> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </section>
        </>
    )
}

export default EventBookDetail;