import React from 'react';
import { connect } from 'react-redux';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import AuthMaster from '../components/layouts/authLayout/AuthMaster';
import Master from '../components/layouts/master/Master';
import OrderMaster from '../components/layouts/orderLayout/OrderMaster';
import AuthRoute from './AuthRoute';
import AuthVerifyRoute from './AuthVerifyRoute';
import ModuleVerifyRoute from './ModuleVerifyRoute';
import routes from './routes';

const RoutePage = (props) => {
    return (
        <Router basename={"/"}>
            <Routes>
                {routes.map(({ path, layout, auth, verify, seo_slug, module, component: Component, ...value }, key) => {
                    return (
                        layout === 'master' ?
                            auth === true && verify === true ?
                                <Route key={key} path="/" element={<AuthVerifyRoute />}>
                                    <Route key={key} path="/" element={<OrderMaster ptitle={Component.props.title} seo_slug={seo_slug} pageNoIndex={auth} path={path} />} >
                                        <Route key={key} element={<ModuleVerifyRoute module={module} />}>
                                            <Route exact key={key} path={path} element={Component} />
                                        </Route>
                                    </Route>
                                </Route>
                                : auth === true ?
                                    <Route key={key} path="/" element={<AuthRoute />}>
                                        <Route key={key} path="/" element={<OrderMaster ptitle={Component.props.title} seo_slug={seo_slug} pageNoIndex={auth} path={path} />}>
                                            <Route key={key} element={<ModuleVerifyRoute module={module} />}>
                                                <Route exact key={key} path={path} element={Component} />
                                            </Route>
                                        </Route>
                                    </Route>
                                    :
                                    <Route key={key} path="/" element={<OrderMaster ptitle={Component.props.title} seo_slug={seo_slug} pageNoIndex={auth} path={path} />} >
                                        <Route key={key} element={<ModuleVerifyRoute module={module} />}>
                                            <Route exact key={key} path={path} element={Component} />
                                        </Route>
                                    </Route>
                            : layout === 'auth' ?
                                <Route key={key} path="/" element={<AuthMaster ptitle={Component.props.title} seo_slug={seo_slug} pageNoIndex={auth} path={path} />} >
                                    <Route key={key} element={<ModuleVerifyRoute module={module} />}>
                                        <Route exact key={key} path={path} element={Component} />
                                    </Route>
                                </Route>
                                : layout === 'order' ?
                                    props.isAuthenticated === false && auth === false && verify === false ?
                                        <Route key={key} path="/" element={<OrderMaster ptitle={Component.props.title} seo_slug={seo_slug} pageNoIndex={auth} path={path} />} >
                                            <Route key={key} element={<ModuleVerifyRoute module={module} />}>
                                                <Route exact key={key} path={path} element={Component} />
                                            </Route>
                                        </Route> :
                                        props.isAuthenticated === true ? <Route key={key} path="/" element={<OrderMaster ptitle={Component.props.title} seo_slug={seo_slug} pageNoIndex={auth} path={path} />} >
                                            <Route key={key} element={<ModuleVerifyRoute module={module} />}>
                                                <Route exact key={key} path={path} element={Component} />
                                            </Route>
                                        </Route> :
                                            <Route
                                                path={path}
                                                element={<Navigate replace to="/" />}
                                                key={key}
                                            />
                                    : layout === 'cms' ?
                                        <Route key={key} element={<ModuleVerifyRoute module={module} />}>
                                            <Route key={key} exact path={path} seo_slug={seo_slug} pageNoIndex={auth} element={Component} />
                                        </Route>
                                        :
                                        <></>
                    )
                })
                }
            </Routes>
        </Router>
    )

}

const mapStateToProps = state => {
    return {
        isAuthenticated: state.Auth.isAuthenticated,
        isVerify: state.Auth.isVerify,
        needToVerify: state.Auth.needToVerify,
        isAdministrator: state.Auth.isAdministrator
    }
};

export default connect(mapStateToProps)(RoutePage);