import { Edit2 } from 'iconsax-react';
import React, { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { Button, Form, Input } from 'antd';
import ToastMe from "../../../common/ToastMe";
import AuthService from '../../../services/auth';
const BaseUrl = process.env.REACT_APP_PROFILE_URL;

const Profile = (props) => {
    const userData = props?.userData?.userData;
    const dispatch = useDispatch();
    const [form] = Form.useForm();
    const [profileData, setProfileData] = useState('')
    const [userImg, setUserImg] = useState('');
    const [imageName, setImageName] = useState();
    const [buttonLoading, setButtonLoading] = useState(false);

    const getProfile = () => {
        dispatch(AuthService.getProfile())
            .then((res) => {
                setProfileData(res.data)
            })
            .catch((errors) => {
                console.log({ errors })
            })
    }

    const previewUserImageOnChange = (ev) => {
        let userImgSrc = URL.createObjectURL(ev.target.files[0]);
        let filesPath = ev.target.files[0];
        setUserImg(userImgSrc);
        const image = new FormData();
        image.append('image', filesPath);
        dispatch(AuthService.uploadUserProfile(image))
            .then((res) => {
                if (res.data) {
                    setImageName(res.data.file_name)
                }
            })
            .catch((errors, statusCode) => {
                setUserImg('')
                ToastMe(errors.errorData, "error");
            });
    }

    const onFinish = (data) => {
        data['avatar'] = imageName;
        setButtonLoading(true)
        dispatch(AuthService.updateUserProfile(data, userData))
            .then((res) => {
                setButtonLoading(false)
                ToastMe("Profile Updated Successfully", 'success')
            })
            .catch((errors) => {
                setButtonLoading(false)
                console.log({ errors })
            })
    };

    useEffect(() => {
        getProfile()
    }, [])

    useEffect(() => {
        form.setFieldsValue({
            firstname: profileData.firstname,
            lastname: profileData.lastname,
            email: profileData.email,
            mobile: profileData.mobile
        })
    }, [profileData])

    return (
        <>
            <section className="section profile_page_wrapper gap50">
                <div className="container">
                    <div className="section_title">
                        <h2>Profile</h2>
                    </div>
                    <div className="profile_card">
                        <div className="row">
                            <div className="col-lg-8">
                                <div className="profile_card_widget mb40">
                                    <div className="profile_image_card">
                                        <div className="image">
                                            <img id='profileImg' className='img-fluid' src={userImg ? userImg : profileData?.avatar ? (BaseUrl + 'user/' + profileData?.avatar) : require("../../../../assets/images/default_img.png")} alt='profile' />
                                        </div>
                                        <label htmlFor='file-input-control' className="edit_btn">
                                            <a role={"button"}>
                                                <Edit2 size="20" color="#333230" />
                                                <div className="col d-none">
                                                    <Input type="file" name='image' className="file-input-control" id='file-input-control' onChange={previewUserImageOnChange} accept="image/*" />
                                                </div>
                                            </a>
                                        </label>
                                    </div>
                                </div>
                                <div className="profile_card_widget mb10">
                                    <h5 className="fs20 lh-1 fw600 mb20">Personal Information</h5>
                                    <Form
                                        name="basic"
                                        form={form}
                                        initialValues={{ remember: true }}
                                        onFinish={onFinish}
                                        autoComplete="off"
                                    >
                                        <div className="row">
                                            <div className="col-lg-6">
                                                <div className="form-group mb-3">
                                                    <label htmlFor="" className="fs14 fw500 lh-1 mb-2">First Name</label>
                                                    <Form.Item
                                                        name="firstname"
                                                        rules={[{ required: true, message: 'Please enter your first name!' }]}
                                                    >
                                                        <Input placeholder="First Name" className='input-control' />
                                                    </Form.Item>
                                                </div>
                                            </div>
                                            <div className="col-lg-6">
                                                <div className="form-group mb-3">
                                                    <label htmlFor="" className="fs14 fw500 lh-1 mb-2">Last Name</label>
                                                    <Form.Item
                                                        name="lastname"
                                                        rules={[{ required: true, message: 'Please enter your last name!' }]}
                                                    >
                                                        <Input placeholder="last Name" className='input-control' />
                                                    </Form.Item>
                                                </div>
                                            </div>
                                            <div className="col-lg-6">
                                                <div className="form-group mb-3">
                                                    <label htmlFor="" className="fs14 fw500 lh-1 mb-2">Email Address</label>
                                                    <Form.Item
                                                        name="email"
                                                        rules={[{ required: true, message: 'Please enter your email address!' },
                                                        { pattern: new RegExp(/^([A-Z0-9a-z._%+-])+\@([A-Za-z0-9.-])+(\.[A-Za-z]{2,4})+$/), message: 'Please enter valid email address!' }]}
                                                    >
                                                        <Input disabled={true} placeholder="Email" className='input-control' />
                                                    </Form.Item>
                                                </div>
                                            </div>
                                            <div className="col-lg-6">
                                                <div className="form-group mb-3">
                                                    <label htmlFor="" className="fs14 fw500 lh-1 mb-2">Mobile Number</label>
                                                    <Form.Item
                                                        name="mobile"
                                                        rules={[{ required: true, message: 'Please enter your email address!' }]}
                                                    >
                                                        <Input disabled={true} placeholder="Mobile Number" className='input-control' />
                                                    </Form.Item>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="profile_card_widget">
                                            <div className="row">
                                                <div className="col-lg-6">
                                                    {/* <button type="submit" className="button button-2 w-100 fs18 fw600">Save</button> */}
                                                    <Button htmlType='submit' loading={buttonLoading} className="button button-2 w-100 fs18 fw600">
                                                        Save
                                                    </Button>
                                                </div>
                                            </div>
                                        </div>
                                    </Form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

const mapStateToProps = state => {
    return {
        userData: state.Auth.userData
    }
};

export default connect(mapStateToProps)(Profile);