import { Modal } from 'antd'
import React from 'react'
import { useNavigate } from 'react-router-dom';

const ThanksForFeedbackModal = (props) => {
    const { thanksFeedBackModalOpen, setThanksFeedBackModalOpen } = props;
    const navigate = useNavigate();

    const setBackRoute = () =>{
        setThanksFeedBackModalOpen(false)
        navigate('/order-history')
    }

    return (
        <Modal
            centered
            open={thanksFeedBackModalOpen}
            onOk={() => setThanksFeedBackModalOpen(false)}
            onCancel={() => setThanksFeedBackModalOpen(false)}
            footer={""}
            className='thanks_for_feedback_modal_wrapper'
        >
            <div className='absolute_icon_wrapper'>
                <img src={require("../../assets/images/icons/icn_thank_you.svg").default} alt="" loading="lazy"/>
            </div>
            <div className='card_content'>
                <h5 className='starting_heading_center_32'>
                    Thank You For Your Feedback..
                </h5>
                <button className='button button-2 w-100 fs18 fw600' onClick={() => setBackRoute()}>
                     Back to Order Detail
                </button>
            </div>
        </Modal>

    )
}

export default ThanksForFeedbackModal