import { Link, useLocation, useNavigate } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import orderservice from '../../../services/orders';
import { Badge, Empty } from 'antd';
import PageLoader from '../../../common/PageLoader';
const BaseUrl = process.env.REACT_APP_PROFILE_URL;

const OrderHistory = (props) => {
    const { state } = useLocation();
    const [productData, setProductData] = useState([])
    const [categoryType, setCategoryType] = useState(1);
    const [category, setCategory] = useState(1);
    const [eventType, setEventType] = useState(0);
    const [charityType, setCharityType] = useState(0);
    const [activeclass, setClass] = useState('food')
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (state?.category == 1) {
            setClass('food')
            setCategoryType(1);
            setCategory(state?.category)
            setEventType(0)
            setCharityType(0)
        }
        if (state?.category == 2) {
            setClass('grocery')
            setCategoryType(1);
            setCategory(state?.category)
            setEventType(0)
            setCharityType(0)
        }
        if (state?.category == 3) {
            setEventType(1)
            setCategoryType(0);
            setCategory(state?.category);
            setClass('event')
            setCharityType(0)
        }
        if (state?.category == 5) {
            setCharityType(1)
            setCategoryType(0);
            setCategory(state?.category);
            setEventType(0)
            setClass('charity')
        }
    }, [state])

    const getOrderHistory = async () => {
        setLoading(true)
        dispatch(orderservice.getOrderHistory(category))
            .then((res) => {
                setProductData(res.data)
                setTimeout(() => {
                    setLoading(false)
                }, 800)
            })
            .catch((errors) => {
                setLoading(false)
                console.log({ errors })
            })
    }
    const changeType = (id) => {
        if (id == 1) {
            setClass('food')
            setCategoryType(1);
            setCategory(id)
            setEventType(0)
            setCharityType(0)
        }
        if (id == 2) {
            setClass('grocery')
            setCategoryType(1);
            setCategory(id)
            setEventType(0)
            setCharityType(0)
        }

        if (id == 3) {
            setEventType(1)
            setCategoryType(0);
            setCategory(id);
            setClass('event')
            setCharityType(0)
        }
        if (id == 5) {
            setCharityType(1)
            setCategoryType(0);
            setCategory(id);
            setEventType(0)
            setClass('charity')
        }

        getOrderHistory();
    }
    const viewOrder = (pdata) => {
        let data = {
            order_id: pdata._id,
            type: pdata.vendor_data.category_type
        }
        navigate("/order-details", { state: { data: data } })
    }
    const viewEvent = (pdata) => {
        let data = {
            order_id: pdata._id,
            type: 3
        }
        navigate("/event-order-details", { state: { data: data } })
    }
    const viewDonation = (pdata) => {
        let data = {
            order_id: pdata._id,
            type: 5
        }
        navigate("/donation-details", { state: { data: data } })
    }
    useEffect(() => {
        getOrderHistory()
    }, [category])
    // useEffect(() => {
    //     let newArry = []
    //     newArry = apiData.filter((prevData) => {
    //         return prevData.shopName == routeParams.slug
    //     })
    //     setProductData(...newArry)
    // }, [])
    // console.log(productData);
    return (
        <>
            <PageLoader loading={loading} />
            <section className='section order_history_page gap50'>
                <div className="container">
                    <div className="menu_items_card pe-xl-5 pe-lg-4">
                        <div className="section_title flex-lg-nowrap flex-wrap mb30 flex_item_cs gap-4">
                            <h2 className="fs24 flex-shrink-0">Order History</h2>
                            <div className="order_history_tabs flex-grow-1 custom-scrollbar">
                                <ul className="flex_item_cs ">
                                    <li><a role={"button"} className={activeclass == 'food' ? 'active' : ''} onClick={() => changeType(1)}>Food</a></li>
                                    <li><a role={"button"} className={activeclass == 'grocery' ? 'active' : ''} onClick={() => changeType(2)}>Grocery</a></li>
                                    <li><a role={"button"} className={activeclass == 'event' ? 'active' : ''} onClick={() => changeType(3)}>Event</a></li>
                                    <li><a role={"button"} className={activeclass == 'charity' ? 'active' : ''} onClick={() => changeType(5)}>Charity</a></li>
                                </ul>
                            </div>
                        </div>
                        <div className="menu_items_list custom-scrollbar">
                            {categoryType == 1 ? <ul className="product_list_main">
                                {productData.length != 0 ? productData.map((pdata, ind) => {
                                    return (
                                        <li>
                                            <div className="product_list_item flex_item_cb gap-4" key={ind}>
                                                <div className="product_image flex-shrink-0">
                                                    <img src={BaseUrl + 'vendor/' + pdata?.vendor_data?.image} className="img-fluid" alt="" loading="lazy"/>
                                                </div>
                                                <div className="product_text flex_item_cb flex-grow-1">
                                                    <div className="left_part">
                                                        <div className='flex_item_cc mb-2'>
                                                            <h4 className='mb-0'>{pdata?.vendor_data?.businessName}</h4>
                                                            {pdata?.status === 0 && (
                                                                <Badge.Ribbon text="Pending" style={{ backgroundColor: "#E4A11B", color: "#fff" }}></Badge.Ribbon>
                                                            )}
                                                            {pdata?.status === 1 && (
                                                                <Badge.Ribbon text="In Progress" style={{ backgroundColor: "#54B4D3", color: "#fff" }}></Badge.Ribbon>
                                                            )}
                                                            {pdata?.status === 2 && (
                                                                <Badge.Ribbon text="Completed" style={{ backgroundColor: "#14A44D", color: "#fff" }}></Badge.Ribbon>
                                                            )}
                                                            {pdata?.status === 3 && (
                                                                <Badge.Ribbon text="Cancelled" style={{ backgroundColor: "#DC4C64", color: "#fff" }}></Badge.Ribbon>
                                                            )}
                                                        </div>
                                                        <div className="flex_item_cs mb20"><p className='price mb-0'><ins>£{pdata?.total_amount ? pdata?.total_amount.toFixed(2) : 0}</ins></p>

                                                        </div>
                                                        <p className="order_time fs20 lh-1 text_c818181 fw500">{moment(pdata?.createdAt).format('Do MMM YYYY')}</p>
                                                    </div>
                                                    <div className="right_part">
                                                        <a role={"button"} onClick={() => viewOrder(pdata)} className="fs16 lh-1 fw500 text_dark1">View Order Details</a>
                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                                    )
                                }) : (
                                    <div>
                                        <Empty
                                            description={<p>History Not Found</p>}
                                            image={Empty.PRESENTED_IMAGE_SIMPLE}
                                        />
                                    </div>
                                )
                                }

                            </ul> : ""}
                            {eventType == 1 ? <ul className="product_list_main">
                                {productData.length != 0 ? productData.map((pdata, ind) => {
                                    return (
                                        <li>
                                            <div className="product_list_item flex_item_cb gap-4" key={ind}>
                                                <div className="product_image flex-shrink-0">
                                                    <img src={BaseUrl + 'eventImage/' + pdata?.event_data?.image} className="img-fluid" alt="" loading="lazy"/>
                                                </div>
                                                <div className="product_text flex_item_cb flex-grow-1">
                                                    <div className="left_part">
                                                        <h4><Link>{pdata?.event_data?.name}</Link></h4>
                                                        <div className="flex_item_cs mb20"><p className='price mb-0'><ins>£{pdata?.amount ? pdata?.amount.toFixed(2) : 0}</ins></p></div>
                                                        {/* <div className="flex_item_cs mb20">
                                                    <p className='food_type fw400 mb-0 gap-2'><span className='dots'></span> Veg Burger</p>
                                                    <div className="rating_star_part">
                                                        <div className="rating_star flex_item_cs gap-2">
                                                            <img src={require("../../../../assets/images/icons/icn_rate.svg").default} className="img-fluid" width={24} alt="rating icon" />
                                                            <span>4.0</span>
                                                        </div>
                                                    </div>
                                                </div> */}
                                                        <p className="order_time fs20 lh-1 text_c818181 fw500">{moment(pdata?.createdAt).format('Do MMM YYYY')}</p>
                                                    </div>
                                                    <div className="right_part">
                                                        <a role={"button"} onClick={() => viewEvent(pdata)} className="fs20 lh-1 fw500 text_dark1">View Booking Details</a>
                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                                    )
                                }) : (
                                    <div>
                                        <Empty
                                            description={<p>History Not Found</p>}
                                            image={Empty.PRESENTED_IMAGE_SIMPLE}
                                        />
                                    </div>
                                )
                                }

                            </ul> : ""}
                            {charityType == 1 ? <ul className="product_list_main">
                                {productData.length != 0 ? productData.map((pdata, ind) => {
                                    return (
                                        <li>
                                            <div className="product_list_item flex_item_cb gap-4" key={ind}>
                                                <div className="product_image flex-shrink-0">
                                                    <img src={BaseUrl + 'ngoItems/' + pdata?.charity_data?.image} className="img-fluid" alt="" loading="lazy"/>
                                                </div>
                                                <div className="product_text flex_item_cb flex-grow-1">
                                                    <div className="left_part">
                                                        <h4><Link>{pdata?.charity_data?.title}</Link></h4>
                                                        <div className="flex_item_cs mb20"><p className='price mb-0'><ins>£{pdata?.amount ? pdata?.amount.toFixed(2) : 0}</ins></p></div>
                                                        {/* <div className="flex_item_cs mb20">
                                                    <p className='food_type fw400 mb-0 gap-2'><span className='dots'></span> Veg Burger</p>
                                                    <div className="rating_star_part">
                                                        <div className="rating_star flex_item_cs gap-2">
                                                            <img src={require("../../../../assets/images/icons/icn_rate.svg").default} className="img-fluid" width={24} alt="rating icon" />
                                                            <span>4.0</span>
                                                        </div>
                                                    </div>
                                                </div> */}
                                                        <p className="order_time fs20 lh-1 text_c818181 fw500">{moment(pdata?.createdAt).format('Do MMM YYYY')}</p>
                                                    </div>
                                                    <div className="right_part">
                                                        <a role={"button"} onClick={() => viewDonation(pdata)} className="fs20 lh-1 fw500 text_dark1">View Details</a>
                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                                    )
                                }) : (
                                    <div>
                                        <Empty
                                            description={<p>History Not Found</p>}
                                            image={Empty.PRESENTED_IMAGE_SIMPLE}
                                        />
                                    </div>
                                )
                                }

                            </ul> : ""}
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default OrderHistory;