import React, { useEffect, useState } from "react";
import { Location } from 'iconsax-react';
import { useLocation } from "react-router-dom";
import restaurantService from "../../../services/restruants";
import { useDispatch } from "react-redux";
import MenuItems from "./MenuItems";
import { Rating } from "@mui/material";
import DefaultImage from '../../../../assets/images/Grocery.svg';

const RestaurantDetails = () => {

    const { state } = useLocation();
    const shopDetails = state?.shopDetails;
    const dispatch = useDispatch();
    const [foodmenu, setFoodMenu] = useState();
    const [grocerymenu, setGroceryMenu] = useState();
    const [selectedMenu, setSelectedMenu] = useState();
    const [menuId, setMenuId] = useState();

    const getFoodMenu = () => {
        dispatch(restaurantService.getFoodMenu(shopDetails._id))
            .then((res) => {
                setFoodMenu(res.data)
                setSelectedMenu(res.data[0].name)
                setMenuId(res.data[0]._id)
            })
            .catch((errors) => {
                console.log({ errors })
            })
    }
    const getGroceryMenu = () => {
        dispatch(restaurantService.getGroceryMenu(shopDetails._id))
            .then((res) => {
                setGroceryMenu(res.data)
                setSelectedMenu(res.data[0].name)
                setMenuId(res.data[0]._id)
            })
            .catch((errors) => {
                console.log({ errors })
            })
    }

    useEffect(() => {
        if (state.type === 'food') {
            getFoodMenu();
        } else {
            getGroceryMenu();
        }
    }, [shopDetails._id])

    const handleMenuClick = (menu) => {
        setSelectedMenu(menu.name)
        setMenuId(menu._id)
    }

    return (
        <>
            <section className="section gap shop_detials_banner bg_lightgray">
                <div className="container">
                    <div className="shop_detials_banner_main">
                        <div className="banner" style={{ backgroundImage: `url(${shopDetails.image ? process.env.REACT_APP_PROFILE_URL + 'vendor/' + shopDetails.image : DefaultImage})` }}>
                        </div>
                        {/* <div className="shop_logo">
                            <img src={require("../../../../assets/images/icons/shop_logo.svg").default} className="img-fluid" alt="" />
                        </div> */}
                        <div className="shop_text flex_item_cs gap-3 mt-3">
                            <h1 className="fs32 mb-0 fw600 lh-1">{shopDetails.businessName}</h1>
                            {/* <h6 className="fs14 mb-0 fw500 lh-1 food_type gap-2"><span className="dots"></span> Veg Burger</h6> */}
                            <h6 className='fs14 mb-0 fw500 lh-1 text_c818181 flex_item_cs gap-2'><Location size="20" color="#818181" className="flex-shrink-0" /> {shopDetails.businessAddress}</h6>
                            <h6 className="fs14 mb-0 fw500 lh-1 flex_item_cs gap-2">
                                <div className="fs24 mb-0 fw500 lh-1 flex_item_cs gap-2 ">
                                    <Rating name="rating" color="#000" value={shopDetails.rating} precision={0.1} readOnly className="flex_item_cc gap-0" />
                                    <span>{shopDetails.rating}</span>
                                </div>
                            </h6>
                        </div>
                    </div>
                </div>
            </section>
            <section className='section shops_details_section gap50'>
                <div className="container">
                    <div className="row">
                        <div className="col-xl-3 col-lg-4 custom_col_left">
                            <div className="shop_menu_category mb30">
                                <ul className=''>
                                    {
                                        state.type === 'food' ?
                                            foodmenu && foodmenu.map((menu, i) => {
                                                return (
                                                    <li><a role="button" onClick={() => handleMenuClick(menu)} className={selectedMenu === menu.name ? 'active' : ''}>{menu.name}</a></li>
                                                )
                                            }) :
                                            grocerymenu && grocerymenu.map((menu, i) => {
                                                return (
                                                    <li><a role="button" onClick={() => handleMenuClick(menu)} className={selectedMenu === menu.name ? 'active' : ''}>{menu.name}</a></li>
                                                )
                                            })
                                    }
                                </ul>
                            </div>
                        </div>
                        <div className="col-xl-9 col-lg-8 custom_col_right">
                            <div className="menu_items_card">
                                <MenuItems selectedMenu={selectedMenu} menuId={menuId} type={state?.type} />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default RestaurantDetails