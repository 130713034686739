import React from 'react';
import { Link } from 'react-router-dom';



const AuthHeader = (props) => {    
  
    return (
        <>
        <header>
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-xl-2 col-3">
                            <div className="header-style">
                                <Link to="/">
                                    <img className="logo img-fluid d-md-block d-none" src={require('../../../assets/img/logo_white.png')} alt="" />
                                    <img className="logo img-fluid d-md-none" width={48} src={require('../../../assets/img/logo-icon.svg').default} alt="" />
                                </Link>
                            </div>
                        </div>
                        <div className="col-xxl-3 col-xl-4 col ms-auto">
                            <div className="extras bag flex_item_ce gap-2">
                                <Link to="/" className="button button-2">Login</Link>
                                <Link to="/" className="button button-2">Sign up</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
        </>
    )
}

export default AuthHeader;