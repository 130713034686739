import { Modal } from 'antd'
import React, { useState } from 'react'

const AddCardModal = (props) => {
    const { addCardModalOpen, setAddCardModalOpen } = props
    return (
        <Modal
            centered
            open={addCardModalOpen}
            onOk={() => setAddCardModalOpen(false)}
            onCancel={() => setAddCardModalOpen(false)}
            footer={""}
            className='add_card_modal_wrapper'
        >
            <div className='card_content'>
                <h5 className='starting_heading'>Add New Card</h5>
                <form action="" className='add_card_form'>
                    <ul>
                        <li>
                            <label htmlFor="">Name</label>
                            <input type="text" />
                        </li>
                        <li>
                            <label htmlFor="">Card Number</label>
                            <input type="text" />
                        </li>
                        <li>
                            <label htmlFor="">Expired Date</label>
                            <div className='input_width50_wrapper'>
                                <input type="text" className='input_width_half' />
                                <input type="text" className='input_width_half' />
                            </div>
                        </li>
                        <li>
                            <label htmlFor="">CVV</label>
                            <input type="text" />
                        </li>
                    </ul>
                    <button className='button button-2 w-100 fs18 fw600'>
                        Add
                    </button>
                </form>
            </div>
        </Modal>
    )
}

export default AddCardModal