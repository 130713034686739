import Http from '../../Http'
import * as action from '../../store/actions'
// import ToastMe from '../../view/common/ToastMe';
const BaseUrl = process.env.REACT_APP_BASE_URL;

export function cmsPages() {
    return dispatch => (
        new Promise((resolve, reject) => {
            Http.callApi('get',BaseUrl + '/admin/getPage')
            .then(function (res) {
                return resolve(res);
            })
            .catch(function (error) {
                    console.log({error})
                    // if (error.response.data.errors && "g_recaptcha_response" in error.response.data.errors) {
                    //     // ToastMe("Please refresh the page for Google reCAPTCHA authentication", 'danger');
                    // } else {
                    //     // ToastMe(error.response.data.message, 'danger');
                    // }
                    const data = {
                        errorData: error.response.data.errors,
                        statusCode: error.response.status,
                    };
                    return reject(data);
                })

        })
    )
}