import Login from "../auth/Login";
import BecomePartner from "../components/pages/Front/BecomePartner";
import Cart from "../components/pages/Front/Cart/Cart";
import Categories from "../components/pages/Front/Categories/Categories";
import Charity from "../components/pages/Front/charity/Charity";
import CharityDetails from "../components/pages/Front/charity/CharityDetails";
import ContactUs from "../components/pages/Front/ContactUs";
import CookiesPolicy from "../components/pages/Front/CookiesPolicy";
import Disclaimer from "../components/pages/Front/Disclaimer";
import AcceptablePolicy from "../components/pages/Front/AcceptablePolicy";
import EULA from "../components/pages/Front/eula";
import EventDetails from "../components/pages/Front/event/EventDetails";
import Events from "../components/pages/Front/event/Events";
import Home from "../components/pages/Front/Home";
import Orders from "../components/pages/Front/Orders/Orders";
import SearchResult from "../components/pages/Front/SearchResult/Search"
import PrivacyPolicy from "../components/pages/Front/PrivacyPolicy";
import ManageAddress from "../components/pages/Front/Profile/ManageAddress";
import ManagePaymentsMethod from "../components/pages/Front/Profile/ManagePaymentsMethod";
import OrderDetails from "../components/pages/Front/Profile/OrderDetails";
import OrderHistory from "../components/pages/Front/Profile/OrderHistory";
import Profile from "../components/pages/Front/Profile/Profile";
import ChangePassword from "../components/pages/Front/Profile/ChangePassword";
import ReturnPolicy from "../components/pages/Front/ReturnPolicy";
import RestaurantDetails from "../components/pages/Front/Shops/RestaurantDetails";
import ShopsDetails from "../components/pages/Front/Shops/ShopsDetails";
import TermsAndConditions from "../components/pages/Front/TermsAndConditions";
import Wishlist from "../components/pages/Front/Wishlist/Wishlist";
import Register from "../auth/Register";
import ForgotPassword from "../auth/ForgotPassword";
import ResetPassword from "../auth/ResetPassword";
import OtpVerification from "../auth/OtpVerification";
import EventBookDetail from "../components/pages/Front/Profile/EventBookDetail";
import CharityDonationDetail from "../components/pages/Front/Profile/CharityDonationDetail";
import DownloadApp from "../components/pages/Front/newpages/DownloadApp";
import AboutUs from "../components/pages/Front/newpages/AboutUs";
import GetSupport from "../components/pages/Front/newpages/GetSupport";
import Faq from "../components/pages/Front/Faq";
import ErrorPage from "../components/layouts/authLayout/ErrorPage";

const routes = [
   
    {
        path: '/',
        layout: 'master',
        exact: true,
        auth: false,
        verify: false,
        component: <Home title="Homesss" />,
        seo_slug: 'binox_home',
    },
    {
        path: '/order',
        layout: 'order',
        exact: true,
        auth: false,
        verify: false,
        component: <Orders title="Orders" />,
        seo_slug: 'binox_home',
    },
    {
        path: '/search-result',
        layout: 'order',
        exact: true,
        auth: false,
        verify: false,
        component: <SearchResult title="search" />,
        seo_slug: 'binox_home',
    },
    {
        path: '/login',
        layout: 'master',
        exact: true,
        auth: false,
        verify: false,
        component: <Login title="Login" />,
        seo_slug: 'binox_home',
    },
    {
        path: '/register',
        layout: 'master',
        exact: true,
        auth: false,
        verify: false,
        component: <Register title="register" />,
        seo_slug: 'binox_home',
    },
    {
        path: '/forgot-password',
        layout: 'master',
        exact: true,
        auth: false,
        verify: false,
        component: <ForgotPassword title="forgot password" />,
        seo_slug: 'binox_home',
    },
    {
        path: '/otp-verification',
        layout: 'master',
        exact: true,
        auth: false,
        verify: false,
        component: <OtpVerification title="otp verification" />,
        seo_slug: 'binox_home',
    },
    {
        path: '/reset-password',
        layout: 'master',
        exact: true,
        auth: false,
        verify: false,
        component: <ResetPassword title="otp verification" />,
        seo_slug: 'binox_home',
    },
    {
        path: '/become-partner',
        layout: 'master',
        exact: true,
        auth: false,
        verify: false,
        component: <BecomePartner title="Become Partner" />,
        seo_slug: 'binox_home',
    },
    {
        path: '/privacy-policy',
        layout: 'master',
        exact: true,
        auth: false,
        verify: false,
        component: <PrivacyPolicy title="Privacy Policy" />,
        seo_slug: 'binox_home',
    },
    {
        path: '/return-policy',
        layout: 'master',
        exact: true,
        auth: false,
        verify: false,
        component: <ReturnPolicy title="Return Policy" />,
        seo_slug: 'binox_home',
    },
    {
        path: '/cookies-policy',
        layout: 'master',
        exact: true,
        auth: false,
        verify: false,
        component: <CookiesPolicy title="Cookies Policy" />,
        seo_slug: 'binox_home',
    },
    {
        path: '/terms-and-conditions',
        layout: 'master',
        exact: true,
        auth: false,
        verify: false,
        component: <TermsAndConditions title="Terms And Conditions" />,
        seo_slug: 'binox_home',
    },
    {
        path: '/disclaimer',
        layout: 'master',
        exact: true,
        auth: false,
        verify: false,
        component: <Disclaimer title="Disclaimer" />,
        seo_slug: 'binox_home',
    },
    {
        path: '/eula',
        layout: 'master',
        exact: true,
        auth: false,
        verify: false,
        component: <EULA title="Disclaimer" />,
        seo_slug: 'binox_home',
    },
    {
        path: '/use-acceptable-policy',
        layout: 'master',
        exact: true,
        auth: false,
        verify: false,
        component: <AcceptablePolicy title="AcceptablePolicy" />,
        seo_slug: 'binox_home',
    },
    {
        path: '/categories',
        layout: 'order',
        exact: true,
        auth: false,
        verify: false,
        component: <Categories title="Categories" />,
        seo_slug: 'binox_home',
    },
    {
        path: '/download-app',
        layout: 'order',
        exact: true,
        auth: false,
        verify: false,
        component: <DownloadApp title="download-app" />,
        seo_slug: 'binox_home',
    },
    {
        path: '/faq',
        layout: 'order',
        exact: true,
        auth: false,
        verify: false,
        component: <Faq title="faq" />,
        seo_slug: 'binox_home',
    },
    {
        path: '/about-us',
        layout: 'order',
        exact: true,
        auth: false,
        verify: false,
        component: <AboutUs title="about-us" />,
        seo_slug: 'binox_home',
    },
    {
        path: '/get-support',
        layout: 'order',
        exact: true,
        auth: false,
        verify: false,
        component: <GetSupport title="get-support" />,
        seo_slug: 'binox_home',
    },
    {
        path: '/shops/:slug',
        layout: 'order',
        exact: true,
        auth: false,
        verify: false,
        component: <ShopsDetails title="Shops" />,
        seo_slug: 'binox_home',
    },
    {
        path: '/shop/:slug',
        layout: 'order',
        exact: true,
        auth: false,
        verify: false,
        component: <RestaurantDetails title="Shops" />,
        seo_slug: 'binox_home',
    },
    {
        path: '/cart',
        layout: 'order',
        exact: true,
        auth: true,
        verify: true,
        component: <Cart title="Cart" />,
        seo_slug: 'binox_home',
    },
    {
        path: '/wishlist',
        layout: 'order',
        exact: true,
        auth: true,
        verify: true,
        component: <Wishlist title="Wishlist" />,
        seo_slug: 'binox_home',
    },
    {
        path: '/profile',
        layout: 'order',
        exact: true,
        auth: true,
        verify: true,
        component: <Profile title="Profile" />,
        seo_slug: 'binox_home',
    },
    {
        path: '/change-password',
        layout: 'order',
        exact: true,
        auth: true,
        verify: true,
        component: <ChangePassword title="Change Password" />,
        seo_slug: 'binox_home',
    },
    {
        path: '/order-history',
        layout: 'order',
        exact: true,
        auth: true,
        verify: true,
        component: <OrderHistory title="Order History" />,
        seo_slug: 'binox_home',
    },
    {
        path: '/order-details',
        layout: 'order',
        exact: true,
        auth: true,
        verify: true,
        component: <OrderDetails title="Order Details" />,
        seo_slug: 'binox_home',
    },
    {
        path: '/event-order-details',
        layout: 'order',
        exact: true,
        auth: true,
        verify: true,
        component: <EventBookDetail title="Event Details" />,
        seo_slug: 'binox_home',
    },
    {
        path: '/donation-details',
        layout: 'order',
        exact: true,
        auth: true,
        verify: true,
        component: <CharityDonationDetail title="Donation Details" />,
        seo_slug: 'binox_home',
    },
    {
        path: '/manage-address',
        layout: 'order',
        exact: true,
        auth: true,
        verify: true,
        component: <ManageAddress title="Manage Address" />,
        seo_slug: 'binox_home',
    },
    {
        path: '/manage-payments-method',
        layout: 'order',
        exact: true,
        auth: true,
        verify: true,
        component: <ManagePaymentsMethod title="Manage Payments Method" />,
        seo_slug: 'binox_home',
    },
    {
        path: '/events',
        layout: 'order',
        exact: true,
        auth: false,
        verify: false,
        component: <Events title="events" />,
        seo_slug: 'binox_home',
    },
    {
        path: '/event-details',
        layout: 'order',
        exact: true,
        auth: false,
        verify: false,
        component: <EventDetails title="event-details" />,
        seo_slug: 'binox_home',
    },
    {
        path: '/charity',
        layout: 'order',
        exact: true,
        auth: false,
        verify: false,
        component: <Charity title="charity" />,
        seo_slug: 'binox_home',
    },
    {
        path: '/charity-details',
        layout: 'order',
        exact: true,
        auth: false,
        verify: false,
        component: <CharityDetails title="charity-details" />,
        seo_slug: 'binox_home',
    },
    {
        path: '/contact',
        layout: 'order',
        exact: true,
        auth: false,
        verify: false,
        component: <ContactUs title="Contact Us" />,
        seo_slug: 'binox_home',
    },
    {
        path: '*',
        layout: 'master',
        exact: true,
        auth: false,
        verify: false,
        component: <ErrorPage title="Page not found" />,
        seo_slug: 'binox_home',
    },
]
export default routes;