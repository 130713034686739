import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import CmsService from '../../../components/services/cmsPages';

const EULA = (props) => {
    const dispatch = useDispatch();
    const [eulaPage, setEulaPage] = useState([]);

    const getPrivacyPolicy = () => {
        dispatch(CmsService.cmsPages())
            .then((res) => {
                res?.data[0]?.data.map((data) => {
                    if (data.page_name == 'EULA') {
                        setEulaPage(data);
                    }
                })
            })
            .catch((errors) => {
                console.log({ errors })
            })
    }

    useEffect(() => {
        getPrivacyPolicy()
    }, [])

    return (
        <>
            {/* hero-section */}
            <section className="hero-section about blog-page gap" style={{ backgroundImage: `url(${require("../../../assets/images/blog-img-1.jpg")})` }}>
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-12">
                            <div className="about-text" data-aos="fade-up" data-aos-delay={200} data-aos-duration={300}>
                                <h2>EULA</h2>
                                <p><strong>Last updated {moment(eulaPage.updatedAt).format("MMM DD, YYYY")}</strong></p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="section gap">
                <div className="container">
                    <div className="row get_services" data-aos="flip-up" data-aos-delay={300} data-aos-duration={400}>
                        <div className="col-md-12">
                            <div className="default-content-area">
                                <div dangerouslySetInnerHTML={{ __html: eulaPage.description }}></div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default EULA;