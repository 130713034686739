import React from 'react'

const GetSupport = () => {
  return (
    <div>
         
    </div>
  )
}

export default GetSupport