import React, { useEffect, useState } from 'react';
import { Progress } from 'antd';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import eventService from '../../../services/events';
import moment from 'moment/moment';
const BaseUrl = process.env.REACT_APP_PROFILE_URL;


const Charity = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [charities, setCharityItems] = useState([]);

    const getCharity = () => {

        dispatch(eventService.getCharity())
            .then((res) => {
                setCharityItems(res.data)
            })
            .catch((errors) => {
                console.log({ errors })
            })
    }

    const showDetails = (id) => {
        dispatch(eventService.getCharityWithId(id))
            .then((res) => {
                navigate("/charity-details")
            })
            .catch((errors) => {
                console.log({ errors })
            })
    }

    useEffect(() => {
        getCharity()
    }, [])
    return (
        <section className='charity_section_wrapper events_happening_near_you gap50'>
            <div className='container'>
                <div className='section_title'>
                    <h2>Charity</h2>
                </div>
                <div className='charity_cards_wrapper events_details_cards_wrapper'>
                    <div className='row gy-4'>
                        {
                            charities.map((pData, index) => {
                                return <div className='col-md-6' >
                                    <div className='individual_card'>
                                        <div className='img_wrapper'>
                                            <Link onClick={() => showDetails(pData._id)}>
                                                <img src={(BaseUrl + 'ngoItems/' + pData.image)} className="img-fluid" alt="" loading="lazy"/>
                                            </Link>
                                            <div className='event_date_absolute'>
                                                <span className='numeric_value'>
                                                    {moment(pData.createdAt).format("D")}
                                                </span>
                                                <span>
                                                    {moment(pData.createdAt).format("MMM")}
                                                </span>
                                            </div>
                                        </div>
                                        <div className='text-content'>
                                            <div className='main_text flex-grow-1'>
                                                <Link onClick={() => showDetails(pData._id)}>
                                                    <h4>{pData.title}</h4>
                                                </Link>
                                                <p>{pData.sub_title}</p>
                                                <div className="progress_bar_part">
                                                    <span>{pData.percentage}%</span>
                                                    <Progress percent={pData.percentage} showInfo={false} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            })
                        }
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Charity