import React from "react";

const UserSavedCard = ({ cardUserName, cardExpiryDate, cardLast4Digit, onCardClick, isSelected, cardId, cardBrand, index }) => {
    return (
        <>
            <div className="custom_radio_group">
                <input type="radio" className="radio_input" id={`payment_card_${index}`} name="payment_radio" onChange={() => onCardClick('card', cardId)} checked={isSelected.id == cardId ? true : false} />
                <label htmlFor={`payment_card_${index}`} className="radio_label">
                    <div className="payment_card_box flex_item_cs gap-3">
                        {
                            cardBrand === "MasterCard" ?
                                <img src={require("../../../../assets/images/icons/icon_master_card.svg").default} className="img-fluid" alt="" />
                                :
                                <img src={require("../../../../assets/images/icons/icon_visa_card.svg").default} className="img-fluid" alt="" />
                        }
                        <span className="mb-0 fs18 fw500">**** **** ****<span className='ms-1'>{cardLast4Digit}</span></span>
                    </div>
                </label>
            </div>
        </>
    )
}

export default UserSavedCard