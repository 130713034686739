import React from 'react';
import { Button, Form, Input, Modal } from 'antd';
import { CloseCircle } from 'iconsax-react';
import InputMask from 'react-input-mask';

const DeleteCardModal = (props) => {
    const { RemoveCartModalOpen, RemoveCartModalCloseEvent } = props;
    const [form] = Form.useForm();

    return (
        <>
            <Modal visible={RemoveCartModalOpen} centered footer={''} closable={false} onCancel={RemoveCartModalCloseEvent} getPopupContainer={node => node.parentNode} className="confirm-cart-modal">
                <div className="">
                    <div className="modal-header flex_item_cb mb-3">
                        <h6 className='fs24 fw-700 text-dark'>Remove Card</h6>
                        <button className="close-modal-btn" onClick={RemoveCartModalCloseEvent}><CloseCircle size="24" color="#0000004d" /></button>
                    </div>
                    <div className="tm-modal-body">
                        <Form form={form} autoComplete="off" >
                            <div className="tranfer-modal-data">
                                <div className="confirm-box-message mb-3">
                                    <label htmlFor="" className="fs16 fw500 lh-1 mb-3">Card Number</label>
                                    <Form.Item
                                        name="number"
                                        rules={[{ required: true, numeric: true, message: 'Please enter your card number' }]}
                                    >
                                        <InputMask mask="9999-9999-9999-9999">
                                            {(inputProps) => <Input {...inputProps}
                                                name='number'
                                                placeholder="Enter Card Number"
                                                className="form-control"
                                            />}
                                        </InputMask>
                                    </Form.Item>
                                </div>
                                <div className="flex_item_cs gap-3">
                                    <Button htmlType="submit" className="button button-2 small-height w-100" onClick={() => { props.onClickBtn(form.getFieldValue()); RemoveCartModalCloseEvent(); }}>
                                        Confirm
                                    </Button>
                                </div>
                            </div>
                        </Form>
                    </div>
                </div>
            </Modal>
        </>
    );
};

export default DeleteCardModal;