import { Button, Form, Tooltip } from 'antd'
import React, { useEffect, useState } from 'react'
import OtpInput from 'react-otp-input'
import { useDispatch } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import ToastMe from '../components/common/ToastMe'
import AuthService from '../components/services/auth'

const OtpVerification = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { state } = useLocation();
    const [otp, SetOtp] = useState('');
    const [minutes, setMinutes] = useState(0);
    const [seconds, setSeconds] = useState(60);
    const [resendOtpToken, setResendOtpToken] = useState();
    const [buttonLoading, setButtonLoading] = useState(false);

    const OtpChange = (e) => {
        SetOtp(e);
    }

    const resendOTP = () => {
        setMinutes(0);
        setSeconds(60);
        var data = {}
        if (state.flag == 'register') {
            data['reason'] = 'forSignUp';
            data['token'] = state.token;
        } else if (state.flag == 'forgotOtp') {
            data['reason'] = 'forForgetPassword';
            data['token'] = state.token;
            data['device'] = 3;
        }
        dispatch(AuthService.resendOtp(data))
            .then((res) => {
                if (res) {
                    setResendOtpToken(res.data.token)
                    ToastMe(res.data.message, 'success')
                }
            })
            .catch((errors) => {
                if (errors.errorData.errors.otp) {
                    ToastMe(errors.errorData.errors.otp, 'error')
                }
            })
    };

    const onFinish = () => {
        if (otp == '') {
            ToastMe('Please enter OTP!', 'error')
        } else if (state.flag === 'forgotOtp') {
            var data = {};
            data['otp'] = otp;
            data['token'] = state?.token;
            setButtonLoading(true)
            dispatch(AuthService.forgotOtpVerify(data))
                .then((res) => {
                    setButtonLoading(false)
                    ToastMe(res.data.message, 'success')
                    setTimeout(() => {
                        navigate('/reset-password', { state: { token: res.data.token } })
                    }, 500)
                })
                .catch((errors) => {
                    setButtonLoading(false)
                    ToastMe(errors.errorData.message, 'error')
                })
        } else {
            const data = {};
            data['otp'] = otp;
            var token = '';
            if (resendOtpToken) {
                token = resendOtpToken;
            } else {
                token = state?.token;
            }
            setButtonLoading(true)
            dispatch(AuthService.otpVerify(data, token))
                .then((res) => {
                    setButtonLoading(false)
                    ToastMe(res.data.message, 'success')
                    navigate('/')
                })
                .catch((errors) => {
                    setButtonLoading(false)
                    ToastMe(errors.errorData.message, 'error')
                })
        }
    }

    const timerdata = ({ hours, minutes, seconds, completed }) => {
        if (seconds < 10) {
            seconds = `0${seconds}`;
        };
        if (minutes < 10) {
            minutes = `0${minutes}`;
        };
        if (completed) {
            // Render a completed state
            return <button type="button" className="send-code-link">Resend Code</button>;
        } else {
            // Render a countdowno
            return (
                <span className="code-timer">Resend code in ({minutes}:{seconds})</span>
            );
        }
    };
    
    useEffect(() => {
        const interval = setInterval(() => {
            if (seconds > 0) {
                setSeconds(seconds - 1);
            }

            if (seconds === 0) {
                if (minutes === 0) {
                    clearInterval(interval);
                } else {
                    setSeconds(59);
                    setMinutes(minutes - 1);
                }
            }
        }, 1000);

        return () => {
            clearInterval(interval);
        };
    }, [seconds]);

    return (
        <div className="page-ath-content mx-auto">
            <div className="page-ath-form">
                <div className="auth-head">
                    <h6>Security verification</h6>
                    <p>To secure your account, please complete the following verification.</p>
                </div>
                <Form className="auth-form" onFinish={onFinish}>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="form-group">
                                <label className="label-name label-flex flex_item_cb mb-2">
                                    <span>Enter OTP</span>
                                    <span className="code-sent-info">
                                        <Tooltip title="Haven’t received code? The code will expire after 10 mins." className='flex_item_cs gap-0'>
                                            <span className="small-info"> Verification code send</span>
                                            <img src={require("../assets/img/info_icon.svg").default} alt='' className='icon' loading="lazy"/>
                                        </Tooltip>
                                    </span>
                                </label>
                                <OtpInput
                                    containerStyle="otp-container"
                                    value={otp}
                                    onChange={OtpChange}
                                    numInputs={4}
                                    inputStyle="input-control"
                                    separator={''}
                                />
                                {/* <div className="otp-info-data mb40">
                                    <Countdown date={Date.now() + 600000} renderer={timerdata} />
                                </div> */}
                                <div className="countdown-text my-2">
                                    {seconds > 0 || minutes > 0 ? (
                                        <p className='fs14 fw500 text-dark'>
                                            Time Remaining: {minutes < 10 ? `0${minutes}` : minutes}:
                                            {seconds < 10 ? `0${seconds}` : seconds}
                                        </p>
                                    ) : (
                                        <p className='fs14 fw-500 text-dark'>Didn't recieve code?</p>
                                    )}
                                    {
                                            seconds <= 0 ?
                                            <a href role={"button"}
                                                disabled={seconds > 0 || minutes > 0}
                                                style={{
                                                    color: seconds > 0 || minutes > 0 ? "#DFE3E8" : "#FF5630",
                                                }}
                                                onClick={resendOTP}
                                                className="fs14 fw500"
                                            >
                                                Resend OTP
                                            </a> : ''
                                    }
                                </div>
                            </div>
                        </div>
                        <Button htmlType='submit' loading={buttonLoading} className="button button-2 w-100">
                            Verify
                        </Button>
                    </div>
                </Form>
            </div>
        </div>
    )
}

export default OtpVerification;