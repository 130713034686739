import React from 'react'
import { Link } from 'react-router-dom'

function ErrorPage() {
    return (
        <div className="main-wrapper error_wrapper">
            <div className="flex_item_cc h-100">
                <div className="text_content">
                    <p className="">oops page not found</p>
                    <h1 className="massage">
                        <span className="numaric_1">4</span>
                        <span className="numaric_2">0</span>
                        <span className="numaric_3">4</span>
                    </h1>
                    <p className='mb-3'>we are sorry , but page you are looking for was <br /> not found</p>
                    <div className='text-center'>
                        <Link to={'/'} className='button button-2 text-center' >Go Back To Home</Link>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ErrorPage
