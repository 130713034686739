import React from 'react'
import { Link } from 'react-router-dom'

const DownloadApp = () => {
    return (
        <section id="download" className="download_app gap" style={{ backgroundImage: `url(${require("../../../../assets/images/background-1.png")})` }}>
            <div className="container">
                <div className="row align-items-center gy-4">
                    <div className="col-lg-5">
                        <div className="img_content_resp text-center">
                            <img alt="img" className="mobile_img2 img-fluid" src={require('../../../../assets/images/app-download.png')} />
                        </div>
                    </div>
                    <div className="col-lg-6 offset-lg-1">
                        <div className="text_content">
                            <div className="icon_wrapper text-center text-lg-start mb-3">
                                <img src={require("../../../../assets/images/app_Icon_bordered.png")} alt="" />
                            </div>
                            <h2 className="fs32 fw600 text_dark mb-3 maxw_60 lh_1_3">
                                Available on play
                                Store and App store
                            </h2>
                            <p className="fs16 fw500 text_dark mb-4">Binox Bargains is available on bothandroid and iOS platforms</p>
                            <div className="d-flex align-items-center justify-content-center justify-content-lg-start gap-3 download_btn_container">
                                <div className="">
                                    <Link to="https://play.google.com/store/apps/details?id=com.binoxbargains" className="w-100">
                                        <img src={require("../../../../assets/images/play-store.png")} alt="" className='img-fluid' />
                                    </Link>
                                </div>
                                <div className="">
                                    <Link to="https://apps.apple.com/in/app/binox-bargains/id6447420853" className="w-100">
                                        <img src={require("../../../../assets/images/app-store.png")} alt="" className='img-fluid' />
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default DownloadApp