import * as ActionTypes from '../action-types'

export function authLogin(payload){
    return {
        type: ActionTypes.AUTH_LOGIN,
        payload
    }
}


export function authLogout(){
    return {
        type: ActionTypes.AUTH_LOGOUT
    }
}

export function authCheck(){
    return {
        type:ActionTypes.AUTH_CHECK
    }
}

export function authVerify(payload){
    return {
        type: ActionTypes.AUTH_VERIFY,
        payload
    }
}

export function authForgot(payload){
    return {
        type: ActionTypes.AUTH_FORGOT,
        payload
    }
}

export function setCard(payload){
    return {
        type: ActionTypes.SET_CARD,
        payload
    }
}

export function setUserData(payload){
    return {
        type: ActionTypes.SET_USERDATA,
        payload
    }
}

export function setCartData(payload){
    return {
        type: ActionTypes.SET_CARTDATA,
        payload
    }
}
export function setNotificationData(payload){
    return {
        type: ActionTypes.SET_NOTIFICATIONDATA,
        payload
    }
}
export function setSearchData(payload){
    return {
        type: ActionTypes.SET_SEARCHDATA,
        payload
    }
}
export function setCharityDetailData(payload){
    return {
        type: ActionTypes.SET_CHARITY_DATA,
        payload
    }
}


export function authVerifyStatus(payload){
    return {
        type: ActionTypes.AUTH_VERIFY_STATUS,
        payload
    }
}

export function changeMobile(payload) {
    return {
        type: ActionTypes.CHANGE_MOBILE,
        payload
    }
}

// export function changeLatitude(payload) {
//     return {
//         type: ActionTypes.SET_LAT,
//         payload
//     }
// }

// export function changeLongitude(payload) {
//     return {
//         type: ActionTypes.SET_LONG,
//         payload
//     }
// }
// export function setStripeKey(payload) {
//     return {
//         type: ActionTypes.SET_STRIPEKEY,
//         payload
//     }
// }