import { Add, Minus } from "iconsax-react";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import ToastMe from "../../../common/ToastMe";
import ConfirmModel from "../../../modals/ConfirmModel";
import RemoveCartModal from "../../../modals/RemoveCartModal";
import restaurantService from "../../../services/restruants";
import { Rating } from '@mui/material';
import { Rate } from "antd";

const MenuItems = (props) => {

    const { selectedMenu, menuId, type } = props;
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [foodItems, setFoodItems] = useState();
    const [groceryItems, setGroceryItems] = useState();
    const [itemArray, setItemArray] = useState([]);
    const [priceSum, setPriceSum] = useState(0);
    const [cartItem, setCartItem] = useState();
    const [ConfirmModalOpen, SetConfirmModalOpen] = useState(false);
    const [RemoveCartModalOpen, SetRemoveCartModalOpen] = useState(false);
    const [deleteId, setDeleteId] = useState();
    const [billDetails, setBillDetails] = useState();
    const [anotherCartItem, setAnotherCartItem] = useState();

    const getFoodItem = () => {
        dispatch(restaurantService.getFoodItem(menuId))
            .then((res) => {
                setFoodItems(res.data)
            })
            .catch((errors) => {
                console.log({ errors })
            })
    }

    const getGroceryItem = () => {
        dispatch(restaurantService.getGroceryItem(menuId))
            .then((res) => {
                setGroceryItems(res.data)
            })
            .catch((errors) => {
                console.log({ errors })
            })
    }

    const getCart = (flagHomePickup) => {
        dispatch(restaurantService.getCart(flagHomePickup))
            .then((res) => {
                setCartItem(res.data.cart_data)
                setBillDetails(res.data.BillDetails)
            })
            .catch((errors) => {
                console.log({ errors })
            })
    }

    useEffect(() => {
        if (type === 'food') {
            getFoodItem();
        } else {
            getGroceryItem();
        }
        getCart()
    }, [menuId, type])

    const handleAddToItem = (item) => {
        if (localStorage.getItem('jwt_token')) {
            setPriceSum(priceSum + item.offerPrice)
            setItemArray([...itemArray, item])
            var data = {};
            data['vendor_id'] = item.vendor_id;
            data['item_id'] = item._id;
            dispatch(restaurantService.addCart(data))
                .then((res) => {
                    ToastMe(res.data.message, 'success')
                    getCart();
                })
                .catch((errors) => {
                    if (errors.errorData) {
                        removeCartModalOpen();
                        setAnotherCartItem(item)
                    }
                })
        } else {
            navigate("/login");
            // ToastMe("Please First Login. After You Can Add Item In Cart", 'error')
        }
    }

    const handlePlus = (item, type) => {
        if (item.quantity == 1 && type === 'minus') {
            setDeleteId(item._id);
            confirmModalOpen();
        } else {
            dispatch(restaurantService.addItemQuantity(item._id, type))
                .then((res) => {
                    if (res) {
                        getCart();
                    }
                })
                .catch((errors) => {
                    console.log({ errors })
                })
        }
    }

    const confirmModalOpen = () => {
        SetConfirmModalOpen(true);
    }

    const confirmModalClose = () => {
        SetConfirmModalOpen(false);
    }

    const removeCartModalOpen = () => {
        SetRemoveCartModalOpen(true);
    }

    const removeCartModalClose = () => {
        SetRemoveCartModalOpen(false);
    }

    const deleteData = () => {
        dispatch(restaurantService.deleteItemCart(deleteId))
            .then((res) => {
                if (res.data) {
                    getCart();
                    ToastMe(res.data.message, 'success')
                }
            })
            .catch((errors) => {
                console.log({ errors })
            })
    }

    const deleteCart = () => {
        dispatch(restaurantService.deleteCart())
            .then((res) => {
                if (res.data) {
                    ToastMe(res.data.message, 'success')
                    getCart();
                    handleAddToItem(anotherCartItem)
                }
            })
            .catch((errors) => {
                console.log({ errors })
            })
    }

    return (
        <>
            <div className="section_title mb30">
                <h2 className="fs24">{selectedMenu}</h2>
            </div>
            <div className="menu_items_list custom-scrollbar">
                <ul className="product_list_main">
                    {
                        type === 'food' ?
                            foodItems && foodItems.length > 0 ?
                                foodItems.map((item, i) => {
                                    const isFound = cartItem?.some(element => {
                                        if (element?.fooditems_data?._id === item._id) {
                                            return true;
                                        }
                                    })
                                    var result = cartItem?.filter(obj => {
                                        return obj?.fooditems_data?._id === item._id
                                    })
                                    return (
                                        <li key={i}>
                                            <div className="product_list_item flex_item_cb gap-4">
                                                <div className="product_image flex-shrink-0">
                                                    <img src={process.env.REACT_APP_PROFILE_URL + 'foodItem/' + item.image} className="img-fluid w-100" alt={item.name} loading="lazy"/>
                                                </div>
                                                <div className="product_text flex_item_cb flex-grow-1">
                                                    <div className="left_part">
                                                        <h4>{item.name}</h4>
                                                        <p className='price'><del className=''>£{item.price?.toFixed(2)}</del><ins>£{item.offerPrice?.toFixed(2)}</ins></p>
                                                        <div className="">
                                                            {/* <p className='food_type fw400'><span className='dots'></span> veg Burger</p> */}
                                                            <h6 className="fs18 mb-0 fw500 lh-1 flex_item_cs gap-2">
                                                                <div className="fs18 mb-0 fw500 lh-1 flex_item_cs gap-2 ">
                                                                    <Rate allowHalf name="rating" color="#000" value={item.rating} precision={0.1} readOnly className="" />
                                                                    <span>{item.rating}</span>
                                                                </div>
                                                            </h6>
                                                        </div>
                                                    </div>
                                                    <div className="right_part">
                                                        {
                                                            isFound === true ?
                                                                <div className="">
                                                                    <div className="qty_button">
                                                                        <a role={"button"} className='btn_qty btn_desc'><Minus size="24" color="#333230" onClick={() => handlePlus(result[0], 'minus')} /></a>
                                                                        <input type="number" value={result[0].quantity} className='qty_input' />
                                                                        <a role={"button"} className="btn_qty btn_incrice"><Add size="24" color="#333230" onClick={() => handlePlus(result[0], 'plus')} /></a>
                                                                    </div>
                                                                </div> :
                                                                <button className="button button-2" onClick={() => handleAddToItem(item)} >Add To Cart</button>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                                    )
                                }) :
                                <p>No Items</p>
                            :
                            groceryItems && groceryItems.length > 0 ?
                                groceryItems.map((item, i) => {
                                    const isFound = cartItem?.some(element => {
                                        if (element?.groceryitems_data?._id === item._id) {
                                            return true;
                                        }
                                    })
                                    var result = cartItem?.filter(obj => {
                                        return obj?.groceryitems_data?._id === item._id
                                    })
                                    return (
                                        <li key={i}>
                                            <div className="product_list_item flex_item_cb gap-4">
                                                <div className="product_image flex-shrink-0">
                                                    <Link to="/">
                                                        <img src={process.env.REACT_APP_PROFILE_URL + 'groceryItem/' + item.image} className="img-fluid w-100" alt={item.name} loading="lazy"/>
                                                    </Link>
                                                </div>
                                                <div className="product_text flex_item_cb flex-grow-1">
                                                    <div className="left_part">
                                                        <h4><Link to="/">{item.name}</Link></h4>
                                                        <p className='price'><del className=''>£{item.price?.toFixed(2)}</del><ins>£{item.offerPrice?.toFixed(2)}</ins></p>
                                                        <div className="d-none d-sm-block">
                                                            {/* <p className='food_type fw400'><span className='dots'></span> veg Burger</p> */}
                                                            {/* <div className="fs24 mb-0 fw500 lh-1 flex_item_cs gap-2 ">
                                                                <div className="rating_star_part">
                                                                    <Rating name="rating" color="#000" value={item.rating} precision={0.1} readOnly className="flex_item_cc gap-0" />
                                                                </div>
                                                                <span>{item.rating}</span>
                                                            </div> */}
                                                            <h6 className="fs18 mb-0 fw500 lh-1 flex_item_cs gap-2">
                                                                <div className="fs18 mb-0 fw500 lh-1 flex_item_cs gap-2 ">
                                                                    <Rate allowHalf name="rating" value={item.rating} precision={0.1} readOnly className="" />
                                                                    <span>{item.rating}</span>
                                                                </div>
                                                            </h6>
                                                        </div>
                                                    </div>
                                                    <div className="right_part">
                                                        {
                                                            isFound === true ?
                                                                <div className="">
                                                                    <div className="qty_button">
                                                                        <a role={"button"} className='btn_qty btn_desc'><Minus size="24" color="#333230" onClick={() => handlePlus(result[0], 'minus')} /></a>
                                                                        <input type="number" value={result[0].quantity} className='qty_input' />
                                                                        <a role={"button"} className="btn_qty btn_incrice"><Add size="24" color="#333230" onClick={() => handlePlus(result[0], 'plus')} /></a>
                                                                    </div>
                                                                </div> :
                                                                <button className="button button-2" onClick={() => handleAddToItem(item)} >Add To Cart</button>
                                                        }
                                                        {/* {
                                                            isFound !== true ?
                                                                <button className="button button-2" onClick={() => handleAddToItem(item)} >Add To Cart</button> : ''
                                                        } */}
                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                                    )
                                }) :
                                <p>No Items</p>
                    }
                </ul>
            </div>
            <div className="menu_footer flex_item_cb">
                <h6>{cartItem?.length} ITEM <span>£{billDetails?.subTotal ? billDetails?.subTotal.toFixed(2) : 0}</span></h6>
                <Link to={"/cart"} state={{ itemArray: itemArray, type: type }} className="button button-2">Next</Link>
            </div>
            <ConfirmModel ConfirmModalOpen={ConfirmModalOpen} onClickBtn={deleteData} ConfirmModalCloseEvent={confirmModalClose} />
            <RemoveCartModal RemoveCartModalOpen={RemoveCartModalOpen} onClickBtn={deleteCart} RemoveCartModalCloseEvent={removeCartModalClose} />
        </>
    )
}

export default MenuItems