import {Navigate,Outlet} from "react-router";
import {useEffect, useState} from "react";

const ModuleVerifyRoute = (props) => {
    const [moduleStatus,setModuleStatus] = useState(props.moduleStatus)
    useEffect(()=>{
        setModuleStatus(props.moduleStatus);
    },[props.module]);
    const checkModuleStatus = (module) => {
        if(module){
            if (moduleStatus && moduleStatus !== undefined && moduleStatus.length > 0) {
                 let filteredModule = moduleStatus.find(obj => {
                   return obj.module == module;
                });
                 if(filteredModule && filteredModule['status'] != 1 && (props.userData?.id != '26' && props.userData?.id != '39' && props.userData?.id != '7' )){
                     return false;
                }
            }
        }
        return true;
    }
    return (
        props.module && checkModuleStatus(props.module) == false && (props.userData?.id != '26' && props.userData?.id != '39' && props.userData?.id != '7' )  ?
            <Navigate to={'/under-construction'} />
            :
            <Outlet />

    );
}

export default ModuleVerifyRoute;