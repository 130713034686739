import { Form, Input, Tabs } from 'antd'
import React, { useState } from 'react'
import PhoneInput from 'react-phone-input-2'
import { useDispatch } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'
import ToastMe from '../components/common/ToastMe'
import AuthService from '../components/services/auth'
const { TabPane } = Tabs;

const ForgotPassword = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [form] = Form.useForm();
    const [phoneValue, setPhoneValue] = useState();
    const [isDisabled, setDisabled] = useState(false);

    const submit = (data) => {
        setDisabled(true);
        data['device'] = 1;
        data.mobile = '+' + phoneValue;
        dispatch(AuthService.getOtp(data))
            .then((res) => {
                setTimeout(() => {
                    setDisabled(false);
                }, 2500)
                ToastMe(res.data.message, 'success')
                setTimeout(() => {
                    navigate('/otp-verification', { state: { token: res.data.token, flag: 'forgotOtp' } })
                }, 500)
            })
            .catch((errors) => {
                setTimeout(() => {
                    setDisabled(false);
                }, 2500)
                if (errors.errorData.message) {
                    ToastMe(errors.errorData.message, 'error')
                } else {
                    form.setFields([{ name: 'email', errors: [errors.errorData.errors.email], }])
                }
            })
    }

    const handlePhoneValue = (e) => {
        setPhoneValue(e);
    };

    return (
        <div className="">
            {/* <div className="page-ath-gfx" style={{ backgroundImage: `url(${require("../assets/img/login-image3.jpg")})` }}></div> */}
            <div className="page-ath-content mx-auto">
                <div className="page-ath-form">
                    <div className="auth-head">
                        <h6>Forgot Password</h6>
                        <p>Enter your email to receive a verification code</p>
                    </div>
                    <Form name="basic" form={form} onFinish={submit} labelCol={{ span: 8 }} wrapperCol={{ span: 24 }} initialValues={{ remember: true }} autoComplete="off">
                        <div className="row">
                            <div className="col-md-12">
                                <Tabs defaultActiveKey="mobile" className='login-tabs'>
                                    <TabPane key="mobile" tab="Mobile">
                                        <div class="form-group mb40">
                                            <label class="label-name">Mobile Number</label>
                                            <PhoneInput
                                                country={'us'}
                                                specialLabel="phone"
                                                onChange={(e) => handlePhoneValue(e)}
                                            />
                                        </div>
                                    </TabPane>
                                    <TabPane key="email" tab="Email">
                                        <div className="form-group  mb40">
                                            <label className="label-name">Email</label>
                                            <Form.Item
                                                name="email"
                                                rules={[{ required: true, message: 'Please enter your email address!' },
                                                { pattern: new RegExp(/^([A-Z0-9a-z._%+-])+\@([A-Za-z0-9.-])+(\.[A-Za-z]{2,4})+$/), message: 'Please enter valid email address!' }]}
                                            >
                                                <Input placeholder="Email" className='input-control' />
                                            </Form.Item>
                                        </div>
                                    </TabPane>
                                </Tabs>
                            </div>
                        </div>
                        <input disabled={isDisabled} type="submit" name="" class="button button-2 w-100" value="Next" />
                        {/* <Link to={"/otp-verification"} role="button" className="button button-2 w-100" >Next</Link> */}
                    </Form>
                    <div className="form-footer-link text-center mt-4">
                        <p className="font-open">Remember Password? <Link to={"/login"} className="forgot-link">Login</Link></p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ForgotPassword