import React from 'react';
import { Button, Modal } from 'antd';
import { CloseCircle } from 'iconsax-react';

const RemoveCartModal = (props) => {
  const {RemoveCartModalOpen , RemoveCartModalCloseEvent} = props;

  return (
    <>
      <Modal visible={RemoveCartModalOpen} centered footer={''} closable={false} onCancel={RemoveCartModalCloseEvent} getPopupContainer={node => node.parentNode} className="confirm-cart-modal">
        <div className="">
          <div className="modal-header flex_item_ce mb-2">
            <button className="close-modal-btn" onClick={RemoveCartModalCloseEvent}><CloseCircle size="24" color="#0000004d" /></button>
          </div>
          <div className="tm-modal-body">
            <div className="tranfer-modal-data">
              <div className="confirm-box-message">
                <h6 className='fs24 fw-700 text-dark mb-3'>Confirm Action</h6>
                <p className='mb-4 fs16 fw-500 text-dark lh_1_5'>Your previous item has been removed from cart. If you want to add item from another shop do click on confirm.</p>
              </div>
              <div className="flex_item_cs gap-3">
                <Button type="button" className="button trasns_bordered small-height w-100" onClick={RemoveCartModalCloseEvent}>
                  Cancel
                </Button>
                <Button type="button" className="button button-2 small-height w-100" onClick={() => { props.onClickBtn(); RemoveCartModalCloseEvent(); }}>
                  Confirm
                </Button>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default RemoveCartModal;