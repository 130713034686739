import React from 'react';
import { Route, Redirect, Navigate, Outlet, useLocation } from 'react-router';

const AuthRoute = ({ isAuthenticated, isVerify }) => {
    const location = useLocation();
    return isAuthenticated ?
    <Outlet />
    :
    <Navigate to={'/login'} state={{ from: location }} />
};



export default AuthRoute;