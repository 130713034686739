import { Form, Input, Radio } from 'antd';
import { Edit2, Location, Trash } from 'iconsax-react';
import React, { useEffect, useRef, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import ToastMe from "../../../common/ToastMe";
import AuthService from '../../../services/auth';
import { Autocomplete, useJsApiLoader } from "@react-google-maps/api";

const ManageAddress = (props) => {

	const userData = props?.userData?.userData;
	const { isLoaded } = useJsApiLoader({ id: 'google-map-script', googleMapsApiKey: process.env.REACT_APP_GOOGLE_KEY, libraries: ["drawing", "places"] })
	const dispatch = useDispatch();
	const [address, setAddress] = useState([]);
	const [addressData, setAddressData] = useState([]);
	const [form] = Form.useForm();
	const [locationLat, setLocationLat] = useState();
	const [locationLng, setLocationLng] = useState();
	const [searchAddress, setSearchAddress] = useState();
	const [locationNameValue, setLocationNameValue] = useState();
	const [postCode, setPostCode] = useState();
	const [autoComplete, setAutoComplete] = useState();
	const [locationCountry, setLocationCountry] = useState();
	const [locationState, setLocationState] = useState();
	const [locationCity, setLocationCity] = useState();
	const [addressId, setAddressId] = useState();
	const [type, setType] = useState();

	const onFinish = (data, id) => {
		if (addressData.length != 0) {
			data['id'] = addressData._id;
			data['latitude'] = addressData?.latitude;
			data['longitude'] = addressData?.longitude;
		} else {
			data['latitude'] = locationLat ?? addressData?.latitude;
			data['longitude'] = locationLng ?? addressData?.longitude;
		}
		if (searchAddress === undefined) {
			data['street_address'] = addressData.street_address;
		} else {
			data['street_address'] = searchAddress;
		}
		dispatch(AuthService.AddAddress(data, userData))
			.then((res) => {
				ToastMe("Address Saved Successfully", 'success')
				setSearchAddress('');
				setAddressData('');
				document.getElementById("address_form").reset();
				getAddress();
			})
			.catch((errors) => {
				console.log(errors.data.message, "error");
			})
	};

	const getAddress = () => {
		dispatch(AuthService.getAddress())
			.then((res) => {
				setAddress(res.data)
			})
			.catch((errors) => {
				console.log(errors.data.message, "error");
			})
	}

	const editAddress = (id) => {
		dispatch(AuthService.getAddress())
			.then((res) => {
				res.data.filter((data) => {
					if (data._id == id) {
						setAddressData(data);
					}
				})
			})
			.catch((errors) => {
				console.log({ errors })
			})
	}
	const deleteAddress = (id) => {
		dispatch(AuthService.deleteAddress(id))
			.then((res) => {
				console.log(res);
				getAddress();
			})
			.catch((errors) => {
				console.log({ errors })
			})
	}
	const getAddressId = (e) => {
		setAddressId(e.target.value);
		dispatch(AuthService.defaultAddress(e.target.value, userData))
			.then((res) => {
				ToastMe(res.data.message, 'success')
			})
			.catch((errors) => {
				console.log({ errors })
			})
	}

	useEffect(() => {
		getAddress()
	}, [])

	const onAutoCompleteIsLoad = (inputAutoComplete) => {
		setAutoComplete(inputAutoComplete);
	};

	const onAutoCompletePlaceIsChanged = () => {
		// console.log("auto complete is changed: ", autoComplete.getPlace());
		if (autoComplete !== null) {
			var place = autoComplete.getPlace();
			setSearchAddress(place.formatted_address);
			setLocationLat(place.geometry.location.lat());
			setLocationLng(place.geometry.location.lng());

			for (var i = 0; i < place.address_components.length; i++) {
				var addressType = place.address_components[i].types[0];

				if (addressType === "postal_code") {
					setPostCode(place.address_components[i].long_name);
				}
				if (addressType === "country") {
					setLocationCountry(place.address_components[i]?.long_name)
				}
				if (addressType === "administrative_area_level_1") {
					setLocationState(place.address_components[i].long_name);
				}
				if (addressType === "locality") {
					setLocationCity(place.address_components[i].long_name);
				}
			}
		}
	};
	const locationName = useRef(() => onAutoCompleteIsLoad());

	const onTodoChange = (e) => {
		if (e.target.name === 'street_address') {
			setLocationNameValue(1);
			setSearchAddress(e.target.value)
		}
	}

	useEffect(() => {
		form.setFieldsValue({
			country: locationCountry,
			state: locationState,
			city: locationCity,
			pincode: postCode,
		})
	}, [locationLng, locationLat])

	useEffect(() => {
		form.setFieldsValue({
			address_type: addressData.address_type,
			houseNumber: addressData.houseNumber,
			apartment_society: addressData.apartment_society,
			street_address: addressData.street_address,
			city: addressData.city,
			state: addressData.state,
			country: addressData.country,
			pincode: addressData.pincode,
		})
	}, [addressData])

	const chnageAddress = (e, type) => {
		switch (type) {
			case 'edit':
				return (
					editAddress(e)
				);
			case 'delete':
				return (
					deleteAddress(e)
				)
			case 'default': (
				getAddressId(e)
			)
		}
	}

	return (
		<>
			<section className="section profile_page_wrapper gap50">
				<div className="container">
					<div className="section_title">
						<h2>Manage Address</h2>
					</div>
					<div className="">
						{address.length != 0 ?
							<div className="manage_address_card mb40">
								<div className="row">
									<div className="col-xl-8">
										<div className="custom_radio_wrap">
											<div className="row gy-3">
												{address && address?.map((add, i) => {
													return (
														<div className="col-lg-6" key={i}>
															<div className="custom_radio_group mb-4 mb-lg-0">
																<input type="radio" className="radio_input" id={`address_${i}`} onChange={(e) => chnageAddress(e, 'default')} name={`address_${i}`} value={add._id}
																	checked={userData?.defaultAddress == add._id ? true : false} />
																<label htmlFor={`address_${i}`} className="radio_label">
																	<div className="address_box flex_item_sb gap-2">
																		<div className="address_box_text">
																			<h6 className="flex_item_cs gap-2 mb-2 fs18 lh-1 fw600"><Location size="24" color='#818181' />
																				{add.address_type == 0 ? 'Home' : "Office"}</h6>
																			<p className="fs16 lh_1_5 mb-0 fw400">{add.houseNumber}, {add.apartment_society}, {add.street_address}</p>
																		</div>
																		<div className="address_box_action flex_item_cs">
																			<a role="button" onClick={() => chnageAddress(add._id, 'edit')}><Edit2 size="24" color="#818181" /></a>
																			<a role="button" onClick={() => chnageAddress(add._id, 'delete')}><Trash size="24" color="#818181" /></a>
																		</div>
																	</div>
																</label>
															</div>
														</div>
													)
												})}
											</div>
										</div>
									</div>
								</div>
							</div>
							:
							''
						}
						<div className="row">
							<div className="col-xl-8">
								<Form form={form} autoComplete="off" id="address_form" onFinish={onFinish}
									className="address_form"
								>
									<div className="profile_card_widget mb10">
										<h5 className="fs20 lh-1 fw600 mb20">Add New Address</h5>
										<div className="col-md-12">
											<div className="form-group mb-3">
												<label className="label-name">Select Address Type</label>
												<Form.Item
													name="address_type"
													rules={[{ required: true, message: 'Please Select Your address type' }]}
												>
													<Radio.Group name="address_type">
														<Radio checked={addressData?.address_type == 0} value={0} onClick={() => setType('home')}>Home</Radio>
														<Radio checked={addressData?.address_type == 1} value={1} onClick={() => setType('office')}>Office</Radio>
													</Radio.Group>
												</Form.Item>
											</div>
										</div>
										<div className="row">
											<div className="col-lg-6">
												<div className="form-group mb20">
													{
														type === 'office' ?
															<label className="fs16 fw500 lh-1 mb-3">Office Number</label> :
															<label className="fs16 fw500 lh-1 mb-3">House Number</label>
													}
													<Form.Item
														name="houseNumber"
														rules={[{ required: true, numeric: true, message: 'Please enter your house number' }]}
													>
														<Input value={addressData?.houseNumber ?? ''} placeholder={type === 'office' ? 'Enter Office Number' : 'Enter House Number'} className="form-control" />
													</Form.Item>
												</div>
											</div>
											<div className="col-lg-6">
												<div className="form-group mb20">
													<label className="fs16 fw500 lh-1 mb-3">Appartment/Society</label>
													<Form.Item
														name="apartment_society"
														rules={[{ required: true, message: 'Please enter your appartment society' }]}
													>
														<Input value={addressData?.apartment_society} placeholder="Enter Appartment/Society" className="form-control" />
													</Form.Item>
												</div>
											</div>
											<div className="form-group mb-4 select_location_from_group">
												<label className="label-name label-small-bold">Search Street Address</label>
												<Form.Item
													name="street_address"
												>
													{
														isLoaded ?
															<Autocomplete
																onLoad={onAutoCompleteIsLoad}
																onPlaceChanged={onAutoCompletePlaceIsChanged}
															>
																<div className='location_input customized_before'>
																	<Input
																		ref={locationName}
																		prefix={<i className="ri-search-line search-icon fw-500"></i>}
																		name='street_address'
																		className="form-control"
																		placeholder="Search Address"
																		value={locationNameValue ? searchAddress : addressData.street_address ? addressData.street_address : ""}
																		onChange={e => onTodoChange(e)}
																	/>
																</div>
															</Autocomplete> : ''
													}
												</Form.Item>
											</div>
											<div className="col-lg-6">
												<div className="form-group mb20">
													<label className="fs16 fw500 lh-1 mb-3">City</label>
													<Form.Item
														name="city"
														rules={[{ required: true, message: 'Please enter your city' }]}
													>
														<Input value={addressData?.city ?? ''} placeholder="Enter City" className="form-control" />
													</Form.Item>
												</div>
											</div>
											<div className="col-lg-6">
												<div className="form-group mb20">
													<label className="fs16 fw500 lh-1 mb-3">State</label>
													<Form.Item
														name="state"
														rules={[{ required: true, message: 'Please enter your state' }]}
													>
														<Input value={addressData?.state ?? ''} placeholder="Enter State" className="form-control" />
													</Form.Item>
												</div>
											</div>
											<div className="col-lg-6">
												<div className="form-group mb20">
													<label className="fs16 fw500 lh-1 mb-3">Country</label>
													<Form.Item
														name="country"
														rules={[{ required: true, message: 'Please enter your country' }]}
													>
														<Input value={addressData?.country ?? ''} placeholder="Enter Country" className="form-control" />
													</Form.Item>
												</div>
											</div>

											<div className="col-lg-6">
												<div className="form-group mb20">
													<label className="fs16 fw500 lh-1 mb-3">Pincode</label>
													<Form.Item
														name="pincode"
														rules={[{ required: true, message: 'Please enter your pincode' },
														{ max: 10, message: 'You can not enter more than 10 characters or numbers' },]}
													>
														<Input value={addressData?.pincode ?? ''} placeholder="Enter Pincode" className="form-control" />
													</Form.Item>
												</div>
											</div>
										</div>
									</div>
									<div className="profile_card_widget">
										<div className="row">
											<div className="col-lg-6">
												<button type="submit" className="button button-2 w-100 fs18 fw600">Add Address</button>
											</div>
										</div>
									</div>
								</Form>
							</div>
						</div>
					</div>
				</div>
			</section>
		</>
	)
}

const mapStateToProps = state => {
	return {
		isAuthenticated: state.Auth.isAuthenticated,
		userData: state.Auth.userData
	}
}
export default connect(mapStateToProps)(ManageAddress);