import Http from '../../Http'
import * as action from '../../store/actions'
// import ToastMe from '../../view/common/ToastMe';
const BaseUrl = process.env.REACT_APP_BASE_URL + '/user/web';

export function getGroceryies(data) {
    
    let url = ''
    if (data != undefined) {
        url = `/groceryShops?search=` + data?.search + `&latitude=` + data?.latitude + `&longitude=` + data?.longitude
    } else {
        url = `/groceryShops`
    }
    return dispatch => (
        new Promise((resolve, reject) => {

            Http.callApi('get', BaseUrl + url)
                .then(function (res) {
                    return resolve(res);

                })
                .catch(function (error) {
                    console.log({ error })
                    // if (error.response.data.errors && "g_recaptcha_response" in error.response.data.errors) {
                    //     // ToastMe("Please refresh the page for Google reCAPTCHA authentication", 'danger');
                    // } else {
                    //     // ToastMe(error.response.data.message, 'danger');
                    // }
                    const data = {
                        errorData: error.response.data.errors,
                        statusCode: error.response.status,
                    };
                    return reject(data);
                })

        })
    )
}

export function getResGroceryies(data) {
    
    let url = ''
    if (data != undefined) {
        url = `/groceryResShops?search=` + data?.search + `&latitude=` + data?.latitude + `&longitude=` + data?.longitude
    } else {
        url = `/groceryResShops`
    }
    return dispatch => (
        new Promise((resolve, reject) => {
            Http.callApi('get', BaseUrl + url)
                .then(function (res) {
                    return resolve(res);
                })
                .catch(function (error) {
                    console.log({ error })
                    const data = {
                        errorData: error.response.data.errors,
                        statusCode: error.response.status,
                    };
                    return reject(data);
                })

        })
    )
}
export function getSpecialOffer(type) {
    return dispatch => (
        new Promise((resolve, reject) => {
            Http.callApi('get', BaseUrl + '/getSpecialOffer?ads_type=' + type)
                .then(function (res) {
                    return resolve(res);

                })
                .catch(function (error) {
                    console.log({ error })
                    // if (error.response.data.errors && "g_recaptcha_response" in error.response.data.errors) {
                    //     // ToastMe("Please refresh the page for Google reCAPTCHA authentication", 'danger');
                    // } else {
                    //     // ToastMe(error.response.data.message, 'danger');
                    // }
                    const data = {
                        errorData: error.response.data.errors,
                        statusCode: error.response.status,
                    };
                    return reject(data);
                })

        })
    )
}