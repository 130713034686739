import Http from '../../Http';
const BaseUrl = process.env.REACT_APP_BASE_URL;

export function getOrderHistory(data) {
    return dispatch => (
        new Promise((resolve, reject) => {
            Http.callApi('get', BaseUrl + '/user/orderHistory?type=' + data)
                .then(function (res) {
                    return resolve(res);
                })
                .catch(function (error) {
                    const data = {
                        errorData: error.response.data.errors,
                        statusCode: error.response.status,
                    };
                    return reject(data);
                })

        })
    )
}
export function getOrderDetail(data) {
    return dispatch => (
        new Promise((resolve, reject) => {
            Http.callApi('get', BaseUrl + '/user/orderDetails?type=' + data.type + '&order_id=' + data.order_id)
                .then(function (res) {
                    return resolve(res);
                })
                .catch(function (error) {
                    const data = {
                        errorData: error.response.data.errors,
                        statusCode: error.response.status,
                    };
                    return reject(data);
                })

        })
    )
}
