import React from 'react'

const HowitsWorkStep3 = () => {
    return (
        <div className="step_3">
            <div className="row align-items-center gy-4 flex-lg-row-reverse">
                <div className="col-lg-5">
                    <div className="img_content_resp text-center">
                        <img alt="img" className="mobile_img2 img-fluid" src={require('../../assets/images/illustartion_2_new.png')} />
                    </div>
                </div>
                <div className="col-lg-6 offset-lg-1">
                    <div className="text_content">
                        <h2 className="fs32 fw600 text_dark mb-3 maxw_60  lh_1_3">Select and place Order</h2>
                        <p className="fs16 fw500 text_dark mb-3">Select your preferred items from our list of already discounted products, add them to your basket, and proceed to a secure checkout with ease.</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default HowitsWorkStep3