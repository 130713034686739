import { Add, Heart, Location, Minus } from 'iconsax-react';
import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Slider from "react-slick";
import apiData from '../../../common/apiData/ProductData';

const ShopsDetails = (props) => {
    const routeParams = useParams();
    const [productData, setProductData] = useState({})
    useEffect(() => {
        let newArry = []
        newArry = apiData.filter((prevData) => {
            return prevData.shopName == routeParams.slug
        })
        setProductData(...newArry)
    }, [])
    return (
        <>
            <section className="section gap shop_detials_banner bg_lightgray">
                <div className="container">
                    <div className="shop_detials_banner_main">
                        <div className="banner" style={{backgroundImage:'url()'}}>
                            
                        </div>
                        <div className="shop_logo">
                            <img src={require("../../../../assets/images/icons/shop_logo.svg").default} className="img-fluid" alt="" />
                        </div>
                        <div className="shop_text flex_item_cs gap-3 mt-3">
                            <h1 className="fs32 mb-0 fw600 lh-1">{productData.shopName}</h1>
                            <h6 className="fs14 mb-0 fw500 lh-1 food_type gap-2"><span className="dots"></span> Veg Burger</h6>
                            <h6 className='fs14 mb-0 fw500 lh-1 text_c818181 flex_item_cs gap-2'><Location size="20" color="#818181" /> l.s Road 5.3 Km</h6>
                            <h6 className="fs14 mb-0 fw500 lh-1 flex_item_cs gap-2 rating_star_part">
                                <div className="rating_star flex_item_cs gap-2">
                                    <img src={require("../../../../assets/images/icons/icn_rate.svg").default} className="img-fluid" alt="rating icon" />
                                    <img src={require("../../../../assets/images/icons/icn_rate.svg").default} className="img-fluid" alt="rating icon" />
                                    <img src={require("../../../../assets/images/icons/icn_rate.svg").default} className="img-fluid" alt="rating icon" />
                                    <img src={require("../../../../assets/images/icons/icn_rate.svg").default} className="img-fluid" alt="rating icon" />
                                    <img src={require("../../../../assets/images/icons/icn_rate_light.svg").default} className="img-fluid" alt="rating icon" />
                                </div>
                                <span>4.0</span>
                            </h6>
                        </div>
                    </div>
                </div>
            </section>
            <section className='section shops_details_section gap50'>
                <div className="container">
                    <div className="row">
                        <div className="col-xl-3 col-lg-4 custom_col_left">
                            <div className="shop_menu_category mb30">
                                <ul className=''>
                                    <li><a role="button" className="active">Burger & Wraps</a></li>
                                    <li><a role="button">What’s New</a></li>
                                    <li><a role="button">Sides</a></li>
                                    <li><a role="button">Beverages</a></li>
                                    <li><a role="button">Desserts</a></li>
                                    <li><a role="button">Breakfast With Bk</a></li>
                                    <li><a role="button">Chicken Winges</a></li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-xl-9 col-lg-8 custom_col_right">
                            <div className="menu_items_card">
                                <div className="section_title mb30">
                                    <h2 className="fs24">Burger & Wraps</h2>
                                </div>
                                <div className="menu_items_list custom-scrollbar">
                                    <ul className="product_list_main">
                                        <li>
                                            <div className="product_list_item flex_item_cb gap-4">
                                                <div className="product_image flex-shrink-0">
                                                    <Link to="/"><img src={require('../../../../assets/images/product_1.jpg')} className="img-fluid" alt="" /></Link>
                                                </div>
                                                <div className="product_text flex_item_cb flex-grow-1">
                                                    <div className="left_part">
                                                        <h4><Link to="/">Crispy veg Burger</Link></h4>
                                                        <p className='price'><del className=''>£5</del><ins>£2</ins></p>
                                                        <div className="d-none d-sm-block">
                                                            <p className='food_type fw400'><span className='dots'></span> veg Burger</p>
                                                            <div className="fs24 mb-0 fw500 lh-1 flex_item_cs gap-2 rating_star_part">
                                                                <div className="rating_star flex_item_cs gap-2">
                                                                    <img src={require("../../../../assets/images/icons/icn_rate.svg").default} className="img-fluid" width={24} alt="rating icon" />
                                                                    <img src={require("../../../../assets/images/icons/icn_rate.svg").default} className="img-fluid" width={24} alt="rating icon" />
                                                                    <img src={require("../../../../assets/images/icons/icn_rate.svg").default} className="img-fluid" width={24} alt="rating icon" />
                                                                    <img src={require("../../../../assets/images/icons/icn_rate.svg").default} className="img-fluid" width={24} alt="rating icon" />
                                                                    <img src={require("../../../../assets/images/icons/icn_rate_light.svg").default} className="img-fluid" width={24} alt="rating icon" />
                                                                </div>
                                                                <span>4.0</span>
                                                            </div>
                                                        </div>
                                                        <div className="d-sm-none d-block">
                                                            <div className="flex_item_cs">
                                                                <div className="rating_star_part">
                                                                    <div className="rating_star flex_item_cs gap-2">
                                                                        <img src={require("../../../../assets/images/icons/icn_rate.svg").default} className="img-fluid" width={24} alt="rating icon" />
                                                                        <span>4.0</span>
                                                                    </div>
                                                                </div>
                                                                <p className='food_type fw400 mb-0 gap-2'><span className='dots'></span> Veg Burger</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="right_part">
                                                        {/* <button className="button button-2">Add To Cart</button> */}
                                                        <div className="">
                                                            <div className="qty_button">
                                                                <a role={"button"} className='btn_qty btn_desc'><Minus size="24" color="#333230" /></a>
                                                                <input type="number" value={1} className='qty_input' />
                                                                <a role={"button"} className="btn_qty btn_incrice"><Add size="24" color="#333230" /></a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="product_list_item flex_item_cb gap-4">
                                                <div className="product_image flex-shrink-0">
                                                    <Link to="/"><img src={require('../../../../assets/images/product_1.jpg')} className="img-fluid" alt="" /></Link>
                                                </div>
                                                <div className="product_text flex_item_cb flex-grow-1">
                                                    <div className="left_part">
                                                        <h4><Link to="/">Crispy veg Burger</Link></h4>
                                                        <p className='price'><del className=''>£5</del><ins>£2</ins></p>
                                                        <div className="d-none d-sm-block">
                                                            <p className='food_type fw400'><span className='dots'></span> veg Burger</p>
                                                            <div className="fs24 mb-0 fw500 lh-1 flex_item_cs gap-2 rating_star_part">
                                                                <div className="rating_star flex_item_cs gap-2">
                                                                    <img src={require("../../../../assets/images/icons/icn_rate.svg").default} className="img-fluid" width={24} alt="rating icon" />
                                                                    <img src={require("../../../../assets/images/icons/icn_rate.svg").default} className="img-fluid" width={24} alt="rating icon" />
                                                                    <img src={require("../../../../assets/images/icons/icn_rate.svg").default} className="img-fluid" width={24} alt="rating icon" />
                                                                    <img src={require("../../../../assets/images/icons/icn_rate.svg").default} className="img-fluid" width={24} alt="rating icon" />
                                                                    <img src={require("../../../../assets/images/icons/icn_rate_light.svg").default} className="img-fluid" width={24} alt="rating icon" />
                                                                </div>
                                                                <span>4.0</span>
                                                            </div>
                                                        </div>
                                                        <div className="d-sm-none d-block">
                                                            <div className="flex_item_cs">
                                                                <div className="rating_star_part">
                                                                    <div className="rating_star flex_item_cs gap-2">
                                                                        <img src={require("../../../../assets/images/icons/icn_rate.svg").default} className="img-fluid" width={24} alt="rating icon" />
                                                                        <span>4.0</span>
                                                                    </div>
                                                                </div>
                                                                <p className='food_type fw400 mb-0 gap-2'><span className='dots'></span> Veg Burger</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="right_part">
                                                        {/* <button className="button button-2">Add To Cart</button> */}
                                                        <div className="">
                                                            <div className="qty_button">
                                                                <a role={"button"} className='btn_qty btn_desc'><Minus size="24" color="#333230" /></a>
                                                                <input type="number" value={1} className='qty_input' />
                                                                <a role={"button"} className="btn_qty btn_incrice"><Add size="24" color="#333230" /></a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="product_list_item flex_item_cb gap-4">
                                                <div className="product_image flex-shrink-0">
                                                    <Link to="/"><img src={require('../../../../assets/images/product_1.jpg')} className="img-fluid" alt="" /></Link>
                                                </div>
                                                <div className="product_text flex_item_cb flex-grow-1">
                                                    <div className="left_part">
                                                        <h4><Link to="/">Crispy veg Burger</Link></h4>
                                                        <p className='price'><del className=''>£5</del><ins>£2</ins></p>
                                                        <div className="d-none d-sm-block">
                                                            <p className='food_type fw400'><span className='dots'></span> veg Burger</p>
                                                            <div className="fs24 mb-0 fw500 lh-1 flex_item_cs gap-2 rating_star_part">
                                                                <div className="rating_star flex_item_cs gap-2">
                                                                    <img src={require("../../../../assets/images/icons/icn_rate.svg").default} className="img-fluid" width={24} alt="rating icon" />
                                                                    <img src={require("../../../../assets/images/icons/icn_rate.svg").default} className="img-fluid" width={24} alt="rating icon" />
                                                                    <img src={require("../../../../assets/images/icons/icn_rate.svg").default} className="img-fluid" width={24} alt="rating icon" />
                                                                    <img src={require("../../../../assets/images/icons/icn_rate.svg").default} className="img-fluid" width={24} alt="rating icon" />
                                                                    <img src={require("../../../../assets/images/icons/icn_rate_light.svg").default} className="img-fluid" width={24} alt="rating icon" />
                                                                </div>
                                                                <span>4.0</span>
                                                            </div>
                                                        </div>
                                                        <div className="d-sm-none d-block">
                                                            <div className="flex_item_cs">
                                                                <div className="rating_star_part">
                                                                    <div className="rating_star flex_item_cs gap-2">
                                                                        <img src={require("../../../../assets/images/icons/icn_rate.svg").default} className="img-fluid" width={24} alt="rating icon" />
                                                                        <span>4.0</span>
                                                                    </div>
                                                                </div>
                                                                <p className='food_type fw400 mb-0 gap-2'><span className='dots'></span> Veg Burger</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="right_part">
                                                        {/* <button className="button button-2">Add To Cart</button> */}
                                                        <div className="">
                                                            <div className="qty_button">
                                                                <a role={"button"} className='btn_qty btn_desc'><Minus size="24" color="#333230" /></a>
                                                                <input type="number" value={1} className='qty_input' />
                                                                <a role={"button"} className="btn_qty btn_incrice"><Add size="24" color="#333230" /></a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="product_list_item flex_item_cb gap-4">
                                                <div className="product_image flex-shrink-0">
                                                    <Link to="/"><img src={require('../../../../assets/images/product_1.jpg')} className="img-fluid" alt="" /></Link>
                                                </div>
                                                <div className="product_text flex_item_cb flex-grow-1">
                                                    <div className="left_part">
                                                        <h4><Link to="/">Crispy veg Burger</Link></h4>
                                                        <p className='price'><del className=''>£5</del><ins>£2</ins></p>
                                                        <div className="d-none d-sm-block">
                                                            <p className='food_type fw400'><span className='dots'></span> veg Burger</p>
                                                            <div className="fs24 mb-0 fw500 lh-1 flex_item_cs gap-2 rating_star_part">
                                                                <div className="rating_star flex_item_cs gap-2">
                                                                    <img src={require("../../../../assets/images/icons/icn_rate.svg").default} className="img-fluid" width={24} alt="rating icon" />
                                                                    <img src={require("../../../../assets/images/icons/icn_rate.svg").default} className="img-fluid" width={24} alt="rating icon" />
                                                                    <img src={require("../../../../assets/images/icons/icn_rate.svg").default} className="img-fluid" width={24} alt="rating icon" />
                                                                    <img src={require("../../../../assets/images/icons/icn_rate.svg").default} className="img-fluid" width={24} alt="rating icon" />
                                                                    <img src={require("../../../../assets/images/icons/icn_rate_light.svg").default} className="img-fluid" width={24} alt="rating icon" />
                                                                </div>
                                                                <span>4.0</span>
                                                            </div>
                                                        </div>
                                                        <div className="d-sm-none d-block">
                                                            <div className="flex_item_cs">
                                                                <div className="rating_star_part">
                                                                    <div className="rating_star flex_item_cs gap-2">
                                                                        <img src={require("../../../../assets/images/icons/icn_rate.svg").default} className="img-fluid" width={24} alt="rating icon" />
                                                                        <span>4.0</span>
                                                                    </div>
                                                                </div>
                                                                <p className='food_type fw400 mb-0 gap-2'><span className='dots'></span> Veg Burger</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="right_part">
                                                        {/* <button className="button button-2">Add To Cart</button> */}
                                                        <div className="">
                                                            <div className="qty_button">
                                                                <a role={"button"} className='btn_qty btn_desc'><Minus size="24" color="#333230" /></a>
                                                                <input type="number" value={1} className='qty_input' />
                                                                <a role={"button"} className="btn_qty btn_incrice"><Add size="24" color="#333230" /></a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="product_list_item flex_item_cb gap-4">
                                                <div className="product_image flex-shrink-0">
                                                    <Link to="/"><img src={require('../../../../assets/images/product_1.jpg')} className="img-fluid" alt="" /></Link>
                                                </div>
                                                <div className="product_text flex_item_cb flex-grow-1">
                                                    <div className="left_part">
                                                        <h4><Link to="/">Crispy veg Burger</Link></h4>
                                                        <p className='price'><del className=''>£5</del><ins>£2</ins></p>
                                                        <div className="d-none d-sm-block">
                                                            <p className='food_type fw400'><span className='dots'></span> veg Burger</p>
                                                            <div className="fs24 mb-0 fw500 lh-1 flex_item_cs gap-2 rating_star_part">
                                                                <div className="rating_star flex_item_cs gap-2">
                                                                    <img src={require("../../../../assets/images/icons/icn_rate.svg").default} className="img-fluid" width={24} alt="rating icon" />
                                                                    <img src={require("../../../../assets/images/icons/icn_rate.svg").default} className="img-fluid" width={24} alt="rating icon" />
                                                                    <img src={require("../../../../assets/images/icons/icn_rate.svg").default} className="img-fluid" width={24} alt="rating icon" />
                                                                    <img src={require("../../../../assets/images/icons/icn_rate.svg").default} className="img-fluid" width={24} alt="rating icon" />
                                                                    <img src={require("../../../../assets/images/icons/icn_rate_light.svg").default} className="img-fluid" width={24} alt="rating icon" />
                                                                </div>
                                                                <span>4.0</span>
                                                            </div>
                                                        </div>
                                                        <div className="d-sm-none d-block">
                                                            <div className="flex_item_cs">
                                                                <div className="rating_star_part">
                                                                    <div className="rating_star flex_item_cs gap-2">
                                                                        <img src={require("../../../../assets/images/icons/icn_rate.svg").default} className="img-fluid" width={24} alt="rating icon" />
                                                                        <span>4.0</span>
                                                                    </div>
                                                                </div>
                                                                <p className='food_type fw400 mb-0 gap-2'><span className='dots'></span> Veg Burger</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="right_part">
                                                        {/* <button className="button button-2">Add To Cart</button> */}
                                                        <div className="">
                                                            <div className="qty_button">
                                                                <a role={"button"} className='btn_qty btn_desc'><Minus size="24" color="#333230" /></a>
                                                                <input type="number" value={1} className='qty_input' />
                                                                <a role={"button"} className="btn_qty btn_incrice"><Add size="24" color="#333230" /></a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="product_list_item flex_item_cb gap-4">
                                                <div className="product_image flex-shrink-0">
                                                    <Link to="/"><img src={require('../../../../assets/images/product_1.jpg')} className="img-fluid" alt="" /></Link>
                                                </div>
                                                <div className="product_text flex_item_cb flex-grow-1">
                                                    <div className="left_part">
                                                        <h4><Link to="/">Crispy veg Burger</Link></h4>
                                                        <p className='price'><del className=''>£5</del><ins>£2</ins></p>
                                                        <div className="d-none d-sm-block">
                                                            <p className='food_type fw400'><span className='dots'></span> veg Burger</p>
                                                            <div className="fs24 mb-0 fw500 lh-1 flex_item_cs gap-2 rating_star_part">
                                                                <div className="rating_star flex_item_cs gap-2">
                                                                    <img src={require("../../../../assets/images/icons/icn_rate.svg").default} className="img-fluid" width={24} alt="rating icon" />
                                                                    <img src={require("../../../../assets/images/icons/icn_rate.svg").default} className="img-fluid" width={24} alt="rating icon" />
                                                                    <img src={require("../../../../assets/images/icons/icn_rate.svg").default} className="img-fluid" width={24} alt="rating icon" />
                                                                    <img src={require("../../../../assets/images/icons/icn_rate.svg").default} className="img-fluid" width={24} alt="rating icon" />
                                                                    <img src={require("../../../../assets/images/icons/icn_rate_light.svg").default} className="img-fluid" width={24} alt="rating icon" />
                                                                </div>
                                                                <span>4.0</span>
                                                            </div>
                                                        </div>
                                                        <div className="d-sm-none d-block">
                                                            <div className="flex_item_cs">
                                                                <div className="rating_star_part">
                                                                    <div className="rating_star flex_item_cs gap-2">
                                                                        <img src={require("../../../../assets/images/icons/icn_rate.svg").default} className="img-fluid" width={24} alt="rating icon" />
                                                                        <span>4.0</span>
                                                                    </div>
                                                                </div>
                                                                <p className='food_type fw400 mb-0 gap-2'><span className='dots'></span> Veg Burger</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="right_part">
                                                        {/* <button className="button button-2">Add To Cart</button> */}
                                                        <div className="">
                                                            <div className="qty_button">
                                                                <a role={"button"} className='btn_qty btn_desc'><Minus size="24" color="#333230" /></a>
                                                                <input type="number" value={1} className='qty_input' />
                                                                <a role={"button"} className="btn_qty btn_incrice"><Add size="24" color="#333230" /></a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="product_list_item flex_item_cb gap-4">
                                                <div className="product_image flex-shrink-0">
                                                    <Link to="/"><img src={require('../../../../assets/images/product_1.jpg')} className="img-fluid" alt="" /></Link>
                                                </div>
                                                <div className="product_text flex_item_cb flex-grow-1">
                                                    <div className="left_part">
                                                        <h4><Link to="/">Crispy veg Burger</Link></h4>
                                                        <p className='price'><del className=''>£5</del><ins>£2</ins></p>
                                                        <div className="d-none d-sm-block">
                                                            <p className='food_type fw400'><span className='dots'></span> veg Burger</p>
                                                            <div className="fs24 mb-0 fw500 lh-1 flex_item_cs gap-2 rating_star_part">
                                                                <div className="rating_star flex_item_cs gap-2">
                                                                    <img src={require("../../../../assets/images/icons/icn_rate.svg").default} className="img-fluid" width={24} alt="rating icon" />
                                                                    <img src={require("../../../../assets/images/icons/icn_rate.svg").default} className="img-fluid" width={24} alt="rating icon" />
                                                                    <img src={require("../../../../assets/images/icons/icn_rate.svg").default} className="img-fluid" width={24} alt="rating icon" />
                                                                    <img src={require("../../../../assets/images/icons/icn_rate.svg").default} className="img-fluid" width={24} alt="rating icon" />
                                                                    <img src={require("../../../../assets/images/icons/icn_rate_light.svg").default} className="img-fluid" width={24} alt="rating icon" />
                                                                </div>
                                                                <span>4.0</span>
                                                            </div>
                                                        </div>
                                                        <div className="d-sm-none d-block">
                                                            <div className="flex_item_cs">
                                                                <div className="rating_star_part">
                                                                    <div className="rating_star flex_item_cs gap-2">
                                                                        <img src={require("../../../../assets/images/icons/icn_rate.svg").default} className="img-fluid" width={24} alt="rating icon" />
                                                                        <span>4.0</span>
                                                                    </div>
                                                                </div>
                                                                <p className='food_type fw400 mb-0 gap-2'><span className='dots'></span> Veg Burger</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="right_part">
                                                        {/* <button className="button button-2">Add To Cart</button> */}
                                                        <div className="">
                                                            <div className="qty_button">
                                                                <a role={"button"} className='btn_qty btn_desc'><Minus size="24" color="#333230" /></a>
                                                                <input type="number" value={1} className='qty_input' />
                                                                <a role={"button"} className="btn_qty btn_incrice"><Add size="24" color="#333230" /></a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="product_list_item flex_item_cb gap-4">
                                                <div className="product_image flex-shrink-0">
                                                    <Link to="/"><img src={require('../../../../assets/images/product_1.jpg')} className="img-fluid" alt="" /></Link>
                                                </div>
                                                <div className="product_text flex_item_cb flex-grow-1">
                                                    <div className="left_part">
                                                        <h4><Link to="/">Crispy veg Burger</Link></h4>
                                                        <p className='price'><del className=''>£5</del><ins>£2</ins></p>
                                                        <div className="d-none d-sm-block">
                                                            <p className='food_type fw400'><span className='dots'></span> veg Burger</p>
                                                            <div className="fs24 mb-0 fw500 lh-1 flex_item_cs gap-2 rating_star_part">
                                                                <div className="rating_star flex_item_cs gap-2">
                                                                    <img src={require("../../../../assets/images/icons/icn_rate.svg").default} className="img-fluid" width={24} alt="rating icon" />
                                                                    <img src={require("../../../../assets/images/icons/icn_rate.svg").default} className="img-fluid" width={24} alt="rating icon" />
                                                                    <img src={require("../../../../assets/images/icons/icn_rate.svg").default} className="img-fluid" width={24} alt="rating icon" />
                                                                    <img src={require("../../../../assets/images/icons/icn_rate.svg").default} className="img-fluid" width={24} alt="rating icon" />
                                                                    <img src={require("../../../../assets/images/icons/icn_rate_light.svg").default} className="img-fluid" width={24} alt="rating icon" />
                                                                </div>
                                                                <span>4.0</span>
                                                            </div>
                                                        </div>
                                                        <div className="d-sm-none d-block">
                                                            <div className="flex_item_cs">
                                                                <div className="rating_star_part">
                                                                    <div className="rating_star flex_item_cs gap-2">
                                                                        <img src={require("../../../../assets/images/icons/icn_rate.svg").default} className="img-fluid" width={24} alt="rating icon" />
                                                                        <span>4.0</span>
                                                                    </div>
                                                                </div>
                                                                <p className='food_type fw400 mb-0 gap-2'><span className='dots'></span> Veg Burger</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="right_part">
                                                        {/* <button className="button button-2">Add To Cart</button> */}
                                                        <div className="">
                                                            <div className="qty_button">
                                                                <a role={"button"} className='btn_qty btn_desc'><Minus size="24" color="#333230" /></a>
                                                                <input type="number" value={1} className='qty_input' />
                                                                <a role={"button"} className="btn_qty btn_incrice"><Add size="24" color="#333230" /></a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                                <div className="menu_footer flex_item_cb">
                                    <h6>2 ITEM <span>£6.5</span></h6>
                                    <Link to={"/cart"} className="button button-2">Next</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default ShopsDetails;