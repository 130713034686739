import { Modal } from 'antd';
import React from 'react'

const PaymentFailedModal = (props) => {
    const { paymentFailedModalOpen, setPaymentFailedModalOpen } = props;
    return (
        <Modal
            centered
            open={paymentFailedModalOpen}
            onOk={() => setPaymentFailedModalOpen(false)}
            onCancel={() => setPaymentFailedModalOpen(false)}
            footer={""}
            className='payment_failed_modal_wrapper'
        >
            <div className='card_content'>
                <div className='circular_image_wrapper'>
                    <img src={require("../../assets/images/payment-failed.png")} className="img-fluid" alt="" loading="lazy"/>
                </div>
                <h5 className='payment_status_title'>
                    Payment Failed
                </h5>
                <p className='payment_status_discription'>
                    Your order has been rejected.
                    for more details, kindly check below details of transaction.
                </p>
                <div className='sidebar_wrapper'>
                    <div className="sidebar_widget">
                        <h5 className='mb-4 fs18 fw600 lh_1_5'>Bill Details</h5>
                        <div className="billtotal_table_wrapper">
                            <table className="table mb-0">
                                <tbody>
                                    <tr>
                                        <th>Subtotal</th>
                                        <td>£5.5</td>
                                    </tr>
                                    <tr>
                                        <th>Taxes</th>
                                        <td>£1.7</td>
                                    </tr>
                                    <tr>
                                        <th>Delivery Charges</th>
                                        <td>£0</td>
                                    </tr>
                                </tbody>
                                <tfoot>
                                    <tr>
                                        <th>TOTAL</th>
                                        <td>£7.2</td>
                                    </tr>
                                </tfoot>
                            </table>
                        </div>
                    </div>
                </div>
                <button className='button button-2 w-100 fs18 fw600' onClick={() => setPaymentFailedModalOpen(false)}>
                    Retry
                </button>
            </div>
        </Modal>
    )
}

export default PaymentFailedModal