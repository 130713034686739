import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { Add, Heart, Location, Minus } from 'iconsax-react';
import Slider from "react-slick";
import apiData from '../../../common/apiData/ProductData';
import FeedbackAskModal from '../../../modals/FeedbackAskModal';
import ThanksForFeedbackModal from '../../../modals/ThanksForFeedbackModal';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import orderservice from '../../../services/orders';
const BaseUrl = process.env.REACT_APP_PROFILE_URL;

const CharityDonationDetail = (props) => {
    const routeParams = useParams();
    const { state } = useLocation();
    const dispatch = useDispatch();
    const [orderData, setOrderData] = useState();
    const [giveFeedBackModalOpen, setGiveFeedBackModalOpen] = useState(false);
    const [thanksFeedBackModalOpen, setThanksFeedBackModalOpen] = useState(false);

    const orderDetails = () => {
        dispatch(orderservice.getOrderDetail(state?.data))
            .then((res) => {
                if (res.data) {
                    setOrderData(res.data)
                }
            })
            .catch((errors) => {
                console.log({ errors })
            })
    }

    useEffect(() => {
        orderDetails();
    }, [])
    return (
        <>
            <section className='section order_details_page gap50'>
                <div className="container">
                    <div className="row">
                        {/* <div className="col-xl-8 col-lg-7">
                            <div className="menu_items_card pe-xl-5 pe-lg-4">
                                <div className="section_title mb30">
                                    <h2 className="fs24">Your Tickets</h2>
                                </div>
                                <div className="menu_items_list past_order_list custom-scrollbar">
                                    {state?.tickets.map((item, i) => {
                                        return (
                                            <li>
                                                <div className="product_list_item flex_item_cb gap-3">
                                                    <div className="product_image flex-shrink-0">
                                                        <label className="text_c818181 fs16 lh-1 fw400 mb-2">Ticket Number</label>
                                                        <h6 className="fs16 fw600 lh_1_5 mb-3">{item.ticket_number}</h6>
                                                        <Link ><img src={BaseUrl + 'tickets/' + item.image} className="img-fluid" alt="" height='80px' width="80px" /></Link>
                                                    </div>
                                                </div>
                                            </li>
                                        )
                                    })}
                                </div>
                            </div>
                        </div> */}
                        <div className="col-xl-4 col-lg-5">
                            <div className="cart_sidebar sidebar_wrapper donation_details_sidebar">
                                <div className="sidebar_widget">
                                    <h5 className='mb-4 fs18 fw600 lh_1_5'>{orderData?.charity_data.title}</h5>
                                    <div className='img_100px_wrapper'>
                                        <img src={BaseUrl + 'ngoItems/' + orderData?.charity_data.image} className="img-fluid" alt="" loading="lazy"/>
                                    </div>
                                    {/* <div className="flex_item_ss gap-2">
                                    </div> */}
                                </div>
                                <div className="sidebar_widget">
                                    <h5 className='mb-4 fs18 fw600 lh_1_5'>Donation Details</h5>
                                    <label className="text_c818181 fs16 lh-1 fw400 mb-2">Donation Id</label>
                                    <h6 className="fs16 fw600 lh_1_5 mb-3">{orderData?.donation_id}</h6>
                                    <label className="text_c818181 fs16 lh-1 fw400 mb-2">Payment Type</label>
                                    <h6 className="fs16 fw600 lh_1_5 mb-3">{orderData?.payment_type}</h6>
                                    <label className="text_c818181 fs16 lh-1 fw400 mb-2">Date</label>
                                    <h6 className="fs16 fw600 lh_1_5 mb-3">{moment(orderData?.createdAt).format('Do MMM YYYY')}</h6>
                                    {/* <label className="text_c818181 fs16 lh-1 fw400 mb-2">People</label>
                                    <h6 className="fs16 fw600 lh_1_5 mb-3">{orderData?.no_of_people}</h6> */}
                                </div>
                                <div className="sidebar_widget">
                                    <h5 className='mb-4 fs18 fw600 lh_1_5'>Bill Details</h5>
                                    <div className="billtotal_table_wrapper">
                                        <table className="table mb30">
                                            <tbody>
                                                <tr>
                                                    <th>Amount</th>
                                                    <td>£{orderData?.amount}</td>
                                                </tr>

                                            </tbody>
                                            <tfoot>
                                                <tr>
                                                    <th>TOTAL</th>
                                                    <td>£{orderData?.amount}</td>
                                                </tr>
                                            </tfoot>
                                        </table>
                                        {/* <button className="button button-2 w-100">Reorder</button> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </section>
        </>
    )
}

export default CharityDonationDetail;