import React from 'react';
import { Route, Redirect, Navigate, Outlet, useLocation } from 'react-router';


const AuthVerifyRoute = ({ isAuthenticated, isVerify }) => {
    const location = useLocation();
    return isAuthenticated && isVerify ?
        <Outlet />
        :
        <Navigate to={'/login'} state={{ from: location }} />

};


export default AuthVerifyRoute;