import { Collapse } from 'antd'
import React, { useEffect, useState } from 'react';
import Slider from 'react-slick';
import { Link, useNavigate } from 'react-router-dom';
import eventService from '../../services/events';
import { connect, useDispatch } from 'react-redux';
import moment from 'moment';
import { Button, Empty, Input } from 'antd';
const BaseUrl = process.env.REACT_APP_PROFILE_URL;
const { Panel } = Collapse

const Faq = () => {
    
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [Faq, setFaq] = useState([]);
    const [flag, setFlag] = useState();
  
  
    function getWidthCont(params) {
        let containerOutWidth = document.getElementsByClassName('container');
        containerOutWidth = containerOutWidth[0].clientWidth
    
        let mainWidth = window.outerWidth
        mainWidth = ((mainWidth - containerOutWidth) / 2)
    
        let allSlider = document.querySelectorAll('.events_details_cards_wrapper .slick-track');
        allSlider.forEach(element => {
          element.style.paddingRight = `${mainWidth}px`
        });
      }
    
      useEffect(() => {
        getWidthCont()
        window.addEventListener('resize', (e) => {
          getWidthCont()
        })
      }, [])
    
      const getFaq = () => {
        dispatch(eventService.getFaq())
          .then((res) => {
            console.log(res.data)
            setFaq(res.data)
          })
          .catch((errors) => {
            console.log({ errors })
          })
      }
      useEffect(() => {
        getFaq()
      }, [])
    return (
        <section className='faq_section gap60'>
            <div className="container">
                <div className="faq_section_warpper">
                    <div className="row justify-content-center">
                        <div className="col-md-10 col-xl-6 col-xxl-5 col-lg-8">
                            <div className="faq_content_wrapper">
                                <h3 className='fs28 fw600 text_dark mb-4'>Frequently Asked Questions</h3>
                                <div className="questions_wrapper">
                                    <Collapse bordered={false} defaultActiveKey={[0]} accordion expandIcon={({ isActive }) => isActive ? <img src={require("../../../assets/images/icons/plus_45deg.svg").default} alt='' loading="lazy"/> : <img src={require("../../../assets/images/icons/plus.svg").default} alt='' loading="lazy"/>}>
                                        {Faq && Faq.length>0 ? 
                                             
                                             Faq.map((faq,i)=>{
                                                 return <Panel header={faq?.question} key={i}>
                                                 <p> {faq?.answer}</p>
                                              </Panel>
                                             })

                                             :
                                             [...Array(7)].map((i)=>{
                                                 return <Panel header="Where can I watch?" key={i}>
                                                 <p> Nibh quisque suscipit fermentum netus nulla cras porttitor euismod nulla. Orci, dictumst nec aliquet id ullamcorper venenatis.</p>
                                              </Panel>
                                             })
                                        }
                                    </Collapse>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Faq