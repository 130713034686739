import React from 'react';
import { Form, Input } from 'antd';
import { useDispatch } from 'react-redux';
import AuthService from '../../services/auth';
import ToastMe from '../../common/ToastMe';
import TextArea from 'antd/es/input/TextArea';

const ContactUs = () => {
    const dispatch = useDispatch();
    const [form] = Form.useForm();

    const onFinish = (data) => {
        dispatch(AuthService.ContactUs(data))
            .then((res) => {
                ToastMe(res.data.message, 'success')
                document.getElementById("contact_form").reset();
            })
            .catch((errors) => {
                console.log({ errors })
            })
    }
    return (
        <>
            <section className="section contact_us gap">
                <div className="container">
                    <div className="row justify-content-between">
                        <div className="col-xl-6 col-lg-6">
                            <div className="text_content">
                                {/* <h2 className="mb-3 fs24 fw600 text_dark lh_1_3">Contact Us</h2> */}
                                <h2 className="mb-3 fs24 fw600 text_dark lh_1_3">Have questions, feedback, or need assistance? We're here to help</h2>
                                {/* <p className="fs16 fw400 lh_1_5 mb40">Please contact us using the form below if you would like further information on how to sign up, partner with us, place orders or ask any questions that you may have and we’ll get back to you shortly.</p> */}
                                <h5 className='fs18 fw600 text_dark mb-1 lh_1_3'>Customer Support:</h5>
                                <p className="fs16 fw400 lh_1_5 mb40">For any inquiries or assistance regarding your food delivery orders, please reach out to our dedicated customer support team. They are available to assist you with any concerns or questions you may have.</p>
                                <h5 className='fs18 fw600 text_dark mb-1 lh_1_3'>General Inquiries:</h5>
                                <p className="fs16 fw400 lh_1_5 mb40">For general inquiries about our food delivery app, partnerships, or other related matters, please contact us using the information provided below. We value your feedback and are committed to providing you with the best service possible.</p>
                                <div className="contact_info_parts">
                                    <div className="info_part flex_item_cs gap-3 mb-4">
                                        <div className="icon_wrapper flex-shrink-0">
                                            <img src={require("../../../assets/images/icons/icn_email_theme.svg").default} alt="" loading="lazy"/>
                                        </div>
                                        <div className="text_content flex-grow-1">
                                            <h5 className='fs18 fw600 text_dark mb-1 lh_1_3'>Email Address</h5>
                                            <p className='fs14 fw500 text_dark mb-0'>support@binoxbargains.com</p>
                                        </div>
                                    </div>
                                    <div className="info_part flex_item_cs gap-3 mb-4">
                                        <div className="icon_wrapper flex-shrink-0">
                                            <img src={require("../../../assets/images/icons/icn_call_theme.svg").default} alt="" loading="lazy"/>
                                        </div>
                                        <div className="text_content flex-grow-1">
                                            <h5 className='fs18 fw600 text_dark mb-1 lh_1_3'>Phone Number</h5>
                                            <p className='fs14 fw500 text_dark mb-0'>02031372886</p>
                                        </div>
                                    </div>
                                    <div className="info_part flex_item_cs gap-3 mb-4">
                                        <div className="icon_wrapper flex-shrink-0">
                                            <img src={require("../../../assets/images/icons/icn_location_theme.svg").default} alt="" loading="lazy"/>
                                        </div>
                                        <div className="text_content flex-grow-1">
                                            <h5 className='fs18 fw600 text_dark mb-1 lh_1_3'>Location</h5>
                                            <p className='fs14 fw500 text_dark mb-0'>85 Great Portland Street, London W1W 7LT</p>
                                        </div>
                                    </div>
                                </div>
                                <p className="fs16 fw400 lh_1_5 mb40">We strive to respond promptly to all inquiries and look forward to hearing from you. Your satisfaction is our top priority!
                                    Please note that our customer support team is available during our business hours, Monday to Friday, 9:00 AM to 9:00 PM.</p>
                            </div>
                        </div>
                        <div className="col-xl-5 col-lg-5">
                            <Form name="basic"
                                id="contact_form"
                                form={form}
                                initialValues={{ remember: true }}
                                onFinish={onFinish}
                                autoComplete="off"
                            >
                                <div className='row'>
                                    <div className='col-12'>
                                        <div className="form-group mb-3">
                                            <label htmlFor="" className="fs16 fw500 lh-1 mb-2">Name</label>
                                            <Form.Item
                                                name="name"
                                                rules={[{ required: true, message: 'Please enter your name' },
                                                { max: 30, message: 'You can not enter more than 30 characters' },
                                                { pattern: new RegExp("[a-zA-Z]+$"), message: 'Please enter only characters' }]}
                                            >
                                                <Input className='input-control'
                                                    value={''}
                                                />
                                            </Form.Item>
                                        </div>
                                    </div>
                                    <div className='col-12'>
                                        <div className="form-group mb-3">
                                            <label htmlFor="" className="fs16 fw500 lh-1 mb-2">Email</label>
                                            <Form.Item
                                                name="email"
                                                rules={[{ required: true, message: 'Please enter your email address!' },
                                                { pattern: new RegExp(/^([A-Z0-9a-z._%+-])+\@([A-Za-z0-9.-])+(\.[A-Za-z]{2,4})+$/), message: 'Please enter valid email address!' }]}
                                            >
                                                <Input className='input-control' />
                                            </Form.Item>
                                        </div>
                                    </div>
                                    <div className='col-12'>
                                        <div className="form-group mb-3">
                                            <label htmlFor="" className="fs16 fw500 lh-1 mb-2">Subject</label>
                                            <Form.Item
                                                name="subject"
                                                rules={[{ required: true, message: 'Please enter your subject' }]}
                                            >
                                                <Input className='input-control' />
                                            </Form.Item>
                                        </div>
                                    </div>
                                    <div className='col-12'>
                                        <div className="form-group mb40">
                                            <label htmlFor="" className="fs16 fw500 lh-1 mb-2">Message</label>
                                            <Form.Item
                                                name="description"
                                                rules={[{ required: true, message: 'Please enter your message' }]}
                                            >
                                                <TextArea rows={4} className='input-control' />
                                            </Form.Item>
                                        </div>
                                    </div>
                                    <div className='col-12'>
                                        <button className="button button-2 w-100 fs18 fw600 w-100">Submit</button>
                                    </div>
                                </div>
                            </Form>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default ContactUs;