import React, { useState, useEffect, useRef } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Add, Card, CloseCircle, Heart, Location, LogoutCurve, Minus, Notification, PasswordCheck, SearchNormal1, ShoppingCart, User } from 'iconsax-react';
import { Badge, Button, Drawer, Dropdown, Input, Select, message, Space } from 'antd';
import { Autocomplete, useJsApiLoader } from "@react-google-maps/api";
import { connect, useDispatch } from 'react-redux';
import * as actions from '../../store/actions';
import restaurantService from '../../services/restruants';
import eventService from '../../services/events';
import ToastMe from '../../common/ToastMe';
import AuthService from '../../services/auth';
import { HashLink } from 'react-router-hash-link';
import { DownOutlined } from '@ant-design/icons';
const BaseUrl = process.env.REACT_APP_PROFILE_URL;

const OrderHeader = (props) => {

    const dispatch = useDispatch();
    const location = useLocation()
    const navigate = useNavigate();
    const { isLoaded } = useJsApiLoader({ id: 'google-map-script', googleMapsApiKey: process.env.REACT_APP_GOOGLE_KEY, libraries: ["drawing", "places"] })
    const cartItems = props?.cartItems;
    const notificationDatas = props?.notificationData;
    const notificationTotalData = props?.notificationTotalData;
    const billDetail = props?.billDetail;
    const [isAuthenticated, setIsAuthenticated] = useState(props.isAuthenticated);
    const [notificationData, setNotificationData] = useState([]);
    const [autoComplete, setAutoComplete] = useState();
    const [locationLat, setLocationLat] = useState();
    const [locationLng, setLocationLng] = useState();
    const [search, setSearch] = useState();
    const [filterDatas, setfilterData] = useState();
    const [locationNameValue, setLocationNameValue] = useState();
    const [searchAddress, setSearchAddress] = useState();
    const locationName = useRef(null);
    const [cartItem, setCartItem] = useState();
    const [deleteId, setDeleteId] = useState();
    const [cartDrawerOpen, setCartDrawerOpen] = useState(false);
    const [openDrawer, setopenDrawer] = useState(false);
    const [unReadData, setUnReadData] = useState([]);
    const [showRestaurantCollape, setShowRestaurantCollape] = useState(false);
    const [showLocationCollape, setShowLocationCollape] = useState(false);

    useEffect(() => {
        if (props.isAuthenticated == false) {
            setIsAuthenticated(false)
        } else {
            setIsAuthenticated(true)
        }
    }, [props])

    const toggleLocationFilter = () => {
        setShowRestaurantCollape(false)
        setShowLocationCollape(!showLocationCollape)
    }

    const toggleRestaurantFilter = () => {
        setShowLocationCollape(false)
        setShowRestaurantCollape(!showRestaurantCollape)
    }

    const cartDrawerOpenEvent = () => {
        setCartDrawerOpen(true);
    };

    const cartDrawerCloseEvent = () => {
        setCartDrawerOpen(false);
    };

    const [notificationDrawerOpen, setNotificationDrawerOpen] = useState(false);
    const notificationDrawerOpenEvent = () => {
        setNotificationDrawerOpen(true);
    };

    const notificationDrawerCloseEvent = () => {
        setNotificationDrawerOpen(false);
    };
    
    useEffect(() => {
        setCartDrawerOpen(false)
        setNotificationDrawerOpen(false);
        setShowRestaurantCollape(false);
        setShowLocationCollape(false)
    }, [location])

    const getCart = (flagHomePickup = '') => {
        dispatch(restaurantService.getCart(flagHomePickup))
            .then((res) => {
                setCartItem(res.data.cart_data)
            })
            .catch((errors) => {
                console.log({ errors })
            })
    }

    useEffect(() => {
        getCart();
    }, [])

    const handleLogout = async () => {
        dispatch(actions.authLogout());
        navigate("/")
    }

    const orderDetail = (data) => {
        let datas = {};
        if ((data.notification_type == '1') || (data.notification_type == '2')) {
            datas = {
                order_id: data.order_id,
                type: data.notification_type
            }
            navigate('/order-details', { state: { data: datas } })
        } else if (data.notification_type == '3') {
            datas = {
                order_id: data.order_id,
                type: data.notification_type
            }
            navigate('/event-order-details', { state: { data: datas } })
        } else if (data.notification_type == '5') {
            datas = {
                order_id: data.order_id,
                type: data.notification_type
            }
            navigate('/donation-details', { state: { data: datas } })
        }
    }


    const onAutoCompleteIsLoad = (inputAutoComplete) => {
        setAutoComplete(inputAutoComplete);
    };

    const onAutoCompletePlaceIsChanged = () => {
        // console.log("auto complete is changed: ", autoComplete.getPlace());
        if (autoComplete !== null) {
            var place = autoComplete.getPlace();
            setSearchAddress(place.formatted_address);
            setLocationLat(place.geometry.location.lat());
            setLocationLng(place.geometry.location.lng());
        }
    };

    const filterData = (e) => {
        if (e) {
            setSearch(e.target.value)
        }
        let data = {
            latitude: locationLat,
            longitude: locationLng,
            search: search,
        }
        dispatch(eventService.filterData(data))
            .then((res) => {
                setfilterData(res)
                // navigate("/orders", { state: { filterData: res.data, searchData: data } })
            })
            .catch((errors) => {
                // console.log({ errors })
            })
    }

    useEffect(() => {
        if (search) {
            if (filterDatas !== undefined) {
                dispatch(actions.setSearchData(filterDatas))
            }
        } else {
            dispatch(actions.setSearchData(''))
        }
        if (locationLat) {
            // dispatch(actions.changeLatitude(locationLat))
            // dispatch(actions.changeLongitude(locationLng))
            dispatch(actions.setSearchData(filterDatas))
        }
    }, [search, filterDatas])

    useEffect(() => {
        filterData();
    }, [locationLat, locationLng])

    const getNotifications = () => {
        dispatch(AuthService.getNotifications())
            .then((res) => {
                setNotificationData(res.data);
                setUnReadData(res.data.filter((data) => {
                    if (data.isRead == 0) {
                        return data;
                    }
                }))
            })
            .catch((errors) => {
                console.log({ errors })
            })
    }

    const notificationRead = (id) => {
        dispatch(AuthService.notificationRead(id))
            .then((res) => {
                getNotifications();
                // setNotificationData(res.data.slice(0,6));
            })
            .catch((errors) => {
                console.log({ errors })
            })
    }
    useEffect(() => {
        getNotifications()
    }, [])

    const onTodoChange = (e) => {
        if (e.target.value == '') {
            dispatch(actions.setSearchData(''))
        }
        if (e.target.name === 'street_address') {
            setLocationNameValue(1);
            setSearchAddress(e.target.value)
        }
    }

    const handlePlus = (item, type) => {
        if (item.quantity == 1 && type === 'minus') {
            setDeleteId(item._id);
        } else {
            dispatch(restaurantService.addItemQuantity(item._id, type))
                .then((res) => {
                    getCart();
                })
                .catch((errors) => {
                    console.log({ errors })
                })
        }
    }

    const deleteItemFromCart = (id) => {
        dispatch(restaurantService.deleteItemCart(id))
            .then((res) => {
                if (res.data) {
                    ToastMe(res.data.message, 'success')
                    getCart();
                }
            })
            .catch((errors) => {
                console.log({ errors })
            })
    }

    const items = [
        {
            key: '1',
            label: (
                <Link to="/login" rel="noopener noreferrer" className='button button-2 w-100 fs14' style={{ padding: "10px 16px" }}>
                    Customer login / sign up
                </Link>
            ),
        },
        {
            key: '2',
            label: (
                <Link target="_blank" rel="noopener noreferrer" className='button button-2 w-100 fs14' style={{ padding: "10px 16px", backgroundImage: 'unset' }} to={"https://vendors.binoxbargains.com/login"}>
                    Partner login / sign up
                </Link>
            ),
        },
    ];

    return (
        <>
            <header className='order_header_wrapper'>
                <div className="container">
                    <div className="header_main">
                        <div className="header_flex_main">
                            <div className="logo_part flex-shrink-0">
                                <div className="header-style">
                                    <Link to={'/'}>
                                        <img className="logo img-fluid d-md-block d-none" src={require('../../../assets/img/logo_dark.png')} alt="" />
                                        <img className="logo img-fluid d-md-none" width={48} src={require('../../../assets/img/logo-icon.svg').default} alt="" />
                                    </Link>
                                </div>
                            </div>
                            <div className="menu_part_wrapper">
                                <div className={`location_search_filter ${showLocationCollape ? "d-block" : ""}`}>
                                    <div className="search_filter_box max_400">
                                        <div className="search_location_filter flex-shrink-0">
                                            <div className="search_box">
                                                {isLoaded ? <Autocomplete ref={locationName} onLoad={onAutoCompleteIsLoad} onPlaceChanged={onAutoCompletePlaceIsChanged}>
                                                    <div className='location_input'>
                                                        <Input
                                                            prefix={<Location size="24" color='#FFBD05' />}
                                                            name='street_address'
                                                            className=""
                                                            placeholder="Search Address"
                                                            value={locationNameValue ? searchAddress : ""}
                                                            onChange={e => onTodoChange(e)}
                                                        />
                                                    </div>
                                                </Autocomplete> : <div>Loading....</div>}
                                            </div>
                                        </div>
                                        <span className='responsive_close_icon d-block d-xl-none' onClick={() => setShowLocationCollape(false)}>
                                            <CloseCircle
                                                size="18"
                                                color="#ffbd05"
                                            />
                                        </span>
                                    </div>
                                </div>
                                {/* <div className={`restaurants_search_filter ${showRestaurantCollape ? "d-block" : ""} `}>
                                    <div className="search_filter_box">
                                        <div className="search_restrorent_filter flex-grow-1">
                                            <div className="search_box">
                                                <div className='location_input'>
                                                    <Input
                                                        prefix={<img src={require("../../../assets/img/icn_search.svg").default} alt="" />}
                                                        name=''
                                                        className=""
                                                        placeholder="Search...."
                                                        onChange={(e) => filterData(e)}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <span className='responsive_close_icon  d-block' onClick={() => setShowRestaurantCollape(false)}>
                                            <CloseCircle
                                                size="18"
                                                color="#ffbd05"
                                            />
                                        </span>
                                    </div>
                                </div> */}
                            </div>
                            <div className="action_right_part  flex-shrink-0">
                                <ul className='extras bag flex_item_ce gap20'>
                                    <li className={`d-block d-xl-none`}>
                                        <a role="button" title='You can search for a restaurant or grocery shop near you' className="fs16 fw500 text-dark me-3 flex nav-link flex_item_cs gap-2" onClick={toggleLocationFilter}>
                                            <img src={require("../../../assets/img/search_location.png")} alt="" loading="lazy" className='nav_icon' />
                                        </a>
                                    </li>
                                    <li className={`d-none d-xl-block ${location.pathname === "/become-partner" ? "active" : ""}`}>
                                        <Link to={"/become-partner"} className="fs16 fw500 text-dark me-3 flex nav-link flex_item_cs gap-2">
                                            <img src={require("../../../assets/img/icn_partnership.svg").default} loading="lazy" alt="" className='nav_icon' />Partnership
                                        </Link>
                                    </li>
                                    <li className={`d-none d-xl-block ${location.pathname === "/about-us" ? "active" : ""}`}>
                                        <Link to={"/about-us"} className="fs16 fw500 text-dark me-3 nav-link flex_item_cs gap-2">
                                            <img src={require("../../../assets/img/binox_favicon.svg").default} loading="lazy" alt="" className='nav_icon' /> About
                                        </Link>
                                    </li>
                                    <li className={`d-none d-xl-block ${location.pathname === "/download-app" ? "active" : ""}`}>
                                        <Link to={"/download-app"} className="fs16 fw500 text-dark me-3 nav-link flex_item_cs gap-2">
                                         <img src={require("../../../assets/img/download.svg").default} loading="lazy" alt="" className='nav_icon' />
                                            Download App
                                        </Link>
                                    </li>
                                    {/* <li className={`d-none d-xl-block ${location.pathname === "/contact" ? "active" : ""}`}>
                                        <Link to={"/contact"} className="fs16 fw500 text-dark me-3 nav-link">
                                            <img src={require("../../../assets/images/icons/")} alt="" />   Get Support
                                        </Link>
                                    </li> */}
                                    <li className='d-block d-xl-none'>
                                        <button className="bar-menu" onClick={() => setopenDrawer(!openDrawer)}>
                                            <i className="fa-solid fa-bars m-0" />
                                        </button>
                                    </li>
                                    {!isAuthenticated
                                        ?
                                        ''
                                        :
                                        <>
                                            <li>
                                                <Badge count={cartItems?.length}>
                                                    <a title='Cart' role="button" onClick={cartDrawerOpenEvent}>
                                                        <ShoppingCart size="24" color="#333230" />
                                                    </a>
                                                </Badge>
                                            </li>
                                            <li>
                                                <Badge count={notificationDatas ? notificationDatas.length : unReadData.length}>
                                                    <a title='Notification' role="button" onClick={notificationDrawerOpenEvent}>
                                                        <Notification size="24" color="#333230" />
                                                    </a>
                                                </Badge>
                                            </li>
                                        </>
                                    }
                                    {
                                        !isAuthenticated ? <li>
                                            <Dropdown menu={{ items }} placement="bottomRight" trigger={['click']} arrow rootClassName='login_dropdown' className='login_dropdown'>
                                                <a onClick={(e) => e.preventDefault()}>
                                                    <Space className='fs-16 fw-600 text-dark'>
                                                        <img src={require("../../../assets/img/user.svg").default} alt="" loading="lazy"/>
                                                         Login
                                                        <DownOutlined />
                                                    </Space>
                                                </a>
                                            </Dropdown>
                                        </li> : <li className="login_user_info">
                                            <Dropdown
                                                overlayClassName="login_user_dropdown_menu"
                                                menu={{
                                                    items: [
                                                        {
                                                            key: '1',
                                                            label: (
                                                                <Link to={"/profile"}>My Profile</Link>
                                                            ),
                                                            icon: <User size={20} color="#818181" variant="Bold" />
                                                        },
                                                        {
                                                            key: '8',
                                                            label: (
                                                                <Link to={"/change-password"}>Change Password</Link>
                                                            ),
                                                            icon: <PasswordCheck size="20" color="#818181" variant="Bold" />
                                                        },
                                                        {
                                                            key: '2',
                                                            label: (
                                                                <Link to={"/order-history"}>Order History</Link>
                                                            ),
                                                            icon: <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <g clip-path="url(#clip0_3265_15019)">
                                                                    <path d="M16.0547 0.343262V3.90646H19.6176L16.0547 0.343262Z" fill="#818181" />
                                                                    <path d="M15.4688 5.07812C15.1452 5.07812 14.8828 4.81578 14.8828 4.49219V0H6.48438C5.51512 0 4.72656 0.788555 4.72656 1.75781V8.30816C4.91961 8.29066 5.11496 8.28125 5.3125 8.28125C7.30969 8.28125 9.09754 9.19438 10.2807 10.625H16.6406C16.9642 10.625 17.2266 10.8873 17.2266 11.2109C17.2266 11.5345 16.9642 11.7969 16.6406 11.7969H11.0527C11.4189 12.5116 11.6551 13.3033 11.7309 14.1406H16.6406C16.9642 14.1406 17.2266 14.403 17.2266 14.7266C17.2266 15.0502 16.9642 15.3125 16.6406 15.3125H11.7309C11.5557 17.2476 10.5218 18.9385 9.01398 20H18.2031C19.1724 20 19.9609 19.2114 19.9609 18.2422V5.07812H15.4688ZM16.6406 8.28125H8.04688C7.72328 8.28125 7.46094 8.01891 7.46094 7.69531C7.46094 7.37172 7.72328 7.10938 8.04688 7.10938H16.6406C16.9642 7.10938 17.2266 7.37172 17.2266 7.69531C17.2266 8.01891 16.9642 8.28125 16.6406 8.28125Z" fill="#818181" />
                                                                    <path d="M5.3125 9.45312C2.40473 9.45312 0.0390625 11.8188 0.0390625 14.7266C0.0390625 17.6343 2.40473 20 5.3125 20C8.22027 20 10.5859 17.6343 10.5859 14.7266C10.5859 11.8188 8.22027 9.45312 5.3125 9.45312ZM6.875 15.3125H5.3125C4.98891 15.3125 4.72656 15.0502 4.72656 14.7266V12.3828C4.72656 12.0592 4.98891 11.7969 5.3125 11.7969C5.63609 11.7969 5.89844 12.0592 5.89844 12.3828V14.1406H6.875C7.19859 14.1406 7.46094 14.403 7.46094 14.7266C7.46094 15.0502 7.19859 15.3125 6.875 15.3125Z" fill="#818181" />
                                                                </g>
                                                                <defs>
                                                                    <clipPath id="clip0_3265_15019">
                                                                        <rect width="20" height="20" fill="white" />
                                                                    </clipPath>
                                                                </defs>
                                                            </svg>
                                                        },
                                                        {
                                                            key: '3',
                                                            label: (
                                                                <Link to={"/wishlist"}>Wishilst</Link>
                                                            ),
                                                            icon: <Heart size={20} color="#818181" variant="Bold" />
                                                        },
                                                        {
                                                            key: '4',
                                                            label: (
                                                                <Link to={"/manage-address"}>Saved Address</Link>
                                                            ),
                                                            icon: <Location size={20} color="#818181" variant="Bold" />
                                                        },
                                                        {
                                                            key: '5',
                                                            label: (
                                                                <Link to={"/manage-payments-method"}>Payment Method</Link>
                                                            ),
                                                            icon: <Card size={20} color="#818181" variant="Bold" />
                                                        },
                                                        {
                                                            key: '6',
                                                            label: (
                                                                <Link to={"/contact"} >Contact Us</Link>
                                                            ),
                                                            icon: <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M15.6449 12.9238C14.8528 14.0116 13.5698 14.7201 12.1243 14.7201H10.0959C9.39525 14.7201 8.79856 14.2753 8.57261 13.6526C8.29371 13.5695 8.02021 13.4615 7.75332 13.3288C7.18813 13.048 6.67494 12.6676 6.22318 12.1963C3.2928 12.8401 1.09961 15.4512 1.09961 18.5749V18.9832C1.09961 19.5445 1.55463 19.9995 2.11591 19.9995H17.8839C18.4452 19.9995 18.9002 19.5445 18.9002 18.9832V18.5749C18.9002 16.1618 17.5913 14.0545 15.6449 12.9238Z" fill="#818181" />
                                                                <path d="M5.14355 10.102C5.58035 10.102 5.96054 9.8599 6.1575 9.50264C6.16268 9.51678 6.16795 9.53084 6.17325 9.54489C6.17482 9.54911 6.17639 9.55333 6.17795 9.55755C6.64003 10.7738 7.53911 11.7934 8.72466 12.2389C9.01139 11.7833 9.51841 11.4803 10.0965 11.4803H12.1249C12.3272 11.4803 12.5155 11.4232 12.6806 11.331C12.9578 11.1759 13.2071 10.7827 13.3359 10.5742C13.5419 10.2406 13.7015 9.88727 13.8431 9.50203C13.9226 9.64648 14.032 9.77214 14.163 9.8706V10.368C14.163 11.4918 13.2487 12.4062 12.1248 12.4062H10.0965C9.71303 12.4062 9.40215 12.717 9.40215 13.1005C9.40215 13.484 9.71303 13.7948 10.0965 13.7948H12.1248C14.0144 13.7948 15.5517 12.2575 15.5517 10.368V9.8706C15.8327 9.65945 16.0146 9.32338 16.0146 8.9448V6.85116V6.04832C16.0146 5.66196 15.8251 5.3201 15.5341 5.10991C15.3085 2.2545 12.9129 0 10.0005 0C7.088 0 4.6924 2.2545 4.46676 5.10986C4.17581 5.32006 3.98633 5.66196 3.98633 6.04828V8.94472C3.98633 9.58566 4.50701 10.102 5.14355 10.102ZM10.0005 1.3887C12.1564 1.3887 13.9347 3.03632 14.1426 5.13858C14.0061 5.24592 13.8943 5.3831 13.8173 5.54052C13.1622 3.8312 11.6996 2.64112 10.0004 2.64112C8.26455 2.64112 6.82546 3.8721 6.18605 5.53369C6.18518 5.53595 6.18435 5.53826 6.18348 5.54056C6.10647 5.38315 5.99469 5.24596 5.85824 5.13862C6.06622 3.03632 7.84446 1.3887 10.0005 1.3887Z" fill="#818181" />
                                                            </svg>
                                                        },
                                                        {
                                                            key: '7',
                                                            label: (
                                                                <a onClick={() => handleLogout()} >Logout</a>
                                                            ),
                                                            icon: <LogoutCurve size={20} color="#818181" variant="Bold" />
                                                        },
                                                    ]
                                                }}
                                            >
                                                <a onClick={(e) => e.preventDefault()} className="flex_item_ce gap-2">
                                                    {props.userData?.userData?.avatar != null ?
                                                        <span className="user_image"><img src={(BaseUrl + 'user/' + props?.userData?.userData?.avatar)} className="img-fluid" alt="" loading="lazy"/></span>
                                                        :
                                                        <span className="user_image"><img src={require("../../../assets/images/default_img.png")} className="img-fluid" alt="profile_image" loading="lazy"/></span>
                                                    }
                                                    <div className="user_text flex_item_cc gap-2">
                                                        <span className="flex-grow-1">{props.userData?.userData?.fullName}</span>
                                                        <img src={require("../../../assets/images/icons/arrow_down.svg").default} className="img-fluid flex-shrink-0" alt="" loading="lazy"/>
                                                    </div>
                                                </a>
                                            </Dropdown>
                                        </li>
                                    }
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="row align-items-center">
                        <div className="menu-wrap">
                            <div className="menu-inner ps ps--active-x ps--active-y">
                                <span className="menu-cls-btn"><i className="cls-leftright" /><i className="cls-rightleft" /></span>
                                <div className="checkout-order">
                                    <div className="title-checkout">
                                        <h2>My Orders</h2>
                                    </div>
                                    <div className="banner-wilmington">
                                        <img alt="logo" src="assets/img/logo-s.jpg" />
                                        <h6>Kennington Lane Cafe</h6>
                                    </div>
                                    <ul>
                                        <li className="price-list">
                                            <i className="closeButton fa-solid fa-xmark" />
                                            <div className="counter-container">
                                                <div className="counter-food">
                                                    <img alt="food" src="assets/img/order-1.png" />
                                                    <h4>Pasta, kiwi and sauce chilli</h4>
                                                </div>
                                                <h3>$39</h3>
                                            </div>
                                            <div className="price">
                                                <div>
                                                    <h2>$39</h2>
                                                    <span>Sum</span>
                                                </div>
                                                <div>
                                                    <div className="qty-input">
                                                        <button className="qty-count qty-count--minus" data-action="minus" type="button">-</button>
                                                        <input className="product-qty" type="number" name="product-qty" min={0} defaultValue={1} />
                                                        <button className="qty-count qty-count--add" data-action="add" type="button">+</button>
                                                    </div>
                                                    <span>Quantity</span>
                                                </div>
                                            </div>
                                        </li>
                                        <li className="price-list">
                                            <i className="closeButton fa-solid fa-xmark" />
                                            <div className="counter-container">
                                                <div className="counter-food">
                                                    <img alt="food" src="assets/img/order-2.png" />
                                                    <h4>Rice with shrimps and kiwi</h4>
                                                </div>
                                                <h3>$49</h3>
                                            </div>
                                            <div className="price">
                                                <div>
                                                    <h2>$49</h2>
                                                    <span>Sum</span>
                                                </div>
                                                <div>
                                                    <div className="qty-input">
                                                        <button className="qty-count qty-count--minus" data-action="minus" type="button">-</button>
                                                        <input className="product-qty" type="number" name="product-qty" min={0} defaultValue={1} />
                                                        <button className="qty-count qty-count--add" data-action="add" type="button">+</button>
                                                    </div>
                                                    <span>Quantity</span>
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                    <div className="totel-price">
                                        <span>Total order:</span>
                                        <h5>$137</h5>
                                    </div>
                                    <div className="totel-price">
                                        <span>To pay:</span>
                                        <h2>$137</h2>
                                    </div>
                                    <button className="button-price">Checkout</button>
                                </div>
                            </div>
                        </div>
                        <div className={`mobile-nav hmburger-menu ${openDrawer ? "open" : ""}`} id="mobile-nav" style={{ display: 'block' }}>
                            <div className="res-log">
                                <Link to="/" role={"button"}>
                                    <img className="logo img-fluid" src={require('../../../assets/images/Frame.png')} alt="" />
                                </Link>
                            </div>
                            <ul>
                                <li style={{ display: 'flex' }} onClick={() => setopenDrawer(false)}><HashLink style={{ width: '100%' }} smooth to="/" >Home</HashLink></li>
                                <li style={{ display: 'flex' }} onClick={() => setopenDrawer(false)}><HashLink style={{ width: '100%' }} smooth to="/about-us" >About Binox</HashLink></li>
                                <li style={{ display: 'flex' }} onClick={() => setopenDrawer(false)}><HashLink style={{ width: '100%' }} smooth to="/download-app" >Download App</HashLink></li>
                                <li style={{ display: 'flex' }} onClick={() => setopenDrawer(false)}><HashLink style={{ width: '100%' }} smooth to="/contact" >Get Support</HashLink></li>
                                {/* <li><a href="contact.html">contacts</a></li> */}
                                { /* <li className='d-sm-none d-block'>
                                    {
                                        location && location.pathname === '/become-partner' ?
                                            <Link to="https://vendors.binoxbargains.com/" className="button button-2"></Link> :
                                            <Link to="/orders" className="button button-2">Order Now</Link>
                                    }
                                 </li> */
                                }
                                {
                                    !isAuthenticated ?
                                        <li><Link to={"/login"} className="button button-2">Login</Link></li> :
                                        <li><Link to={""} className="button button-2">Logout</Link></li>
                                }
                            </ul>
                            <a role={"button"} href="JavaScript:void(0)" id="res-cross" onClick={() => setopenDrawer(false)} />
                        </div>
                    </div>
                </div>
            </header>
            <div className="header_exact_bottom d-none mt-3">
                <div className="container">
                    <div className="menu_part flex-grow-1">
                        <div className="search_filter_box">
                            <div className="filter_left flex-shrink-0">
                                <div className="search_box">
                                    {/* {isLoaded ? <Autocomplete ref={locationName} onLoad={onAutoCompleteIsLoad} onPlaceChanged={onAutoCompletePlaceIsChanged}>
                                        <div className='location_input'>
                                            <Input
                                                prefix={<Location size="24" color='#FFBD05' />}
                                                name='street_address'
                                                className=""
                                                placeholder="Search Address"
                                                value={locationNameValue ? searchAddress : ""}
                                                onChange={e => onTodoChange(e)}
                                            />
                                        </div>
                                    </Autocomplete> : <div>Loading....</div>} */}
                                </div>
                            </div>
                            <div className="filter_right flex-grow-1">
                                <div className="search_box">
                                    {/* <TextField
                                                sx={{ marginTop: "-24px" }}
                                                type="text"
                                                autoComplete="off"
                                                fullWidth
                                                variant="outlined"
                                                placeholder="Search to Select"
                                                onChange={(e) => filterData(e)}
                                            // optionFilterProp="children"
                                            // filterOption={(input, option) => (option?.label ?? '').includes(input)}
                                            // filterSort={(optionA, optionB) =>
                                            //     (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                                            // }

                                            /> */}
                                    <div className='location_input'>
                                        <Input
                                            prefix={<img src={require("../../../assets/img/icn_search.svg").default} alt="" />}
                                            name=''
                                            className=""
                                            placeholder="Search...."
                                            onChange={(e) => filterData(e)}
                                        />
                                        {/* <div className='location_google_icon'>
                                                                    <img src={l_googleicon} alt="" />
                                                 </div> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Drawer title="Your Cart" footer={
                <>
                    <div className="cart_drawer_total">
                        <div className="billtotal_table_wrapper">
                            <table className="table mb-4">
                                <tbody>
                                    <tr>
                                        <th>Subtotal</th>
                                        <td>£{billDetail ? billDetail?.subTotal?.toFixed(2) : 0}</td>
                                    </tr>
                                    <tr>
                                        <th>Taxes</th>
                                        <td>£{billDetail ? billDetail?.Taxes?.toFixed(2) : 0}</td>
                                    </tr>
                                    <tr>
                                        <th>Delivery Charges</th>
                                        <td>£{billDetail ? billDetail?.deliveryCharge?.toFixed(2) : 0}</td>
                                    </tr>
                                </tbody>
                                <tfoot>
                                    <tr>
                                        <th>TOTAL</th>
                                        <td>£{billDetail ? billDetail?.Total?.toFixed(2) : 0}</td>
                                    </tr>
                                </tfoot>
                            </table>
                        </div>
                    </div>
                    <div className="flex_item_cb gap-2">
                        <Link to={"/cart"} className="button button-2 w-100">Checkout</Link>
                    </div>
                </>
            } rootClassName="custom_drawer_menu" placement="right" onClose={cartDrawerCloseEvent} open={cartDrawerOpen}>
                <div className="cart_list_drawer">
                    <div className="menu_items_card">
                        <div className="menu_items_list">
                            <ul className="product_list_main">
                                {
                                    cartItems && cartItems?.map((item, i) => {
                                        var data;
                                        if (item.groceryitems_data) {
                                            data = item.groceryitems_data;
                                            data['type'] = 'groceryItem/';
                                        } else {
                                            data = item.fooditems_data;
                                            data['type'] = 'foodItem/';
                                        }
                                        return (
                                            <li key={i}>
                                                <div className="product_list_item flex_item_sb gap-3">
                                                    <div className="product_image flex-shrink-0">
                                                        <img src={process.env.REACT_APP_PROFILE_URL + data?.type + data?.image} className="img-fluid w-100" alt={item.name} loading="lazy"/>
                                                    </div>
                                                    <div className="product_text flex-grow-1">
                                                        <h4 className="flex_item_sb gap-2">{data.name} <a role={"button"} className="flex-shrnik-0"><CloseCircle onClick={() => deleteItemFromCart(item._id)} size={20} color="#818181" variant="Bold" /></a></h4>
                                                        <div className="flex_item_cb flex-grow-1">
                                                            <div className="left_part">
                                                                <p className='price'><del className=''>£{data.price?.toFixed(2)}</del><ins>£{data.offerPrice.toFixed(2)}</ins></p>
                                                            </div>
                                                            <div className="right_part">
                                                                <div className="qty_button">
                                                                    <a role={"button"} className='btn_qty btn_desc'><Minus size="24" color="#333230" onClick={() => handlePlus(item, 'minus')} /></a>
                                                                    <input type="number" value={item.quantity} className='qty_input' />
                                                                    <a role={"button"} className="btn_qty btn_incrice"><Add size="24" color="#333230" onClick={() => handlePlus(item, 'plus')} /></a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </li>
                                        )
                                    })
                                }
                            </ul>
                        </div>
                    </div>
                </div>
            </Drawer>
            <Drawer title="Notifications"
                // footer={
                //     <Link to={"/cart"} className="button button-2 w-100">View All</Link>
                // }
                rootClassName="custom_drawer_menu" placement="right" onClose={notificationDrawerCloseEvent} open={notificationDrawerOpen}>
                <div className="notification_lists">
                    {
                        notificationTotalData ?
                            notificationTotalData.map((data, index) => (
                                <div className="notification_item_card flex_item_ss " key={index}>
                                    <div className={`text flex-grow-1 ${data.isRead == 1 ? '' : 'unread'}`} onClick={() => notificationRead(data._id)}>
                                        <h6><a role="button" onClick={() => orderDetail(data)}>{data.title}</a></h6>
                                        <p className="text_3line" onClick={() => orderDetail(data)}>{data.description}</p>
                                    </div>
                                </div>
                            ))
                            :
                            notificationData.map((data, index) => (
                                <div className="notification_item_card flex_item_ss " key={index}>
                                    {/* <div className="icon flex_item_cc gap-0 flex-shrink-0">
                                    <User variant="Bold" size={24} />
                                </div> */}
                                    <div className={`text flex-grow-1 ${data.isRead == 1 ? '' : 'unread'}`} onClick={() => notificationRead(data._id)}>
                                        <h6><a role="button" onClick={() => orderDetail(data)}>{data.title}</a></h6>
                                        <p className="text_3line" onClick={() => orderDetail(data)}>{data.description}</p>
                                    </div>
                                </div>
                            ))
                    }
                </div>
            </Drawer>
        </>
    )
}

const mapStateToProps = state => {
    return {
        isAuthenticated: state.Auth.isAuthenticated,
        isVerify: state.Auth.isVerify,
        needToVerify: state.Auth.needToVerify,
        isAdministrator: state.Auth.isAdministrator,
        userData: state.Auth.userData,
        filter: state.filter,
        cartItems: state.Auth.cartItems,
        notificationData: state.Auth.notificationDatas,
        notificationTotalData: state.Auth.totalData,
        billDetail: state.Auth.billDetail
    }
};

export default connect(mapStateToProps)(OrderHeader);