import { Button, Modal } from 'antd';
import React from 'react'

const PaymentSuccessfulModal = (props) => {
    const { paymentSuccessModalOpen, setPaymentSuccessModalOpen, billDetails, eventDetail } = props;

    return (
        <Modal
            centered
            open={paymentSuccessModalOpen}
            onOk={() => setPaymentSuccessModalOpen(false)}
            onCancel={() => { props.onClickBtn(); setPaymentSuccessModalOpen(false)}}
            footer={""}
            className='payment_successful_modal_wrapper'
        >
            <div className='card_content'>
                <div className='circular_image_wrapper'>
                    <img src={require("../../assets/images/payment-success.png")} className="img-fluid" alt="" loading="lazy"/>
                </div>
                <h5 className='payment_status_title'>
                    Payment Successful
                </h5>
                <p className='payment_status_discription'>
                    {eventDetail ? 'Your event has been booked successfully. ' : 'Your order has been placed successfully.'}
                    for more details, kindly check below details of transaction.
                </p>
                <p className='payment_status_discription'>
                    {eventDetail ? 'You can check your event book status in order history page.' : 'You can check your order status in order history page.'}
                </p>
                <div className='sidebar_wrapper'>
                    <div className="sidebar_widget">
                        <h5 className='mb-4 fs18 fw600 lh_1_5'>{eventDetail ? 'Event Booked Details' : 'Bill Details'}</h5>
                        <div className="billtotal_table_wrapper">
                            {
                                eventDetail && eventDetail ?
                                    <table className="table mb-0">
                                        <tbody>
                                            <tr>
                                                <th>Subtotal</th>
                                                <td>£{eventDetail?.subTotal ? eventDetail?.subTotal?.toFixed(2) : 0}</td>
                                            </tr>
                                            <tr>
                                                <th>Persons</th>
                                                <td>{eventDetail?.persons ? eventDetail?.persons : 0}</td>
                                            </tr>
                                        </tbody>
                                        <tfoot>
                                            <tr>
                                                <th>TOTAL</th>
                                                <td>£{eventDetail?.total ? eventDetail?.total?.toFixed(2) : 0}</td>
                                            </tr>
                                        </tfoot>
                                    </table> :
                                    <table className="table mb-0">
                                        <tbody>
                                            <tr>
                                                <th>Subtotal</th>
                                                <td>£{billDetails ? billDetails?.subTotal?.toFixed(2) : 0}</td>
                                            </tr>
                                            <tr>
                                                <th>Taxes</th>
                                                <td>£{billDetails ? billDetails?.Taxes?.toFixed(2) : 0}</td>
                                            </tr>
                                            <tr>
                                                <th>Delivery Charges</th>
                                                <td>£{billDetails ? billDetails?.deliveryCharge?.toFixed(2) : 0}</td>
                                            </tr>
                                        </tbody>
                                        <tfoot>
                                            <tr>
                                                <th>TOTAL</th>
                                                <td>£{billDetails ? billDetails?.Total?.toFixed(2) : 0}</td>
                                            </tr>
                                        </tfoot>
                                    </table>
                            }
                        </div>
                    </div>
                </div>
                <button className='button button-2 w-100 fs18 fw600' onClick={() => { props.onClickBtn(); setPaymentSuccessModalOpen(false) }}>
                    Done
                </button>
            </div>
        </Modal>
    )
}

export default PaymentSuccessfulModal