import Http from '../../Http'
import * as action from '../../store/actions'
// import ToastMe from '../../view/common/ToastMe';
const BaseUrl = process.env.REACT_APP_BASE_URL;

export function login(credentials) {
    credentials.pushToken = 'binox-web';
    credentials.device = '1';
    return dispatch => (
        new Promise((resolve, reject) => {
            Http.callApi('post', BaseUrl + '/user/login', credentials)
                .then(function (res) {
                    dispatch(action.authLogin(res));
                    return resolve(res);
                })
                .catch(function (error) {
                    const data = {
                        errorData: error.response.data,
                        statusCode: error.response.status,
                    };
                    return reject(data);
                })

        })
    )
}

export function register(credentials) {
    credentials.pushToken = 'binox-web';
    credentials.device = '3';
    return dispatch => (
        new Promise((resolve, reject) => {
            Http.callApi('post', BaseUrl + '/user/register', credentials)
                .then(function (res) {
                    localStorage.setItem('jwt_type', 'verify');
                    return resolve(res);
                })
                .catch(function (error) {
                    const data = {
                        errorData: error.response.data,
                        statusCode: error.response.status,
                    };
                    return reject(data);
                })

        })
    )
}

export function otpVerify(data, token) {
    return dispatch => (
        new Promise((resolve, reject) => {
            let config = {
                headers: {
                    'authorization': 'Bearer ' + token,
                    'refreshToken': 'test'
                }
            }
            Http.callApi('post', BaseUrl + '/user/otp/verify/signup', data, config)
                .then(function (res) {
                    dispatch(action.authVerify(res));
                    return resolve(res);
                })
                .catch(function (error) {
                    const data = {
                        errorData: error.response.data,
                        statusCode: error.response.status,
                    };
                    return reject(data);
                })

        })
    )
}

export function getProfile() {
    return dispatch => (
        new Promise((resolve, reject) => {
            Http.callApi('get', BaseUrl + '/user/getprofile')
                .then(function (res) {
                    return resolve(res);
                })
                .catch(function (error) {
                    const data = {
                        errorData: error.response.data.errors,
                        statusCode: error.response.status,
                    };
                    return reject(data);
                })
        })
    )
}

export function updateUserProfile(data, userData) {
    return dispatch => (
        new Promise((resolve, reject) => {
            Http.callApi('post', BaseUrl + '/user/updateUserProfile', data)
                .then(function (res) {
                    if(data.avatar){
                        userData.avatar = data.avatar;
                    }
                    userData.fullName = data.firstname + ' ' + data.lastname;
                    const my_data = { data: { userData: userData } };
                    dispatch(action.setUserData(my_data));
                    return resolve(res);
                })
                .catch(function (error) {
                    const data = {
                        errorData: error.response.data.errors,
                        statusCode: error.response.status,
                    };
                    return reject(data);
                })
        })
    )
}

export function uploadUserProfile(data) {
    data.env = 'test';
    return dispatch => (
        new Promise((resolve, reject) => {
            Http.callApi('post', BaseUrl + '/uploadImage/user', data)
                .then(function (res) {
                    return resolve(res);
                })
                .catch(function (error) {
                    console.log(error);
                    const data = {
                        errorData: error.response.data.message,
                        statusCode: error.response.status,
                    };
                    return reject(data);
                })
        })
    )
}
export function changePassword(data) {
    return dispatch => (
        new Promise((resolve, reject) => {
            Http.callApi('post', BaseUrl + '/user/changepassword', data)
                .then(function (res) {
                    return resolve(res);
                })
                .catch(function (error) {
                    const data = {
                        errorData: error.response.data,
                        statusCode: error.response.status,
                    };
                    return reject(data);
                })
        })
    )
}
export function ContactUs(data) {
    return dispatch => (
        new Promise((resolve, reject) => {
            Http.callApi('post', BaseUrl + '/user/supportTicket', data)
                .then(function (res) {
                    return resolve(res);
                })
                .catch(function (error) {
                    const data = {
                        errorData: error.response.data,
                        statusCode: error.response.status,
                    };
                    return reject(data);
                })
        })
    )
}
export function getAddress(data) {
    return dispatch => (
        new Promise((resolve, reject) => {
            Http.callApi('get', BaseUrl + '/user/getAddress')
                .then(function (res) {
                    return resolve(res);
                })
                .catch(function (error) {
                    const data = {
                        errorData: error.response.data,
                        statusCode: error.response.status,
                    };
                    return reject(data);
                })
        })
    )
}
export function AddAddress(data, userData) {
    return dispatch => (
        new Promise((resolve, reject) => {
            Http.callApi('post', BaseUrl + '/user/addAddress', data)
                .then(function (res) {
                    userData.address.push(res.data)
                    const my_data = { data: { userData: userData } };
                    dispatch(action.setUserData(my_data));
                    return resolve(res);
                })
                .catch(function (error) {
                    const data = {
                        errorData: error.response.data,
                        statusCode: error.response.status,
                    };
                    return reject(data);
                })
        })
    )
}
export function getOtp(data) {
    return dispatch => (
        new Promise((resolve, reject) => {
            Http.callApi('post', BaseUrl + '/user/otp/get', data)
                .then(function (res) {
                    return resolve(res);
                })
                .catch(function (error) {
                    const data = {
                        errorData: error.response.data,
                        statusCode: error.response.status,
                    };
                    return reject(data);
                })
        })
    )
}
export function forgotOtpVerify(data) {
    return dispatch => (
        new Promise((resolve, reject) => {
            Http.callApi('post', BaseUrl + '/user/otp/verify', data)
                .then(function (res) {
                    return resolve(res);
                })
                .catch(function (error) {
                    const data = {
                        errorData: error.response.data,
                        statusCode: error.response.status,
                    };
                    return reject(data);
                })
        })
    )
}
export function forgotPassword(data) {
    return dispatch => (
        new Promise((resolve, reject) => {
            Http.callApi('post', BaseUrl + '/user/forgotpassword', data)
                .then(function (res) {
                    return resolve(res);
                })
                .catch(function (error) {
                    const data = {
                        errorData: error.response.data,
                        statusCode: error.response.status,
                    };
                    return reject(data);
                })
        })
    )
}

export function deleteAddress(id) {
    return dispatch => (
        new Promise((resolve, reject) => {
            Http.callApi('delete', BaseUrl + '/user/deleteAddress?id=' + id)
                .then(function (res) {
                    // if(userData.address.length <= 0){
                    //     userData.defaultAddress = null;
                    // }
                    // const arr = userData.filter((item) => item._id !== id);
                    // const my_data = { data: { userData: arr } };
                    // console.log({my_data})
                    // dispatch(action.setUserData(my_data));
                    return resolve(res);
                })
                .catch(function (error) {
                    const data = {
                        errorData: error.response.data,
                        statusCode: error.response.status,
                    };
                    return reject(data);
                })
        })
    )
}

export function defaultAddress(id, userData) {
    return dispatch => (
        new Promise((resolve, reject) => {
            Http.callApi('get', BaseUrl + '/user/updateDefaultAddress/' + id)
                .then(function (res) {
                    userData.defaultAddress = id;
                    const my_data = { data: { userData: userData } };
                    dispatch(action.setUserData(my_data));
                    return resolve(res);
                })
                .catch(function (error) {
                    const data = {
                        errorData: error.response.data,
                        statusCode: error.response.status,
                    };
                    return reject(data);
                })
        })
    )
}

export function resendOtp(data) {
    return dispatch => (
        new Promise((resolve, reject) => {
            let config = {
                headers: {
                    'authorization': 'Bearer ' + data.token,
                    'refreshToken': 'test'
                }
            }
            Http.callApi('post', BaseUrl + '/user/otp/resend', data, config)
                .then(function (res) {
                    return resolve(res);
                })
                .catch(function (error) {
                    const data = {
                        errorData: error.response.data,
                        statusCode: error.response.status,
                    };
                    return reject(data);
                })
        })
    )
}
export function getAdminSetting(data) {
    // console.log(data);
    return dispatch => (
        new Promise((resolve, reject) => {
            Http.callApi('get', BaseUrl + '/user/getAdminSetting')
                .then(function (res) {
                    return resolve(res);
                })
                .catch(function (error) {
                    const data = {
                        errorData: error.response.data,
                        statusCode: error.response.status,
                    };
                    return reject(data);
                })
        })
    )
}
export function addCard(data) {
    return dispatch => (
        new Promise((resolve, reject) => {
            Http.callApi('post', BaseUrl + '/user/web/webAddNewCard', data)
                .then(function (res) {
                    return resolve(res);
                })
                .catch(function (error) {
                    const data = {
                        errorData: error.response.data,
                        statusCode: error.response.status,
                    };
                    return reject(data);
                })
        })
    )
}

export function deleteCard(data) {
    return dispatch => (
        new Promise((resolve, reject) => {
            Http.callApi('post', BaseUrl + '/user/web/webDeleteCard', data)
                .then(function (res) {
                    return resolve(res);
                })
                .catch(function (error) {
                    const data = {
                        errorData: error.response.data,
                        statusCode: error.response.status,
                    };
                    return reject(data);
                })
        })
    )
}
export function getNotifications() {
    return dispatch => (
        new Promise((resolve, reject) => {
            Http.callApi('get', BaseUrl + '/user/notificationList')
                .then(function (res) {
                    dispatch(action.setNotificationData(res));
                    return resolve(res);
                })
                .catch(function (error) {
                    const data = {
                        errorData: error.response.data,
                        statusCode: error.response.status,
                    };
                    return reject(data);
                })
        })
    )
}
export function notificationRead(id) {
    return dispatch => (
        new Promise((resolve, reject) => {
            Http.callApi('get', BaseUrl + '/user/web/notificationRead?notify_id='+id)
                .then(function (res) {
                    return resolve(res);
                })
                .catch(function (error) {
                    console.log(error);
                    const data = {
                        errorData: error.response.data,
                        statusCode: error.response.status,
                    };
                    return reject(data);
                })
        })
    )
}
