import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Form, Input, Tabs } from 'antd';
import AuthService from '../components/services/auth'
import { useDispatch } from "react-redux";
import ToastMe from "../components/common/ToastMe";
import { Eye, EyeSlash } from 'iconsax-react';
import PhoneInput from "react-phone-input-2";
const { TabPane } = Tabs;

const Login = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [phone, setPhone] = useState();
    const [email, setEmail] = useState();

    const onFinish = (data) => {
        let loginData = {};
        if (phone != null) {
            loginData.username = {
                "3": '+' + phone
            }
        } else {
            loginData.username = {
                "2": email
            }
        }
        loginData.password = data.password;

        dispatch(AuthService.login(loginData))
            .then((res) => {
                ToastMe("Login Successfully", 'success')
                navigate("/")
            })
            .catch((errors) => {
                ToastMe(errors.errorData.message, 'error');
            })
    };
    const handlePhoneValue = (e) => {
        setPhone(e)
        setEmail(null)
    };
    const handleEmailValue = (e) => {
        setEmail(e.target.value)
        setPhone(null)
    };
    return (
        <>
            <div className="">
                <div className="page-ath-content mx-auto">
                    <div className="page-ath-form">
                        <div className="auth-head">
                            <h6>Binox Bargains Account Login</h6>
                            <p>Welcome back! Log in with your Email</p>
                        </div>
                        <Form name="basic" labelCol={{ span: 8 }} wrapperCol={{ span: 24 }} initialValues={{ remember: true }} onFinish={onFinish} autoComplete="off" >
                            <div className="row">
                                <div className="col-md-12">
                                    <Tabs defaultActiveKey="mobile" className='login-tabs'>
                                        <TabPane key="mobile" tab="Mobile">
                                            <div class="form-group mb-3">
                                                <label class="label-name">Mobile Number</label>
                                                <Form.Item
                                                    name="mobile"
                                                    rules={[
                                                        ({ getFieldValue }) => ({
                                                            validator(_, value) {
                                                                if (email || value) {
                                                                    return Promise.resolve();
                                                                }
                                                                return Promise.reject(new Error('Please enter your mobile number!'));
                                                            },
                                                        }),
                                                    ]}
                                                // rules={[{ required: true, message: 'Please enter your mobile number!' }, { min: 8, message: 'Mobile number should be min 10 number' }, { max: 12, message: 'Mobile number should be max 12 number' }]}
                                                >
                                                    <PhoneInput
                                                        country={'us'}
                                                        specialLabel="phone"
                                                        onChange={(e) => handlePhoneValue(e)}
                                                    />
                                                </Form.Item>
                                            </div>
                                        </TabPane>
                                        <TabPane key="email" tab="Email">
                                            <div className="form-group mb-3 ">
                                                <label className="label-name">Email</label>
                                                <Form.Item
                                                    name="email"
                                                    onChange={(e) => handleEmailValue(e)}
                                                    rules={[
                                                        { pattern: new RegExp(/^([A-Z0-9a-z._%+-])+\@([A-Za-z0-9.-])+(\.[A-Za-z]{2,4})+$/), message: 'Please enter valid email address!' },
                                                        ({ getFieldValue }) => ({
                                                            validator(_, value) {
                                                                if (phone || value) {
                                                                    return Promise.resolve();
                                                                }
                                                                return Promise.reject(new Error('Please enter your email!'));
                                                            },
                                                        }),
                                                    ]}
                                                // rules={[{ required: true, message: 'Please input your email!' },
                                                // { pattern: new RegExp(/^([A-Z0-9a-z._%+-])+\@([A-Za-z0-9.-])+(\.[A-Za-z]{2,4})+$/), message: 'Please enter valid email address!' }]}
                                                >
                                                    <Input placeholder='Enter Email' className="input-control w-100" />
                                                </Form.Item>
                                            </div>
                                        </TabPane>
                                    </Tabs>
                                </div>
                                <div className="col-md-12">
                                    <div className="form-group mb-3">
                                        <label className="label-name">Password</label>
                                        <Form.Item
                                            name="password"
                                            rules={[{ required: true, message: 'Please enter your password!' },

                                            ]}
                                        >
                                            <Input.Password
                                                className='input-control w-100'
                                                placeholder="Enter Password"
                                                iconRender={(visible) => (visible ? <Eye size="18" color="#bbbbbb" /> : <EyeSlash size="18" color="#bbbbbb" />)}
                                            />
                                        </Form.Item>
                                    </div>
                                </div>
                            </div>
                            <div className="forgot-link text-end mb30">
                                <Link to={"/forgot-password"}>Forgot password?</Link>
                            </div>
                            <input type="submit" name="" class="button button-2 w-100" value="login" />
                        </Form>
                        <div className="form-footer-link text-center mt-4">
                            <p className="font-open">Don't have an account? <Link to={"/register"} className="forgot-link">Register Now</Link></p>
                        </div>
                    </div>
                </div>
            </div >
        </>
    );
}

export default Login