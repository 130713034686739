import { Button, Form, Input } from 'antd';
import React, { useState } from 'react'
// import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';
import { Link, useNavigate } from 'react-router-dom';
import { Eye, EyeSlash } from 'iconsax-react';
import { useDispatch } from 'react-redux';
import AuthService from '../components/services/auth';
import ToastMe from '../components/common/ToastMe';
import PhoneInput from "react-phone-input-2";
import { TextField } from 'material-ui';

const Register = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [phoneValue, setPhoneValue] = useState();
    const [form] = Form.useForm();
    const [buttonLoading, setButtonLoading] = useState(false);

    const handlePhoneValue = (e) => {
        setPhoneValue(e);
    };
    const onFinish = (data) => {
        data.username = {
            "2": data.email,
            "3": '+' + phoneValue
        }
        setButtonLoading(true)
        dispatch(AuthService.register(data))
            .then((res) => {
                setButtonLoading(false)
                ToastMe('Your otp for verification is sent on your mobile number', 'success')
                navigate('/otp-verification', { state: { token: res.data.token, flag: 'register' } })
            })
            .catch(({ errorData }) => {
                setButtonLoading(false)
                if (errorData.message) {
                    ToastMe(errorData.message, 'error')
                }
                if (errorData.errors['username.2']) {
                    form.setFields([{ name: 'email', errors: ['The email format is invalid.'] }])
                }
                if (errorData.errors['username.3']) {
                    form.setFields([{ name: 'mobile', errors: ['The mobile number must be at least 8 characters.'] }])
                }
                if (errorData.errors['password']) {
                    form.setFields([{ name: 'password', errors: ['Password should be at least 8 characters in length and should include at least one upper case letter, lower case letter, one number, and one special character.'] }])
                }
            })
    };

    return (
        <div class="">
            <div class="page-ath-content mx-auto">
                <div class="page-ath-form">
                    <div class="auth-head">
                        <h6>Create your account</h6>
                        <p>Welcome to Binox Bargains</p>
                    </div>
                    <Form name="basic" form={form} labelCol={{ span: 8 }} wrapperCol={{ span: 24 }} initialValues={{ remember: true }} autoComplete="off" onFinish={onFinish} >
                        <div class="row">
                            <div class="col-md-12">
                                <div class="form-group mb-3">
                                    <label class="label-name">First Name</label>
                                    <Form.Item
                                        name="firstname"
                                        rules={[{ required: true, message: 'Please enter your first name!' },
                                        { max: 15, message: 'You can not enter more than 15 characters' },
                                        { pattern: new RegExp("[a-zA-Z]+$"), message: 'Please enter only characters' }]}
                                    >
                                        <Input placeholder="First Name" className='input-control' />
                                    </Form.Item>
                                </div>
                            </div>
                            <div class="col-md-12">
                                <div class="form-group mb-3">
                                    <label class="label-name">Last Name</label>
                                    <Form.Item
                                        name="lastname"
                                        rules={[{ required: true, message: 'Please enter your last name!' },
                                        { max: 15, message: 'You can not enter more than 15 characters' },
                                        { pattern: new RegExp("[a-zA-Z]+$"), message: 'Please enter only characters' }]}
                                    >
                                        <Input placeholder="Last Name" className='input-control' />
                                    </Form.Item>
                                </div>
                            </div>
                            <div class="col-md-12">
                                <div class="form-group mb-3">
                                    <label class="label-name">Email</label>
                                    <Form.Item
                                        name="email"
                                        rules={[{ required: true, message: 'Please enter your email address!' },
                                        { pattern: new RegExp(/^([A-Z0-9a-z._%+-])+\@([A-Za-z0-9.-])+(\.[A-Za-z]{2,4})+$/), message: 'Please enter valid email address!' }]}
                                    >
                                        <Input placeholder="Your Email" className='input-control' />
                                    </Form.Item>
                                </div>
                            </div>
                            <div class="col-md-12">
                                <div class="form-group mb-3">
                                    <label class="label-name">Mobile Number</label>
                                    <Form.Item
                                        name="mobile"
                                        rules={[{ required: true, message: 'Please enter your mobile number!' }]}
                                    >
                                        <PhoneInput
                                            country={'us'}
                                            specialLabel="phone"
                                            onChange={(e) => handlePhoneValue(e)}
                                        />
                                    </Form.Item>
                                </div>
                            </div>
                            <div class="col-md-12">
                                <div class="form-group mb-3">
                                    <label class="label-name">Password</label>
                                    <Form.Item
                                        name="password"
                                        rules={[{ required: true, message: 'Please enter your password!' },
                                        { max: 20, message: 'You can not enter more than 20 characters' }]}
                                    >
                                        <Input.Password
                                            className='input-control'
                                            placeholder="Password"
                                            onPaste={(e) => {
                                                e.preventDefault()
                                                return false;
                                            }}
                                            onCopy={(e) => {
                                                e.preventDefault()
                                                return false;
                                            }}
                                            iconRender={(visible) => (visible ? <Eye size="18" color="#bbbbbb" /> : <EyeSlash size="18" color="#bbbbbb" />)}
                                        />
                                    </Form.Item>
                                </div>
                            </div>
                            <div class="col-md-12">
                                <div class="form-group mb40">
                                    <label class="label-name">Confirm Password</label>
                                    <Form.Item
                                        name="confirm_password"
                                        rules={[{ required: true, message: 'Please enter your confirm password!' },
                                        ({ getFieldValue }) => ({
                                            validator(_, value) {
                                                if (!value || getFieldValue('password') === value) {
                                                    return Promise.resolve();
                                                }
                                                return Promise.reject(new Error('The confirm passwords that you entered do not match!'));
                                            },
                                        }),
                                        ]}
                                    >
                                        <Input.Password
                                            className='input-control'
                                            placeholder="Confirm Password"
                                            iconRender={(visible) => (visible ? <Eye size="18" color="#bbbbbb" /> : <EyeSlash size="18" color="#bbbbbb" />)}
                                        />
                                    </Form.Item>
                                </div>
                            </div>
                        </div>
                        <Button htmlType='submit' loading={buttonLoading} className="button button-2 w-100">
                            Sign Up
                        </Button>
                    </Form>
                    <div class="form-footer-link text-center mt-4">
                        <p class="font-open">Already have an account? <Link to={"/login"} class="forgot-link">Login</Link></p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Register;