import React, { useEffect, useState } from 'react';
import { Button, Progress } from 'antd';
import { useLocation, useNavigate } from 'react-router-dom';
import moment from 'moment';
import { connect, useDispatch } from 'react-redux';
import PaymentFailedModal from '../../../modals/PaymentFailedModal';
import AddCardModal from '../../../modals/AddCardModal';
import ConfirmModel from '../../../modals/ConfirmModel';
import UserSavedCard from '../Cart/UserSavedCard';
import StripeCheckout from 'react-stripe-checkout';
import eventService from '../../../services/events';
import ToastMe from '../../../common/ToastMe';
import PaymentSuccessfulCharityModal from './PaymentSuccessfulCharityModal';
const BaseUrl = process.env.REACT_APP_PROFILE_URL;

const CharityDetails = (props) => {
    const dataCharity = props.charityDetailData;
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const userData = props.userData.userData;
    const [donation, setDonateFlag] = useState(0);
    const [cardData, setCardData] = useState([]);
    const [isSelected, setIsSelected] = useState({ payment_name: '', id: '' });
    const [paymentSuccessModalOpen, setPaymentSuccessModalOpen] = useState(false);
    const [paymentFailedModalOpen, setPaymentFailedModalOpen] = useState(false);
    const [billDetails, setBillDetails] = useState();
    const [ConfirmModalOpen, SetConfirmModalOpen] = useState(false);
    const [stripeToken, setStripeToken] = useState('');
    const [addCardModalOpen, setAddCardModalOpen] = useState(false);
    const [amount, setAmountToDonate] = useState(0);
    const [paymentShow, setPaymentShow] = useState(false);
    const [buttonLoading, setButtonLoading] = useState(false);

    const RedirectToLogin = () => {
        navigate('/login');
    }
    const donate = () => {
        setDonateFlag(!donation);
    }

    const onToken = (token) => setStripeToken(token.id)

    const confirmModalClose = () => {
        SetConfirmModalOpen(false);
    }

    const getCard = () => {
        var myHeaders = new Headers();
        myHeaders.append("Authorization", `Bearer ${process.env.REACT_APP_STRIPE_SECRET}`);
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

        var requestOptions = { method: 'GET', headers: myHeaders, redirect: 'follow' };

        if (userData?.customer_id) {
            fetch('https://api.stripe.com/v1/customers/' + userData?.customer_id + '/sources', requestOptions)
                .then(response => response.text())
                .then((result) => {
                    let responseData = JSON.parse(result).data;
                    setCardData(responseData)
                    setIsSelected({ ...isSelected, payment_name: 'card', id: responseData[0].id })
                })
                .catch(error => console.log('error', error));
        }
    }

    const onPaymentMethodClick = (payment_name, id) => {
        setIsSelected({ ...isSelected, payment_name: payment_name, id: id })
    }
    const getValue = (e) => {
        const value = e.target.value
        const regex = /^[0-9]*$/;
        if (regex.test(value)) {
            setAmountToDonate(value);
        }
    }

    const charityDonation = () => {
        if (amount < 0 || amount == 0) {
            ToastMe('Please enter valid amount !!', 'error');
        } else {

            if (cardData.length > 0) {
                var data = {};
                data['charity_item_id'] = dataCharity._id;
                data['source'] = isSelected.id;
                data['amount'] = Number(amount);
                data['payment_type'] = 1;
                setButtonLoading(true)
                dispatch(eventService.charityDonation(data))
                    .then((res) => {
                        setButtonLoading(false)
                        setPaymentSuccessModalOpen(true);
                        setAmountToDonate('')
                        setBillDetails(data)
                        setPaymentShow(false);
                    })
                    .catch((errors) => {
                        setButtonLoading(false)
                        console.log({ errors })
                    })
            } else {
                ToastMe('First you have add you card', 'error');
            }
        }

    }

    const payamount = (e) => {
        e.preventDefault();
        if (amount < 0 || amount == 0) {
            ToastMe('Please enter valid amount !!', 'error');
        } else {
            setPaymentShow(!paymentShow)
        }
    }
    useEffect(() => {
        if (stripeToken) {
            var myHeaders = new Headers();
            myHeaders.append("Authorization", `Bearer ${process.env.REACT_APP_STRIPE_SECRET}`);
            myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

            var urlencoded = new URLSearchParams();
            urlencoded.append("source", stripeToken);

            var requestOptions = { method: 'POST', headers: myHeaders, body: urlencoded, redirect: 'follow' };

            fetch('https://api.stripe.com/v1/customers/' + userData?.customer_id + '/sources', requestOptions)
                .then((response) => {
                    response.text();
                    getCard();
                })
                .catch((error) => { console.log('error', error) });
        }

    }, [stripeToken])

    useEffect(() => {
        getCard();
    }, [])

    const RemoveDetails = () => {
        navigate("/order-history", { state: { category: 5 } });
    }

    return (
        <>
            <section className='charity-details_hero_section'>
                <div className='container'>
                    <div className='banner_wrapper' style={{ backgroundImage: `url(${(BaseUrl + 'ngoItems/' + dataCharity?.image)})` }}>
                        <div className='charity_date_absolute'>
                            <div className='event_date_absolute'>
                                <span className='numeric_value'>
                                    {moment(dataCharity?.createdAt).format("D")}
                                </span>
                                <span>
                                    {moment(dataCharity?.createdAt).format("MMM")}
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className='text_content_bottom'>
                        <div>
                            <h3>
                                {dataCharity?.title}
                            </h3>
                            <p>
                                {dataCharity?.sub_title}
                            </p>
                            <div className='date_percent_between'>
                                <p> {moment(dataCharity?.createdAt).format("DD MMM YYYY")}</p><span>{dataCharity?.percentage}%</span>
                            </div>
                            <Progress percent={dataCharity?.percentage} showInfo={false} />
                        </div>
                        {localStorage.getItem('jwt_token') ?
                            <button className='button button-2' onClick={donate}>Donate</button>
                            :
                            <button className='button button-2' onClick={RedirectToLogin}>Donate</button>
                        }

                    </div>
                </div>
            </section>
            <section className='charity_details_about gap50'>
                <div className='container'>
                    <div className='row gy-5'>
                        <div className='col-lg-7 col-xl-8 col-md-6'>
                            <div className='text_paragraph_wrapper'>
                                <h4>About :-</h4>
                                <p>{dataCharity?.description}</p>
                            </div>
                        </div>
                        {donation == 1 ? <div className='col-lg-5 col-xl-4 col-md-6'>
                            <div className='form_wrapper'>
                                <form>
                                    <ul>
                                        <li>
                                            <label for="">Donate Amount</label>
                                            <input type="number" id='amount' value={amount} onChange={(e) => getValue(e)} />
                                        </li>
                                    </ul>
                                    {
                                        paymentShow === false ? <button className='button button-2' onClick={(e) => payamount(e)}> pay </button> : ''
                                    }
                                </form>
                            </div>
                            {paymentShow == true ? <div className="sidebar_widget">
                                <h5 className='mb-4 fs18 fw600 lh_1_5'>Select Your Card</h5>
                                <div className="custom_radio_wrap payment_method_card mb30">
                                    {
                                        cardData && cardData.map((item, i) => {
                                            return (
                                                <UserSavedCard cardUserName={item.name} cardId={item.id} cardExpiryDate={`${item.exp_month}/${item.exp_year}`} cardLast4Digit={item.last4} cardBrand={item.brand} isSelected={isSelected} onCardClick={onPaymentMethodClick} index={i} />
                                            )
                                        })
                                    }
                                    <StripeCheckout
                                        stripeKey={process.env.REACT_APP_STRIPE_KEY}
                                        token={onToken}
                                        email={userData?.email}
                                        name={userData?.fullname}
                                        panelLabel="Save Card"
                                        style={{ color: '#fff', fontSize: 18 }}
                                    >
                                        <div className="">
                                            <input type="radio" className="radio_input" id='payment_card3' name="payment_radio" />
                                            <label htmlFor="payment_card3" className="radio_label" onClick={() => setAddCardModalOpen(false)}>
                                                <div className="payment_card_box flex_item_cs gap-3">
                                                    <img src={require("../../../../assets/images/icons/icon_add_card.svg").default} className="img-fluid" alt="" />
                                                    <p className="mb-0 fs18 fw500"> Add New Card</p>
                                                </div>
                                            </label>
                                        </div>
                                    </StripeCheckout>
                                </div>
                                <Button loading={buttonLoading} className="button button-2 w-100" onClick={() => charityDonation()}>
                                    Pay
                                </Button>
                            </div> : ""}
                        </div> : ""
                        }
                    </div>
                </div>
            </section>
            <PaymentFailedModal paymentFailedModalOpen={paymentFailedModalOpen} setPaymentFailedModalOpen={setPaymentFailedModalOpen} />
            <PaymentSuccessfulCharityModal billDetails={billDetails} paymentSuccessModalOpen={paymentSuccessModalOpen} setPaymentSuccessModalOpen={setPaymentSuccessModalOpen} onClickBtn={() => RemoveDetails()} />
            <AddCardModal addCardModalOpen={addCardModalOpen} setAddCardModalOpen={setAddCardModalOpen} />
            <ConfirmModel ConfirmModalOpen={ConfirmModalOpen} ConfirmModalCloseEvent={confirmModalClose} />
        </>

    )
}

const mapStateToProps = state => {
    return {
        isAuthenticated: state.Auth.isAuthenticated,
        userData: state.Auth.userData,
        charityDetailData: state.Auth.charityDetailData
    }
}
export default connect(mapStateToProps)(CharityDetails);