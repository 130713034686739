import { useLocation } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { Location } from 'iconsax-react';
import FeedbackAskModal from '../../../modals/FeedbackAskModal';
import ThanksForFeedbackModal from '../../../modals/ThanksForFeedbackModal';
import moment from 'moment';
import { Rate } from 'antd';
import orderservice from '../../../services/orders';
import { useDispatch } from 'react-redux';
import { Badge } from 'antd';
const BaseUrl = process.env.REACT_APP_PROFILE_URL;

const OrderDetails = (props) => {
    const dispatch = useDispatch();
    const { state } = useLocation();
    const [giveFeedBackModalOpen, setGiveFeedBackModalOpen] = useState(false);
    const [thanksFeedBackModalOpen, setThanksFeedBackModalOpen] = useState(false);
    const [itemRate, setItem] = useState('');
    const [category, setCategory] = useState('');
    const [orderData, setOrderData] = useState();

    const sendFeedback = (item) => {
        setGiveFeedBackModalOpen(true);
        setItem(item);
        setCategory(orderData?.vendor_data?.category_type);
    }

    const orderDetails = () => {
        dispatch(orderservice.getOrderDetail(state?.data))
            .then((res) => {
                if (res.data) {
                    setOrderData(res.data)
                }
            })
            .catch((errors) => {
                console.log({ errors })
            })
    }

    useEffect(() => {
        orderDetails();
    }, [state])

    return (
        <>
            <section className='section order_details_page gap50'>
                <div className="container">
                    <div className="row">
                        <div className="col-xl-8 col-lg-7">
                            <div className="menu_items_card pe-xl-5 pe-lg-4">
                                <div className="section_title mb30">
                                    <h2 className="fs24">Your Order</h2>
                                </div>
                                <div className="menu_items_list past_order_list custom-scrollbar">
                                    <ul className="product_list_main">
                                        {orderData?.items?.map((item, i) => {
                                            let img = orderData?.vendor_data?.category_type == '1' ? 'foodItem/' : 'groceryItem/';
                                            return (
                                                <li>
                                                    <div className="product_list_item flex_item_cb gap-3" key={i}>
                                                        <div className="product_image flex-shrink-0">
                                                            <img src={BaseUrl + img + item.image} className="img-fluid" alt="" loading="lazy"/>
                                                        </div>
                                                        <div className="product_text flex_item_cb flex-grow-1">
                                                            <div className="left_part flex-grow-1">
                                                                <h4 className="mb-2">{item.item_name}</h4>
                                                                <p className='price mb-2'><ins className="fs20 lh-1 fw600">£{item.price}</ins></p>
                                                                <p className="order_time mb-0 fs18 lh-1 text_dark1 fw600">x{item.quantity}</p>
                                                            </div>
                                                            <div className='right_part flex-shrink-0 rating_content'>
                                                                {orderData?.status === 2 ? <div className="right_part flex-shrink-0">
                                                                    {item.rating == 0 ? <a onClick={() => sendFeedback(item)} role={"button"} className="fs16 lh-1 fw600 text_blue">Give Feedback</a> :
                                                                        <Rate disabled allowHalf value={item.rating} />}
                                                                </div> : ''}
                                                            </div>

                                                        </div>
                                                    </div>
                                                </li>
                                            )
                                        })}

                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-4 col-lg-5">
                            <div className="cart_sidebar sidebar_wrapper">
                                <div className="sidebar_widget">
                                    <div className='flex_item_cb mb-4'>
                                        <h5 className='fs18 fw600 lh_1_5 mb-0'>{orderData?.vendor_data?.businessName}</h5>
                                        {orderData?.status === 0 && (
                                            <Badge.Ribbon text="Pending" style={{ backgroundColor: "#E4A11B", color: "#fff" }}></Badge.Ribbon>
                                        )}
                                        {orderData?.status === 1 && (
                                            <Badge.Ribbon text="In Progress" style={{ backgroundColor: "#54B4D3", color: "#fff" }}></Badge.Ribbon>

                                        )}
                                        {orderData?.status === 2 && (
                                            <Badge.Ribbon text="Completed" style={{ backgroundColor: "#14A44D", color: "#fff" }}></Badge.Ribbon>

                                        )}
                                        {orderData?.status === 3 && (
                                            <Badge.Ribbon text="Cancelled" style={{ backgroundColor: "#DC4C64", color: "#fff" }}></Badge.Ribbon>

                                        )}
                                    </div>
                                    <div className="flex_item_ss gap-2">
                                        <Location size="20" color='#FFBD05' /> <p className="fs16 lh_1_5 mb-0 fw400">{orderData?.vendor_data?.businessAddress}</p>
                                    </div>
                                    <br></br>
                                    {/* <div className="flex_item_ss gap-2">
                                        <h5 className='mb-4 fs18 fw600 lh_1_5'>Pickup Start:</h5>
                                        <p className="fs16 lh_1_5 mb-0 fw400">{orderData?.is_pickup != true ? '' : moment(orderData?.pickup_start_time).format('H:m:s a')}</p>
                                        <h5 className='mb-4 fs18 fw600 lh_1_5'>Pickup End:</h5>
                                        <p className="fs16 lh_1_5 mb-0 fw400">{orderData?.is_pickup != true ? '' : moment(orderData?.pickup_end_time).format('H:m:s a')}</p>
                                    </div> */}
                                    {
                                        orderData?.status !== 2 ?
                                            <div className="">
                                                {orderData?.is_pickup == true ?
                                                    <>
                                                        <p>
                                                            {
                                                                orderData?.pickup_start_time ?
                                                                    <>
                                                                        <label className="text_c818181 fs16 lh-1 fw400 mb-2">Pickup Date & Time</label>
                                                                        <h6 className="fs16 fw600 lh_1_5 mb-3">Date : {moment(orderData?.pickup_start_time).format("DD/MM/YYYY")}</h6>
                                                                        <h6 className="fs16 fw600 lh_1_5 mb-3">Time : {moment(orderData?.pickup_start_time).format('hh:mm a')} to {moment(orderData?.pickup_end_time).format('hh:mm a')}</h6>
                                                                    </> : 'Pickup time provided you after accept your order. Please wait until accept your order.'
                                                            }
                                                        </p>
                                                    </>
                                                    :
                                                    ''
                                                }
                                            </div> : ''
                                    }

                                </div>
                                <div className="sidebar_widget">
                                    <h5 className='mb-4 fs18 fw600 lh_1_5'>Order Details</h5>
                                    <label className="text_c818181 fs16 lh-1 fw400 mb-2">Order Number</label>
                                    <h6 className="fs16 fw600 lh_1_5 mb-3">{orderData?.order_id}</h6>
                                    <label className="text_c818181 fs16 lh-1 fw400 mb-2">Payment Type</label>
                                    <h6 className="fs16 fw600 lh_1_5 mb-3">{orderData?.payment_type}</h6>
                                    <div className="">
                                        {orderData?.status === 1 ?
                                            <>
                                                <label className="text_c818181 fs16 lh-1 fw400 mb-2">Verification OTP</label>
                                                <h6 className="fs16 fw600 lh_1_5 mb-3">{orderData?.verification_otp}</h6>
                                            </>
                                            : ''}
                                    </div>
                                    <label className="text_c818181 fs16 lh-1 fw400 mb-2">Date</label>
                                    <h6 className="fs16 fw600 lh_1_5 mb-3">{moment(orderData?.createdAt).format('Do MMM YYYY')}</h6>
                                    <div className="">
                                        {orderData?.is_pickup == false ?
                                            <>
                                                <label className="text_c818181 fs16 lh-1 fw400 mb-2">Deliver to</label>
                                                <h6 className="fs16 fw600 lh_1_5 mb-3">{orderData?.delivery_address}</h6>
                                            </>
                                            : ''}
                                    </div>
                                    {
                                        orderData?.mobile ? <>
                                            <label className="text_c818181 fs16 lh-1 fw400 mb-2">Phone Number</label>
                                            <div className="">
                                                <h6 className="fs16 fw600 lh_1_5 mb-3">{orderData?.mobile?.substring(0, 5) + '*****'}</h6>
                                            </div></> : ''
                                    }

                                </div>
                                <div className="sidebar_widget">
                                    <h5 className='mb-4 fs18 fw600 lh_1_5'>Bill Details</h5>
                                    <div className="billtotal_table_wrapper">
                                        <table className="table mb30">
                                            <tbody>
                                                <tr>
                                                    <th>Sub Total</th>
                                                    <td>£{orderData?.sub_total.toFixed(2) ?? 0}</td>
                                                </tr>
                                                <tr>
                                                    <th>Taxes</th>
                                                    <td>£{orderData?.tax.toFixed(2) ?? 0}</td>
                                                </tr>
                                                <tr>
                                                    <th>Delivery Charges</th>
                                                    <td>£{orderData?.delivery_charge.toFixed(2) ?? 0}</td>
                                                </tr>
                                            </tbody>
                                            <tfoot>
                                                <tr>
                                                    <th>TOTAL</th>
                                                    <td>£{orderData?.total_amount ? orderData?.total_amount.toFixed(2) : 0}</td>
                                                </tr>
                                            </tfoot>
                                        </table>
                                        {/* <button className="button button-2 w-100">Reorder</button> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </section>
            <FeedbackAskModal data={itemRate} category={category} giveFeedBackModalOpen={giveFeedBackModalOpen} setGiveFeedBackModalOpen={setGiveFeedBackModalOpen} openThanksModal={setThanksFeedBackModalOpen} />
            <ThanksForFeedbackModal thanksFeedBackModalOpen={thanksFeedBackModalOpen} setThanksFeedBackModalOpen={setThanksFeedBackModalOpen} />
        </>
    )
}

export default OrderDetails;