import { Heart, Location } from 'iconsax-react';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import restaurantService from '../../../services/restruants';
import { useDispatch } from 'react-redux';
import { Empty } from 'antd';
import DefaultImage from '../../../../assets/images/Grocery.svg';
import PageLoader from '../../../common/PageLoader';
const BaseUrl = process.env.REACT_APP_PROFILE_URL;

const Wishlist = (props) => {
    const dispatch = useDispatch();
    const [favoriteData, setFavoriteData] = useState([])
    const [loading, setLoding] = useState(true);

    const getFavoriteData = () => {
        dispatch(restaurantService.getFavorite())
            .then((res) => {
                setFavoriteData(res.data);
                setLoding(false);
            })
            .catch((errors) => {
                console.log({ errors });
            });
    }

    const wishlistRemove = (data) => {
        var datas = {};
        datas['vendor_id'] = data._id;
        datas['is_fav'] = data.is_fav == false ? true : false;
        dispatch(restaurantService.addToFavorite(datas))
            .then((res) => {
                // ToastMe(res.data.message);
                getFavoriteData();
            })
            .catch((errors) => {
                console.log(errors);
            })
    }

    useEffect(() => {
        getFavoriteData()
    }, [])

    return (
        <>
            <PageLoader loading={loading} />
            <section className="section gap50">
                <div className="container">
                    <div className="section_title">
                        <h2>Wishlist</h2>
                    </div>
                    <div className="products_grids_main">
                        <div className="row">
                            {favoriteData && favoriteData.length != 0 ?
                                favoriteData.map((data, ind) => {
                                    var type;
                                    if (data.category_type == '1') {
                                        type = 'food';
                                    } else {
                                        type = 'grocery';
                                    }
                                    return (
                                        <div className="col-lg-4 col-6" key={ind}>
                                            <div className="products_cards">
                                                <div className="products_card_image mb20">
                                                    <Link role={"button"} to={"/shop/" + data.businessName + ""} state={{ shopDetails: data, type: type }}>
                                                        <img src={data.image ? BaseUrl + 'vendor/' + data.image : DefaultImage} className="img-fluid" alt={data.shopName} loading="lazy"/>
                                                    </Link>
                                                    <div className="product_action">
                                                        <a role={"buttton"} onClick={(e) => wishlistRemove(data)} className="wishlist_icon"><Heart size="16" color="#f0140c" variant="Bold" /></a>
                                                    </div>
                                                </div>
                                                <div className="products_card_text flex_item_sb gap-2">
                                                    <div className="left_part">
                                                        <h5 className='fs20'><Link role={"button"} to={"/shop/" + data.businessName + ""} state={{ shopDetails: data, type: type }}>{data.businessName}</Link></h5>
                                                        <p>{data.description}</p>
                                                        <p className="flex_item_cs gap-2"><Location size="20px" color="#818181" /> {data.businessAddress}</p>
                                                    </div>
                                                    <div className="right_part">
                                                        <div className="rating_badge flex_item_cc gap-1"><img src={require("../../../../assets/images/icons/icn_rate.svg").default} className="img-fluid" alt="" loading="lazy"/><p>{data.rating}</p></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })
                                :
                                <div>
                                    <Empty description={<p>Wishlist Not Found</p>} image={Empty.PRESENTED_IMAGE_SIMPLE} />
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Wishlist;