import axios from "axios";
const BASE_URL = process.env.REACT_APP_BASE_URL;

export default axios.create({
  baseURL: BASE_URL,
});

const axiosPrivate = axios.create({
  baseURL: BASE_URL,
  headers: { "Content-Type": "application/json", "env": "test" },
  withCredentials: true,
});

function path(root, sublink) {
  return `${root}${sublink}`;
}

const ROOTS_PATH = "/user";
const VENDOR_PATH = "vendor";
const IMAGE_PATH = "uploadImage";

const AUTH_API_URL = {
  login: path(ROOTS_PATH, "/login"),
//   updatePushTokenNotification: path(VENDOR_PATH, "/updatePushTokenNotification"),
//   register: path(VENDOR_PATH, "/register"),
//   verifyOtp: path(VENDOR_PATH, "/verifyOtp"),
//   getProfile: path(VENDOR_PATH, "/getProfile"),
//   editProfile: path(VENDOR_PATH, "/editProfile/"),
//   changePassword: path(VENDOR_PATH, "/changePassword/"),
//   getOtp: path(VENDOR_PATH, "/get/otp"),
//   forgotVerifyOtp: path(VENDOR_PATH, "/verify/Otp"),
//   forgotPassword: path(VENDOR_PATH, "/forgotpassword"),
//   resendOTP: path(VENDOR_PATH, "/otp/resend"),
//   logout: path(VENDOR_PATH, "/logout"),
//   uploadImage: path(IMAGE_PATH, "/vendorKYC"),
//   uploadMainImage: path(IMAGE_PATH, "/vendor"),
//   twoFactorVerification: path(VENDOR_PATH, "/verify/TwoFactorAuth"),
//   resendTwoFactorOtp: path(VENDOR_PATH, "/verify/twoFactor/resend"),
//   uploadKyc: path(VENDOR_PATH, "/addKyc"),
//   addBank: path(VENDOR_PATH, "/addBank"),

//   // get sub category on category change
//   getSubCategory: path(VENDOR_PATH, "/register/getSubcategories"),
//   // ====================== no use ===================
//   refreshToken: path(ROOTS_PATH, "/refreshToken"),
};

const USERS = {
    getRestraunts:path(ROOTS_PATH, "/restaurants"),
    getGroceries:path(ROOTS_PATH, "/groceryShops"),
}

export {
  axiosPrivate,
  AUTH_API_URL,
  USERS
}