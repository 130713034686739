import { Form, Input } from 'antd'
import React, { useEffect } from 'react'
// import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';
import { useLocation, useNavigate } from 'react-router-dom';
import { Eye, EyeSlash } from 'iconsax-react';
import { useDispatch } from 'react-redux';
import AuthService from '../components/services/auth';
import ToastMe from '../components/common/ToastMe';

const ResetPassword = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { state } = useLocation();
    const [form] = Form.useForm();

    const submit = (data) => {
        data['token'] = state?.token;
        data['device'] = 1;
        dispatch(AuthService.forgotPassword(data))
            .then((res) => {
                ToastMe(res.data.message, 'success')
                setTimeout(() => {
                    navigate('/login')
                }, 500)
            })
            .catch(({ errorData }) => {
                if (errorData.errors['password']) {
                    form.setFields([{ name: 'password', errors: ['Password should be at least 8 characters in length and should include at least one upper case letter, one number, and one special character.'] }])
                }
                // for (const e in errorData.errors) {
                //     form.setFields([{ name: e, errors: [errorData.errors[e]] }])
                // }
            })
    }

    useEffect(() => {
        if(state == null){
            navigate("/")
        }
    }, [state])

    return (
        <div className="">
            {/* <div className="page-ath-gfx" style={{ backgroundImage: `url(${require("../assets/img/login-image3.jpg")})` }}></div> */}
            <div className="page-ath-content mx-auto">
                <div className="page-ath-form">
                    <div className="auth-head">
                        <h6>Reset Your Password</h6>
                        <p>Set your new password</p>
                    </div>
                    <Form name="basic" onFinish={submit} labelCol={{ span: 8 }} wrapperCol={{ span: 24 }} initialValues={{ remember: true }} autoComplete="off" form={form}>
                        <div className="row">
                            <div className="col-md-12">
                                <div className="form-group mb-3">
                                    <label className="label-name">New Password</label>
                                    <Form.Item
                                        name="password"
                                        rules={[{ required: true, message: 'Please enter new password!' }]}
                                    >
                                        <Input.Password
                                            className='input-control'
                                            placeholder="New Password"
                                            iconRender={(visible) => (visible ? <EyeSlash size="18" color="#bbbbbb" /> : <Eye size="18" color="#bbbbbb" />)}
                                        />
                                    </Form.Item>
                                </div>
                            </div>
                            <div className="col-md-12">
                                <div className="form-group mb40">
                                    <label className="label-name">Confirm Password</label>
                                    <Form.Item
                                        name="confirmPassword"
                                        rules={[{ required: true, message: 'Please enter your confirm password!' },
                                        ({ getFieldValue }) => ({
                                            validator(_, value) {
                                                if (!value || getFieldValue('password') === value) {
                                                    return Promise.resolve();
                                                }
                                                return Promise.reject(new Error('The confirm passwords that you entered do not match!'));
                                            },
                                        }),
                                        ]}
                                    >
                                        <Input.Password
                                            className='input-control'
                                            placeholder="Confirm Password"
                                            iconRender={(visible) => (visible ? <EyeSlash size="18" color="#bbbbbb" /> : <Eye size="18" color="#bbbbbb" />)}
                                        />
                                    </Form.Item>
                                </div>
                            </div>
                        </div>
                        <input type="submit" name="" class="button button-2 w-100" value="Set New Password" />
                    </Form>
                </div>
            </div>
        </div>
    )
}

export default ResetPassword