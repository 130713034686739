import { Input, Modal, Rate } from 'antd';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import ToastMe from '../common/ToastMe';
import eventService from '../services/events';
const { TextArea } = Input;

const FeedbackAskModal = (props) => {
    const dispatch = useDispatch();
    const { giveFeedBackModalOpen, setGiveFeedBackModalOpen, openThanksModal } = props;
    const [rating, setRating] = useState((props.data.rating));
    const [comment, setComment] = useState('');

    const handelCloseModal = () => {
        if (rating === undefined) {
            ToastMe("Please rate your experience!", 'error');
        } else {
            let data = {
                item_id: props.data._id,
                rating: Number(rating),
                category_type: props.category,
                comment: comment,
            }

            dispatch(eventService.sendFeedback(data))
                .then((res) => {
                    setGiveFeedBackModalOpen(false);
                    openThanksModal(true);
                })
                .catch(({ errorData }) => {
                    console.log({ errorData })
                })
        }
    }

    const getRating = (e) => {
        setRating(e);
    }
    return (
        <Modal
            centered
            open={giveFeedBackModalOpen}
            onOk={() => setGiveFeedBackModalOpen(false)}
            onCancel={() => setGiveFeedBackModalOpen(false)}
            footer={""}
            className='feedback_modal_wrapper'
        >
            <div className='absolute_icon_wrapper'>
                <img src={require("../../assets/images/icons/icn_rate_feedback.svg").default} alt="" loading="lazy"/>
            </div>
            <div className='card_content'>
                <h5 className='starting_heading_center_32'>
                    Give Feedback
                </h5>
                <p className='sub_heading'>Please rate your experience.</p>
                <div class="row">
                    <div class="col-md-12">
                        <div class="form-group mb-3">
                            <div className='rating_star flex_item_cs gap-2'>
                                <Rate allowHalf defaultValue={rating} onChange={(e) => getRating(e)} />
                            </div>
                        </div>
                    </div>
                    <div class="col-md-12">
                        <div class="form-group mb-3">
                            <TextArea rows={4} className='input-control' placeholder='Comment' onChange={(e) => setComment(e.target.value)} />
                        </div>
                    </div>
                </div>
                <button className='button button-2 w-100 fs18 fw600' onClick={() => handelCloseModal()}>Submit</button>
            </div>
        </Modal>
    )
}

export default FeedbackAskModal