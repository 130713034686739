import { Provider } from 'react-redux'
import store from './components/store'
import React, { useState } from 'react';
import RoutePage from './routes';
import './App.css'
import * as action from './components/store/actions'
import CookiesBanner from './components/pages/Front/banners/CookiesBanner';
import { useCookies } from 'react-cookie';
import { useEffect } from 'react';
store.dispatch(action.authCheck());

const App = () => {
	const [cookies, setCookie, removeCookie] = useCookies('');
	const [closeModel, setCloseModel] = useState();

	useEffect(() => {
		if (Object.keys(cookies).length === 0) {
			setCloseModel(true)
		} else {
			setCloseModel(false)
		}
	}, cookies)


	const AcceptCookie = () => {
		setCloseModel(false);
		setCookie('name', 'Binox')
		// setTimeout(() => {
		// 	setCloseModel(true)
		// 	removeCookie('name', '')
		// }, 100000)
	}

	return (
		<Provider store={store}>
			<CookiesBanner AcceptAllCookie={() => AcceptCookie()} handleClose={closeModel} Close={() => setCloseModel(false)} />
			<RoutePage />
		</Provider>
	)
}


export default App;
