import Http from '../../Http'
import * as action from '../../store/actions'
// import ToastMe from '../../view/common/ToastMe';
const BaseUrl = process.env.REACT_APP_BASE_URL + '/user/web/';
const url = process.env.REACT_APP_BASE_URL + '/user/';

export function getevents(data) {
    // console.log(data);
    let url = ''
    if (data != undefined) {
        if (data.latitude != undefined && data.longitude != undefined) {

            url = `events?search=` + data.search + `&latitude=` + data.latitude + `&longitude=` + data.longitude
        } else {

            url = `events?search=` + data.search
        }
    } else {
        url = `events`
    }
    return dispatch => (
        new Promise((resolve, reject) => {
            Http.callApi('get', BaseUrl + url)
                .then(function (res) {
                    return resolve(res);
                })
                .catch(function (error) {
                    console.log({ error })
                    // if (error.response.data.errors && "g_recaptcha_response" in error.response.data.errors) {
                    //     // ToastMe("Please refresh the page for Google reCAPTCHA authentication", 'danger');
                    // } else {
                    //     // ToastMe(error.response.data.message, 'danger');
                    // }
                    const data = {
                        errorData: error.response.data.errors,
                        statusCode: error.response.status,
                    };
                    return reject(data);
                })

        })
    )
}
export function getCharity(data) {
    let url = ''
    if (data != undefined) {
        url = `charity?search=` + data.search + `&latitude=` + data.latitude + `&longitude=` + data.longitude
    } else {
        url = `charity`
    }
    return dispatch => (
        new Promise((resolve, reject) => {
            Http.callApi('get', BaseUrl + url)
                .then(function (res) {
                    return resolve(res);
                })
                .catch(function (error) {
                    console.log({ error })
                    // if (error.response.data.errors && "g_recaptcha_response" in error.response.data.errors) {
                    //     // ToastMe("Please refresh the page for Google reCAPTCHA authentication", 'danger');
                    // } else {
                    //     // ToastMe(error.response.data.message, 'danger');
                    // }
                    const data = {
                        errorData: error.response.data.errors,
                        statusCode: error.response.status,
                    };
                    return reject(data);
                })

        })
    )
}
export function getMainCategory() {
    return dispatch => (
        new Promise((resolve, reject) => {
            Http.callApi('get', BaseUrl + 'getMainCategory')
                .then(function (res) {
                    // dispatch(action.setCharityDetailData(res.data));
                    return resolve(res);
                })
                .catch(function (error) {
                    const data = {
                        errorData: error.response.data.errors,
                        statusCode: error.response.status,
                    };
                    return reject(data);
                })

        })
    )
}
export function getCharityWithId(charity_id) {
    return dispatch => (
        new Promise((resolve, reject) => {
            Http.callApi('get', BaseUrl + 'getCharityById/' + charity_id)
                .then(function (res) {
                    dispatch(action.setCharityDetailData(res.data));
                    return resolve(res);
                })
                .catch(function (error) {

                    // if (error.response.data.errors && "g_recaptcha_response" in error.response.data.errors) {
                    //     // ToastMe("Please refresh the page for Google reCAPTCHA authentication", 'danger');
                    // } else {
                    //     // ToastMe(error.response.data.message, 'danger');
                    // }
                    const data = {
                        errorData: error.response.data.errors,
                        statusCode: error.response.status,
                    };
                    return reject(data);
                })

        })
    )
}
export function getEventById(id) {
    return dispatch => (
        new Promise((resolve, reject) => {
            Http.callApi('get', BaseUrl + 'getEventById/?event_id=' + id)
                .then(function (res) {
                    return resolve(res);
                })
                .catch(function (error) {
                    console.log({ error })
                    const data = {
                        errorData: error.response.data.errors,
                        statusCode: error.response.status,
                    };
                    return reject(data);
                })
        })
    )
}
export function getpopularEvents() {
    return dispatch => (
        new Promise((resolve, reject) => {
            Http.callApi('get', BaseUrl + 'events/popular')
                .then(function (res) {
                    return resolve(res);
                })
                .catch(function (error) {
                    // if (error.response.data.errors && "g_recaptcha_response" in error.response.data.errors) {
                    //     // ToastMe("Please refresh the page for Google reCAPTCHA authentication", 'danger');
                    // } else {
                    //     // ToastMe(error.response.data.message, 'danger');
                    // }
                    const data = {
                        errorData: error.response.data.errors,
                        statusCode: error.response.status,
                    };
                    return reject(data);
                })

        })
    )
}
export function getFeaturedEvents() {
    return dispatch => (
        new Promise((resolve, reject) => {
            Http.callApi('get', BaseUrl + 'events/featured')
                .then(function (res) {
                    return resolve(res);
                })
                .catch(function (error) {
                    const data = {
                        errorData: error.response.data.errors,
                        statusCode: error.response.status,
                    };
                    return reject(data);
                })

        })
    )
}
export function sendFeedback(data) {
    return dispatch => (
        new Promise((resolve, reject) => {
            Http.callApi('post', url + 'addFeedback', data)
                .then(function (res) {
                    return resolve(res);
                })
                .catch(function (error) {
                    const data = {
                        errorData: error.response.data,
                        statusCode: error.response.status,
                    };
                    return reject(data);
                })

        })
    )
}
export function charityDonation(data) {
    return dispatch => (
        new Promise((resolve, reject) => {
            Http.callApi('post', url + 'charityDonation', data)
                .then(function (res) {

                    dispatch(action.setCharityDetailData(res.data.ngo));
                    return resolve(res);
                })
                .catch(function (error) {
                    // if (error.response.data.errors && "g_recaptcha_response" in error.response.data.errors) {
                    //     // ToastMe("Please refresh the page for Google reCAPTCHA authentication", 'danger');
                    // } else {
                    //     // ToastMe(error.response.data.message, 'danger');
                    // }
                    const data = {
                        errorData: error.response.data.errors,
                        statusCode: error.response.status,
                    };
                    return reject(data);
                })

        })
    )
}
export function filterData(data) {
    return dispatch => (
        new Promise((resolve, reject) => {
            var searchUrl;
            if (data.latitude && data.longitude && data.search) {
                searchUrl = `web/filterData?distance=5` + `&search=` + data.search + `&latitude=` + data.latitude + `&longitude=` + data.longitude
            } else if (data.latitude && data.longitude) {
                searchUrl = `web/filterData?distance=5` + `&longitude=` + data.longitude + `&latitude=` + data.latitude
            } else {
                searchUrl = `web/filterData?distance=5` + `&search=` + data.search
            }
            Http.callApi('get', url + searchUrl)
                .then(function (res) {
                    return resolve(res);
                })
                .catch(function (error) {
                    const data = {
                        errorData: error.response.data,
                        statusCode: error.response.status,
                    };
                    return reject(data);
                })

        })
    )
}
export function bookEvent(data) {
    return dispatch => (
        new Promise((resolve, reject) => {
            Http.callApi('post', url + 'bookEvent', data)
                .then(function (res) {
                    return resolve(res);
                })
                .catch(function (error) {
                    const data = {
                        errorData: error.response.data,
                        statusCode: error.response.status,
                    };
                    return reject(data);
                })

        })
    )
}

export function addSubscriptions(data) {
    return dispatch => (
        new Promise((resolve, reject) => {
            Http.callApi('post', BaseUrl + 'addSubscrption', data)
                .then(function (res) {
                    return resolve(res);
                })
                .catch(function (error) {
                    const data = {
                        errorData: error.response.data,
                        statusCode: error.response.status,
                    };
                    return reject(data);
                })
        })
    )
}

export function getFaq() {
    return dispatch => (
        new Promise((resolve, reject) => {
            Http.callApi('get', BaseUrl + 'faq')
                .then(function (res) {
                    return resolve(res);
                })
                .catch(function (error) {
                    const data = {
                        errorData: error.response.data.errors,
                        statusCode: error.response.status,
                    };
                    return reject(data);
                })

        })
    )
}