import { Button, Modal } from 'antd';
import React from 'react'

const PaymentSuccessfulCharityModal = (props) => {
    const { paymentSuccessModalOpen, setPaymentSuccessModalOpen, billDetails } = props;
    return (
        <Modal
            centered
            open={paymentSuccessModalOpen}
            onOk={() => setPaymentSuccessModalOpen(false)}
            onCancel={() => setPaymentSuccessModalOpen(false)}
            footer={""}
            className='payment_successful_modal_wrapper'
        >
            <div className='card_content'>
                <div className='circular_image_wrapper'>
                    <img src={require("../../../../assets/images/payment-success.png")} className="img-fluid" alt="" />
                </div>
                <h5 className='payment_status_title'>
                    Payment Successful
                </h5>
               
                <div className='sidebar_wrapper'>
                    <div className="sidebar_widget">
                        <h5 className='mb-4 fs18 fw600 lh_1_5'>Donation Details</h5>
                        <div className="billtotal_table_wrapper">
                            <table className="table mb-0">
                                <tbody>
                                    <tr>
                                        <th>Total</th>
                                        <td>£{billDetails ? billDetails?.amount?.toFixed(2) : 0}</td>
                                    </tr>
                                   
                                </tbody>
                                <tfoot>
                                    <tr>
                                        <th>TOTAL</th>
                                        <td>£{billDetails ? billDetails?.amount?.toFixed(2) : 0}</td>
                                    </tr>
                                </tfoot>
                            </table>
                        </div>
                    </div>
                </div>
                <button className='button button-2 w-100 fs18 fw600' onClick={() => { props.onClickBtn(); setPaymentSuccessModalOpen(false) }}>
                    Done
                </button>
            </div>
        </Modal>
    )
}

export default PaymentSuccessfulCharityModal