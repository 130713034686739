import React, { createContext, useEffect, useState } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import Footer from '../master/Footer';
import AuthHeader from './AuthHeader';
import OrderFooter from '../orderLayout/OrderFooter';

export const authMainContext = createContext(null)

const AuthMaster = (props) => {

    return (
        <>
            <authMainContext.Provider>
                {/* <PageLoader loading={loading} /> */}
                <div className="auth-wrapper">
                    <AuthHeader/>
                    <div className="auth-content-section">
                        <Outlet />
                    </div>
                    <OrderFooter/>
                </div>
            </authMainContext.Provider>
        </>
    )

}

export default AuthMaster;
