import React, { useEffect, useState } from 'react';
import { Location, Clock } from 'iconsax-react';
import Slider from 'react-slick';
import { Link, useNavigate } from 'react-router-dom';
import eventService from '../../../services/events';
import { connect, useDispatch } from 'react-redux';
import moment from 'moment';
import { Button, Empty, Input } from 'antd';
import DefaultImage from '../../../../assets/images/Event.svg';
import PageLoader from '../../../common/PageLoader';
const BaseUrl = process.env.REACT_APP_PROFILE_URL;

const Events = (props) => {
  const searchDatas = props?.searchData;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [events, setEvents] = useState([]);
  const [popularEvents, setPopularEvents] = useState([]);
  const [featuredEvents, setFeaturedEvents] = useState([]);
  const [flag, setFlag] = useState();
  const [search, setSearch] = useState("");
  // const [filterDataNew, setfilterDataNew] = useState([]);
  const [loading, setLoding] = useState(true);
  const [eventLength, setEventLength] = useState(6);

  function getWidthCont(params) {
    let containerOutWidth = document.getElementsByClassName('container');
    containerOutWidth = containerOutWidth[0].clientWidth

    let mainWidth = window.outerWidth
    mainWidth = ((mainWidth - containerOutWidth) / 2)

    let allSlider = document.querySelectorAll('.events_details_cards_wrapper .slick-track');
    allSlider.forEach(element => {
      element.style.paddingRight = `${mainWidth}px`
    });
  }

  useEffect(() => {
    getWidthCont()
    window.addEventListener('resize', (e) => {
      getWidthCont()
    })
  }, [])

  const getevents = () => {
    dispatch(eventService.getevents())
      .then((res) => {
        setEvents(res.data);
        setLoding(false);
      })
      .catch((errors) => {
        console.log({ errors })
      })
  }

  const getpopularEvents = () => {
    dispatch(eventService.getpopularEvents())
      .then((res) => {
        setPopularEvents(res.data)
        setLoding(false);
      })
      .catch((errors) => {
        console.log({ errors })
      })
  }
  const getFeaturedEvents = () => {
    dispatch(eventService.getFeaturedEvents())
      .then((res) => {
        setFeaturedEvents(res.data)
        setLoding(false);
      })
      .catch((errors) => {
        console.log({ errors })
      })
  }

  const viewAll = (flag) => {
    if (flag === 'all') {
      setFlag('1');
      setEventLength(events.length)
      const section = document.getElementById("near_you");
      if (section) {
        window.scrollTo({
          top: section.offsetTop + 450,
          behavior: 'smooth'
        });
      }
    } else {
      setEventLength(6)
      setFlag('2');
    }
  }

  const EventDeatils = (data) => {
    dispatch(eventService.getEventById(data._id))
      .then((res) => {
        navigate("/event-details", { state: res.data })
      })
      .catch((errors) => {
        console.log({ errors })
      })
  };

  useEffect(() => {
    if (searchDatas === undefined) {
      getevents()
    } else {
      setEvents(searchDatas?.event)
    }
    getpopularEvents()
    getFeaturedEvents()
  }, [searchDatas])

  const offerSliderSetting = {
    slidesToShow: 2,
    slidesToScroll: 1,
    infinite: false,
    arrows: false,
    dots: true,
    responsive: [
      {
        breakpoint: 1921,
        settings: {
          slidesToShow: 2
        }
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 2
        }
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1
        }
      },
      {
        breakpoint: 575,
        settings: {
          slidesToShow: 1
        }
      }
    ]
  };

  function nearYou(e) {
    const section = document.getElementById("near_you");
    if (section) {
      window.scrollTo({
        top: section.offsetTop,
        behavior: 'smooth'
      });
    }
  }
  const filterData = (e) => {
    if (e) {

      setSearch(e.target.value)
    }
    let data = {
      // type:1,
      search: search,
    }
    dispatch(eventService.getevents(data))
      .then((res) => {
        setEvents(res.data)
      })
      .catch((errors) => {
        console.log({ errors })
      })
  }

  return (
    <>
      <PageLoader loading={loading} />
      <section className='event_starting_banner gap'>
        <div className="container">
          <div className="banner_content">
            <div className="row">
              <div className="col-xl-5 col-lg-7 col-md-10">
                <h2 className='fs48 fw600 text-white mb-3 lh_1_3'> <span className='text_orange'> Specially </span> curated events <span className='text_orange'> for you</span></h2>
                <p className='fs16 fw400 text-white mb-4'>Check out this weeks “must not miss” events around</p>
                <div className="search_item_form">
                  <div className="form-group mb-0">
                    <Input
                      onChange={(e) => filterData(e)}
                      placeholder="Search your Events" suffix={<Button className="button button-2" onClick={e => nearYou(e)}><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M21.7043 20.3458L17.6523 16.2938L17.7726 16.1365C19.1289 14.3677 19.7625 12.1495 19.545 9.93115C19.3276 7.71277 18.2753 5.6599 16.6012 4.18814C14.9272 2.71638 12.7564 1.93569 10.5285 2.00415C8.30049 2.07261 6.18178 2.9851 4.60124 4.55686C3.0207 6.12861 2.09642 8.24221 2.01556 10.4698C1.93469 12.6973 2.70326 14.8724 4.16567 16.5546C5.62808 18.2368 7.67505 19.3005 9.89219 19.5304C12.1093 19.7602 14.331 19.139 16.1074 17.7925L16.2739 17.6722L20.3259 21.7242C20.5117 21.9013 20.7585 22 21.0151 22C21.2717 22 21.5185 21.9013 21.7043 21.7242C21.7952 21.6339 21.8673 21.5266 21.9166 21.4083C21.9658 21.29 21.9912 21.1631 21.9912 21.035C21.9912 20.9069 21.9658 20.78 21.9166 20.6617C21.8673 20.5434 21.7952 20.4361 21.7043 20.3458V20.3458ZM15.6263 15.6462C14.516 16.7619 13.0531 17.4579 11.4869 17.6155C9.92084 17.773 8.34859 17.3823 7.03836 16.5101C5.72813 15.6378 4.76112 14.338 4.30223 12.8324C3.84334 11.3267 3.92102 9.70849 4.52202 8.25373C5.12302 6.79897 6.21011 5.59779 7.59787 4.85504C8.98562 4.1123 10.5881 3.87403 12.1319 4.18086C13.6757 4.48768 15.0653 5.3206 16.0635 6.53755C17.0618 7.7545 17.607 9.28011 17.606 10.8541C17.607 12.6507 16.8949 14.3742 15.6263 15.6462V15.6462Z" fill="#333030" />
                      </svg>
                      </Button>} className="form-control" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className='section featured_event gap60'>
        <div className="container">
          <div className="section_title">
            <h2 className='text-center'>Featured Events</h2>
          </div>
          <div className="featured_event_content">
            <div className="row h-100 gy-4">
              <div className="col-xl-8 col-lg-7 h-100">
                <div className="left_side h-100">
                  <div className="featured_event_card h-100 bg-fit" style={{ backgroundImage: `url(${featuredEvents[0]?.image ? BaseUrl + 'eventImage/' + featuredEvents[0]?.image : DefaultImage})` }}>
                    <div className="text_content w-100">
                      <span className='event_date mb-3 d-inline-block'>
                        {moment(featuredEvents[0]?.time).format('DD MMM YYYY')}
                      </span>
                      <div className="flex_item_cs gap-3">
                        <div className="flex-grow-1">
                          <h3 className='fs32 fw600 text-white lh_1_3'>{featuredEvents[0]?.name}</h3>
                          <p className='fs16 fw500 text-white'>{featuredEvents[0]?.city}</p>
                        </div>
                        <p className='flex-shrink-0 event_cost d-inline-block'>
                          <span> <del>{featuredEvents[0]?.price.toFixed(2)}</del> </span>
                          {featuredEvents[0]?.offerPrice.toFixed(2)}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-4 col-lg-5 h-100">
                <div className="right_side h-100">
                  <div className="featured_event_card sm bg-fit" style={{ backgroundImage: `url(${featuredEvents[1]?.image ? BaseUrl + 'eventImage/' + featuredEvents[1]?.image : DefaultImage})` }}>
                    <div className="text_content w-100">
                      <span className='event_date mb-3 d-inline-block'>
                        {moment(featuredEvents[1]?.time).format('DD MMM YYYY')}
                      </span>
                      <div className="flex_item_cs gap-3">
                        <div className="flex-grow-1">
                          <h3 className='fs24 fw600 text-white lh_1_3'>{featuredEvents[1]?.name}</h3>
                        </div>
                        <p className='flex-shrink-0 event_cost d-inline-block sm'>
                          <span> <del>{featuredEvents[1]?.offerPrice.toFixed(1)}</del> </span>
                          {featuredEvents[1]?.price.toFixed(1)}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="featured_event_card sm bg-fit" style={{ backgroundImage: `url(${featuredEvents[2]?.image ? BaseUrl + 'eventImage/' + featuredEvents[2]?.image : DefaultImage})` }}>
                    <div className="text_content w-100">
                      <span className='event_date mb-3 d-inline-block'>

                        {moment(featuredEvents[2]?.time).format('DD MMM YYYY')}

                      </span>
                      <div className="flex_item_cs gap-3">
                        <div className="flex-grow-1">
                          <h3 className='fs24 fw600 text-white lh_1_3'>{featuredEvents[2]?.name}</h3>
                        </div>
                        <p className='flex-shrink-0 event_cost d-inline-block sm'>
                          <span> <del>{featuredEvents[2]?.offerPrice.toFixed(2)}</del> </span>
                          {featuredEvents[2]?.offerPrice.toFixed(2)}

                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className='events_happening_near_you gap50 bg_lightgray' id="near_you">
        <div className='container'>
          <div className='section_title'>
            <div className="flex_item_cb flex-wrap gap-2">
              <h2>Happening near you</h2>
              {events?.length != 0 ?
                flag === '1'
                  ? <a style={{ cursor: 'pointer' }} onClick={(e) => viewAll('less')}>View Less</a>
                  : <a style={{ cursor: 'pointer' }} onClick={(e) => viewAll('all')}>View All</a> : ''}
            </div>
          </div>
          <div className='events_details_cards_wrapper'>
            <div className='row gy-4'>
              {events && events.length > 0 ?
                events.slice(0, eventLength).map((event, index) => {
                  return <div className='col-md-4' key={index}>
                    <div className='individual_card'>
                      <div className='img_wrapper'>
                        {/* <img style={{ cursor: 'pointer' }} src={require("../../../../assets/images/event1.png")} className="img-fluid" alt="" /> */}
                        <img style={{ cursor: 'pointer' }} onClick={() => { EventDeatils(event) }} src={event.image ? BaseUrl + 'eventImage/' + event.image : DefaultImage} className="img-fluid" alt="" />

                      </div>
                      <div className='text-content'>
                        <div className='main_text'>
                          <p className='flex_item_cs mb-3 subtext lh_1'><span><svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M6.66602 0.833984C7.12625 0.833984 7.49935 1.20708 7.49935 1.66732V2.08398H12.4993V1.66732C12.4993 1.20708 12.8724 0.833984 13.3327 0.833984C13.7929 0.833984 14.166 1.20708 14.166 1.66732V2.1285C15.3974 2.26545 16.3987 2.71883 17.1204 3.49611C17.9884 4.43081 18.3327 5.70624 18.3327 7.08398V14.1673C18.3327 15.5451 17.9884 16.8205 17.1204 17.7552C16.2421 18.7011 14.9498 19.1673 13.3327 19.1673H6.66602C5.04885 19.1673 3.75659 18.7011 2.87827 17.7552C2.01033 16.8205 1.66602 15.5451 1.66602 14.1673V7.08398C1.66602 5.70624 2.01033 4.43081 2.87827 3.49611C3.60003 2.71883 4.6013 2.26545 5.83268 2.1285V1.66732C5.83268 1.20708 6.20578 0.833984 6.66602 0.833984ZM5.83268 3.80843C5.0126 3.93094 4.46378 4.238 4.09959 4.63019C3.66419 5.09909 3.39361 5.79593 3.3418 6.74232H16.6569C16.6051 5.79593 16.3345 5.09909 15.8991 4.63019C15.5349 4.238 14.9861 3.93094 14.166 3.80843V4.16732C14.166 4.62755 13.7929 5.00065 13.3327 5.00065C12.8724 5.00065 12.4993 4.62755 12.4993 4.16732V3.75065H7.49935V4.16732C7.49935 4.62755 7.12625 5.00065 6.66602 5.00065C6.20578 5.00065 5.83268 4.62755 5.83268 4.16732V3.80843ZM16.666 8.40898H3.33268V14.1673C3.33268 15.2896 3.61337 16.0975 4.09959 16.6211C4.57544 17.1336 5.36652 17.5006 6.66602 17.5006H13.3327C14.6322 17.5006 15.4233 17.1336 15.8991 16.6211C16.3853 16.0975 16.666 15.2896 16.666 14.1673V8.40898Z" fill="#333230" />
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M5.80078 11.0486C5.80078 10.435 6.29824 9.9375 6.91189 9.9375H6.92023C7.53388 9.9375 8.03134 10.435 8.03134 11.0486C8.03134 11.6623 7.53388 12.1597 6.92023 12.1597H6.91189C6.29824 12.1597 5.80078 11.6623 5.80078 11.0486ZM8.88495 11.0486C8.88495 10.435 9.38241 9.9375 9.99606 9.9375H10.0044C10.618 9.9375 11.1155 10.435 11.1155 11.0486C11.1155 11.6623 10.618 12.1597 10.0044 12.1597H9.99606C9.38241 12.1597 8.88495 11.6623 8.88495 11.0486ZM11.9683 11.0486C11.9683 10.435 12.4657 9.9375 13.0794 9.9375H13.0869C13.7005 9.9375 14.198 10.435 14.198 11.0486C14.198 11.6623 13.7005 12.1597 13.0869 12.1597H13.0794C12.4657 12.1597 11.9683 11.6623 11.9683 11.0486ZM5.80078 14.3517C5.80078 13.7381 6.29824 13.2406 6.91189 13.2406H6.92023C7.53388 13.2406 8.03134 13.7381 8.03134 14.3517C8.03134 14.9654 7.53388 15.4629 6.92023 15.4629H6.91189C6.29824 15.4629 5.80078 14.9654 5.80078 14.3517ZM8.88495 14.3517C8.88495 13.7381 9.38241 13.2406 9.99606 13.2406H10.0044C10.618 13.2406 11.1155 13.7381 11.1155 14.3517C11.1155 14.9654 10.618 15.4629 10.0044 15.4629H9.99606C9.38241 15.4629 8.88495 14.9654 8.88495 14.3517ZM11.9683 14.3517C11.9683 13.7381 12.4657 13.2406 13.0794 13.2406H13.0869C13.7005 13.2406 14.198 13.7381 14.198 14.3517C14.198 14.9654 13.7005 15.4629 13.0869 15.4629H13.0794C12.4657 15.4629 11.9683 14.9654 11.9683 14.3517Z" fill="#333230" />
                          </svg>
                          </span>
                            <span>
                              {moment(event?.time).format('DD MMM YYYY')}
                            </span>
                          </p>
                          {/* <h4 style={{ cursor: 'pointer' }}>{data.name}</h4> */}
                          <h4 style={{ cursor: 'pointer' }} className='title mb-3' >
                            {event.name}
                          </h4>
                          <Link onClick={() => { EventDeatils(event) }} className='button button-2 button-outlined'>Read More</Link>
                        </div>
                      </div>
                    </div>
                  </div>
                })
                : (
                  <div>
                    <Empty
                      description={<p>Not Found</p>}
                      image={Empty.PRESENTED_IMAGE_SIMPLE}
                    />
                  </div>
                )
              }

              {/* {
                events?.length != 0 ?
                  (flag && flag === '1' ? events : events?.slice(0, 4)).map((data, index) => {
                    return <div className='col-md-6' key={index}>
                      <div className='individual_card'>
                        <div className='img_wrapper'>
                          <img style={{ cursor: 'pointer' }} onClick={(id) => { EventDeatils(data) }} src={data.image ? BaseUrl + 'eventImage/' + data.image : DefaultImage} className="img-fluid" alt="" />
                        </div>
                        <div className='text-content'>
                          <div className='main_text'>
                            <p className='flex_item_cs'><span><svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path fill-rule="evenodd" clip-rule="evenodd" d="M6.66602 0.833984C7.12625 0.833984 7.49935 1.20708 7.49935 1.66732V2.08398H12.4993V1.66732C12.4993 1.20708 12.8724 0.833984 13.3327 0.833984C13.7929 0.833984 14.166 1.20708 14.166 1.66732V2.1285C15.3974 2.26545 16.3987 2.71883 17.1204 3.49611C17.9884 4.43081 18.3327 5.70624 18.3327 7.08398V14.1673C18.3327 15.5451 17.9884 16.8205 17.1204 17.7552C16.2421 18.7011 14.9498 19.1673 13.3327 19.1673H6.66602C5.04885 19.1673 3.75659 18.7011 2.87827 17.7552C2.01033 16.8205 1.66602 15.5451 1.66602 14.1673V7.08398C1.66602 5.70624 2.01033 4.43081 2.87827 3.49611C3.60003 2.71883 4.6013 2.26545 5.83268 2.1285V1.66732C5.83268 1.20708 6.20578 0.833984 6.66602 0.833984ZM5.83268 3.80843C5.0126 3.93094 4.46378 4.238 4.09959 4.63019C3.66419 5.09909 3.39361 5.79593 3.3418 6.74232H16.6569C16.6051 5.79593 16.3345 5.09909 15.8991 4.63019C15.5349 4.238 14.9861 3.93094 14.166 3.80843V4.16732C14.166 4.62755 13.7929 5.00065 13.3327 5.00065C12.8724 5.00065 12.4993 4.62755 12.4993 4.16732V3.75065H7.49935V4.16732C7.49935 4.62755 7.12625 5.00065 6.66602 5.00065C6.20578 5.00065 5.83268 4.62755 5.83268 4.16732V3.80843ZM16.666 8.40898H3.33268V14.1673C3.33268 15.2896 3.61337 16.0975 4.09959 16.6211C4.57544 17.1336 5.36652 17.5006 6.66602 17.5006H13.3327C14.6322 17.5006 15.4233 17.1336 15.8991 16.6211C16.3853 16.0975 16.666 15.2896 16.666 14.1673V8.40898Z" fill="#333230" />
                              <path fill-rule="evenodd" clip-rule="evenodd" d="M5.80078 11.0486C5.80078 10.435 6.29824 9.9375 6.91189 9.9375H6.92023C7.53388 9.9375 8.03134 10.435 8.03134 11.0486C8.03134 11.6623 7.53388 12.1597 6.92023 12.1597H6.91189C6.29824 12.1597 5.80078 11.6623 5.80078 11.0486ZM8.88495 11.0486C8.88495 10.435 9.38241 9.9375 9.99606 9.9375H10.0044C10.618 9.9375 11.1155 10.435 11.1155 11.0486C11.1155 11.6623 10.618 12.1597 10.0044 12.1597H9.99606C9.38241 12.1597 8.88495 11.6623 8.88495 11.0486ZM11.9683 11.0486C11.9683 10.435 12.4657 9.9375 13.0794 9.9375H13.0869C13.7005 9.9375 14.198 10.435 14.198 11.0486C14.198 11.6623 13.7005 12.1597 13.0869 12.1597H13.0794C12.4657 12.1597 11.9683 11.6623 11.9683 11.0486ZM5.80078 14.3517C5.80078 13.7381 6.29824 13.2406 6.91189 13.2406H6.92023C7.53388 13.2406 8.03134 13.7381 8.03134 14.3517C8.03134 14.9654 7.53388 15.4629 6.92023 15.4629H6.91189C6.29824 15.4629 5.80078 14.9654 5.80078 14.3517ZM8.88495 14.3517C8.88495 13.7381 9.38241 13.2406 9.99606 13.2406H10.0044C10.618 13.2406 11.1155 13.7381 11.1155 14.3517C11.1155 14.9654 10.618 15.4629 10.0044 15.4629H9.99606C9.38241 15.4629 8.88495 14.9654 8.88495 14.3517ZM11.9683 14.3517C11.9683 13.7381 12.4657 13.2406 13.0794 13.2406H13.0869C13.7005 13.2406 14.198 13.7381 14.198 14.3517C14.198 14.9654 13.7005 15.4629 13.0869 15.4629H13.0794C12.4657 15.4629 11.9683 14.9654 11.9683 14.3517Z" fill="#333230" />
                            </svg>
                            </span>25 March 2023</p>
                            <h4 style={{ cursor: 'pointer' }} onClick={(id) => { EventDeatils(data) }}>{data.name}</h4>
                            <h4 style={{ cursor: 'pointer' }} onClick={(id) => { EventDeatils(data) }}>
                              impact of Extrinsic Motivation on
                              Intrinsic Motivation
                            </h4>
                            <Link to={"/event-details"} className='button button-2 btn-outlined'>
                              Read More
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  })
                  :
                  <div>
                    <Empty description={<p>Events Not Found</p>} image={Empty.PRESENTED_IMAGE_SIMPLE} />
                  </div>
              } */}
            </div>
          </div>
        </div>
      </section>
      {/* <section className='popular_events gap50'>
        <div className='container'>
          <div className='section_title'>
            <div className="flex_item_cb flex-wrap gap-2">
              <h2>Popular Event</h2>
              {popularEvents != 4 ? flag === '2' ? '' : <a style={{ cursor: 'pointer' }} onClick={(e) => viewAll('popular')}>View All</a> : ''}
            </div>
          </div>
        </div>
        <div className='custom_container'>
          <div className='events_details_cards_wrapper'>
            {
              flag === '2' ?
                <div className='row gy-4'>
                  {popularEvents && popularEvents.length > 0 ?
                    popularEvents?.map((data, index) => {
                      return (
                        <div className='col-md-6' key={index}>
                          <div className='individual_card'>
                            <div className='img_wrapper'>
                              <img style={{ cursor: 'pointer' }} onClick={() => { EventDeatils(data) }} src={data.image ? BaseUrl + 'eventImage/' + data.image : DefaultImage} className="img-fluid" alt="" />
                              <div className='event_date_absolute'>
                                <span className='numeric_value'> {moment(data.time).format("D")}</span>
                                <span> {moment(data.time).format("MMM")}</span>
                              </div>
                            </div>
                            <div className='text-content'>
                              <div className='main_text'>
                                <Link onClick={() => { EventDeatils(data) }}><h4>{data.name}</h4></Link>
                                <p>
                                  <span>
                                    <Clock size="18" color="#818181" />
                                    {moment(data.time).format('h:mm a')}
                                  </span>
                                  <span>
                                    <Location
                                      size="18"
                                      color="#818181"
                                    />
                                    {data.city}
                                  </span>
                                </p>
                              </div>
                              <div className='event_price_numaric'>
                                <span>
                                  <span className='unvalide_price'><del>£{(data.price.toFixed(2))}</del></span>
                                  £{(data.offerPrice).toFixed(2)}
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      )
                    })
                    :
                    <Empty description={<p>Popular Events Not Found</p>} image={Empty.PRESENTED_IMAGE_SIMPLE} />
                  }
                </div> :
                <div className='slider-wrapper'>
                  <Slider {...offerSliderSetting} className="slide_gap30 custom_dots slider_flex">
                    {popularEvents && popularEvents.length > 0 ?
                      popularEvents?.slice(0, 4).map((data, index) => {
                        return (
                          <div className='individual_card' key={index}>
                            <div className='img_wrapper'>
                              <img style={{ cursor: 'pointer' }} onClick={() => { EventDeatils(data) }} src={data.image ? BaseUrl + 'eventImage/' + data.image : DefaultImage} className="img-fluid" alt="" />
                              <div className='event_date_absolute'>
                                <span className='numeric_value'> {moment(data.time).format("D")}</span>
                                <span> {moment(data.time).format("MMM")}</span>
                              </div>
                            </div>
                            <div className='text-content gap-3'>
                              <div className='main_text flex-grow-1'>
                                <Link onClick={() => { EventDeatils(data) }}><h4>{data.name}</h4></Link>
                                <p>
                                  <span>
                                    <Clock size="18" color="#818181" />
                                    {moment(data.time).format('h:mm a')}
                                  </span>
                                  <span>
                                    <Location
                                      size="18"
                                      color="#818181"
                                    />
                                    {data.city}
                                  </span>
                                </p>
                              </div>
                              <div className='event_price_numaric'>
                                <span>
                                  <span className='unvalide_price'><del>£{(data?.price?.toFixed(2))}</del></span>
                                  £{(data.offerPrice).toFixed(2)}
                                </span>
                              </div>
                            </div>
                          </div>
                        )
                      })
                      :
                      <Empty description={<p>Popular Events Not Found</p>} image={Empty.PRESENTED_IMAGE_SIMPLE} />
                    }
                  </Slider>
                </div>
            }
          </div>
        </div>
      </section> */}
    </>
  )
}

const mapStateToProps = state => {
  return {
    searchData: state.Auth.searchData
  }
};

export default connect(mapStateToProps)(Events);