export const AUTH_VERIFY_STATUS = 'AUTH_VERIFY_STATUS';
export const AUTH_LOGIN = 'AUTH_LOGIN';
export const AUTH_CHECK = 'AUTH_CHECK';
export const AUTH_LOGOUT = 'AUTH_LOGOUT';
export const AUTH_VERIFY = 'AUTH_VERIFY';
export const AUTH_FORGOT = 'AUTH_FORGOT';
export const CHANGE_MOBILE = 'CHANGE_MOBILE';
export const SET_CARD = 'SET_CARD';
export const SET_NOTIFICATIONDATA = 'SET_NOTIFICATIONDATA';
export const SET_USERDATA = 'SET_USERDATA';
export const SET_CARTDATA = 'SET_CARTDATA';
export const SET_SEARCHDATA = 'SET_SEARCHDATA';
export const SET_CHARITY_DATA = 'SET_CHARITY_DATA';
// export const SET_LAT = 'SET_LAT';
// export const SET_LONG = 'SET_LONG';
// export const SET_STRIPEKEY = 'SET_STRIPEKEY';