import React from 'react';
import { useDispatch } from 'react-redux';
import { Form, Input } from 'antd';
import authService from '../../../services/auth';
import ToastMe from "../../../common/ToastMe";
import { Eye, EyeSlash } from 'iconsax-react';

const Profile = (props) => {
    const dispatch = useDispatch();
    const [form] = Form.useForm();

    const onFinish = (data) => {
        dispatch(authService.changePassword(data))
            .then((res) => {
                ToastMe("Password Change Successfully", 'success')
                form.resetFields();
            })
            .catch(({errorData}) => {
                ToastMe(errorData.message, 'error')
                if(errorData.errors.new_password === 'The new password must be different from old password.'){
                    form.setFields([{ name: 'new_password', errors: [errorData.errors['new_password']] }])
                } else if (errorData.errors['new_password']) {
                    form.setFields([{ name: 'new_password', errors: ['Password should be at least 8 characters in length and should include at least one upper case letter, lower case letter, one number, and one special character.'] }])
                } else {
                    ToastMe(errorData.message, 'error')
                }
            })
    };

    return (
        <>
            <section className="section profile_page_wrapper gap50">
                <div className="container">
                    <div className="page-ath-content mx-auto">
                        <div className="page-ath-form">
                            <div className="auth-head">
                                <h6>Change Password</h6>
                                <p>Set your new password</p>
                            </div>
                            <Form name="basic" labelCol={{ span: 8 }} wrapperCol={{ span: 24 }} initialValues={{ remember: true }} autoComplete="off" form={form} onFinish={onFinish}>
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="form-group mb-3">
                                            <label className="label-name">Current Password</label>
                                            <Form.Item
                                                name="old_password"
                                                rules={[{ required: true, message: 'Please enter your password!' }]}
                                            >
                                                <Input.Password
                                                    className='input-control'
                                                    placeholder="Current Password"
                                                    iconRender={(visible) => (visible ? <Eye size="18" color="#bbbbbb" /> : <EyeSlash size="18" color="#bbbbbb" />)}
                                                />
                                            </Form.Item>
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        <div className="form-group mb-3">
                                            <label className="label-name">New Password</label>
                                            <Form.Item
                                                name="new_password"
                                                rules={[{ required: true, message: 'Please enter your new password!' }]}
                                            >
                                                <Input.Password
                                                    className='input-control'
                                                    placeholder="New Password"
                                                    iconRender={(visible) => (visible ? <Eye size="18" color="#bbbbbb" /> : <EyeSlash size="18" color="#bbbbbb" />)}
                                                />
                                            </Form.Item>
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        <div className="form-group mb-3">
                                            <label className="label-name">Confirm Password</label>
                                            <Form.Item
                                                name="diffrent"
                                                dependencies={['new_password']}
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'Please enter confirm your password!',
                                                    },
                                                    ({ getFieldValue }) => ({
                                                        validator(_, value) {
                                                            if (!value || getFieldValue('new_password') === value) {
                                                                return Promise.resolve();
                                                            }
                                                            return Promise.reject(new Error('The confirm passwords that you entered do not match!'));
                                                        },
                                                    }),
                                                ]}
                                            >
                                                <Input.Password
                                                    className='input-control'
                                                    placeholder="Confirm Password"
                                                    iconRender={(visible) => (visible ? <Eye size="18" color="#bbbbbb" /> : <EyeSlash size="18" color="#bbbbbb" />)}
                                                />
                                            </Form.Item>
                                        </div>
                                    </div>
                                </div>
                                <input type="submit" name="" class="button button-2 w-100" value="Submit" />
                            </Form>
                        </div>
                    </div>
                    {/* <Form
                                    name="basic"
                                    form={form}
                                    labelCol={{ span: 8 }}
                                    wrapperCol={{ span: 16 }}
                                    style={{ maxWidth: 600 }}
                                    initialValues={{ remember: true }}
                                    onFinish={onFinish}
                                    autoComplete="off"
                                >
                                    <Form.Item
                                        label="Current Password"
                                        name="old_password"
                                        rules={[{ required: true, message: 'Please enter your password!' }]}
                                    >
                                        <Input.Password />
                                    </Form.Item>

                                    <Form.Item
                                        label="New Password"
                                        name="new_password"
                                        rules={[{ required: true, message: 'Please enter your new password!' }]}
                                    >
                                        <Input.Password />
                                    </Form.Item>

                                    <Form.Item
                                        label="Confirm Password"
                                        name="diffrent"
                                        dependencies={['new_password']}
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Please confirm your password!',
                                            },
                                            ({ getFieldValue }) => ({
                                                validator(_, value) {
                                                    if (!value || getFieldValue('new_password') === value) {
                                                        return Promise.resolve();
                                                    }
                                                    return Promise.reject(new Error('The confirm passwords that you entered do not match!'));
                                                },
                                            }),
                                        ]}
                                    >
                                        <Input.Password />
                                    </Form.Item>
                                    <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                                        <Button type="primary" htmlType="submit">
                                            Submit
                                        </Button>
                                    </Form.Item>
                                </Form> */}
                </div>
            </section>
        </>
    )
}

export default Profile;