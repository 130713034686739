import React from 'react'

const CookiesBanner = (props) => {
  
  return (
    <>
      {
        props.handleClose === true ?
          <div className='cookies_banner_wrapper'>
            <div className='cookies_content'>
              <h4 className='fs20 text-dark fw600 mb-3 lh-1'>
                This Site Use Cookies
              </h4>
              <p className='fs14 fw-500 text-dark lh_1_5 mb-4'>
                Cookies are stored on your device locally to free up storage space on a website’s servers. In turn, websites can personalize while saving money on server maintenance and storage costs.
              </p>
              <div className='buttons_wrapper'>
                <div className='button_outside'>
                  <button className='button button-2' onClick={() => props.Close()}>
                    {/* Manage Cookies */}
                    Close
                  </button>
                </div>
                <div className='button_outside'>
                  <button className='button button-2' onClick={() => props.AcceptAllCookie()} >
                    Accept All
                  </button>
                </div>
              </div>
              <div className='cookies_gif_absolute'>
                <img src={require("../../../../assets/img/cookiespolicy_transparent.gif")} alt="" />
              </div>
            </div>
          </div>
          : ''
      }
    </>
  )
}

export default CookiesBanner