
const apiData = [
    {
        shopId : "shop1",
        shopLike : "",
        shopRate: "4.4",
        shopCategory : "Restaurants",
        shopType: "foods",
        shopImg : "product_img1.jpg",
        shopName : "The Glorious Restaurant", 
        shopDesc : "North Indian, Chinese, Itali...",
        shopLocation : "l.s Road 5.3 Km",
    },
    {
        shopId : "shop2",
        shopLike : "",
        shopRate: "5.0",
        shopCategory : "Restaurants",
        shopType: "foods",
        shopImg : "product_img2.jpg",
        shopName : "Gujarati Test", 
        shopDesc : "Panir Tadka, Salad, Itali...",
        shopLocation : "l.s Road 5.3 Km",
    },
    {
        shopId : "shop3",
        shopLike : "",
        shopRate: "3.7",
        shopCategory : "Restaurants",
        shopType: "foods",
        shopImg : "product_img3.jpg",
        shopName : "M.P Paratha & Omlet", 
        shopDesc : "Street Food, North Indian..",
        shopLocation : "l.s Road 5.3 Km",
    },
    {
        shopId : "shop4",
        shopLike : "",
        shopRate: "4.4",
        shopCategory : "Restaurants",
        shopType: "foods",
        shopImg : "product_img4.jpg",
        shopName : "The Milano Pizza", 
        shopDesc : "Garden chilli, Italian Pizza...",
        shopLocation : "l.s Road 5.3 Km",
    },
    {
        shopId : "shop5",
        shopLike : "",
        shopRate: "5.0",
        shopCategory : "Restaurants",
        shopType: "foods",
        shopImg : "product_img5.jpg",
        shopName : "Burger King", 
        shopDesc : "Burger & Wraps, Sides.. ",
        shopLocation : "l.s Road 5.3 Km",
    },
    {
        shopId : "shop6",
        shopLike : "",
        shopRate: "3.7",
        shopCategory : "Restaurants",
        shopType: "foods",
        shopImg : "product_img6.jpg",
        shopName : "La Pino’z Pizza", 
        shopDesc : "Pizza, Fast Food, Beverang...",
        shopLocation : "l.s Road 5.3 Km",
    },
    {
        shopId : "shop7",
        shopLike : "",
        shopRate: "4.4",
        shopCategory : "Groceries",
        shopType: "Groceries",
        shopImg : "product_img7.jpg",
        shopName : "The Bingo Shop", 
        shopDesc : "Sugar, Surf, Ariel...",
        shopLocation : "l.s Road 5.3 Km",
    },
    {
        shopId : "shop8",
        shopLike : "",
        shopRate: "5.0",
        shopCategory : "Groceries",
        shopType: "Groceries",
        shopImg : "product_img8.jpg",
        shopName : "FreshFrout", 
        shopDesc : "Apple, Banana, Mango... ",
        shopLocation : "l.s Road 5.3 Km",
    },
    {
        shopId : "shop9",
        shopLike : "",
        shopRate: "3.7",
        shopCategory : "Groceries",
        shopType: "Groceries",
        shopImg : "product_img9.jpg",
        shopName : "The Aashirvaad Store", 
        shopDesc : "Oil, Rice, Organic Sugar... ",
        shopLocation : "l.s Road 5.3 Km",
    },
    {
        shopId : "shop10",
        shopLike : "",
        shopRate: "4.4",
        shopCategory : "Groceries",
        shopType: "Groceries",
        shopImg : "product_img10.jpg",
        shopName : "The Aashirvaad Store", 
        shopDesc : "Oil, Raise, Organic Sugar... ",
        shopLocation : "l.s Road 5.3 Km",
    },
    {
        shopId : "shop11",
        shopLike : "",
        shopRate: "5.0",
        shopCategory : "Groceries",
        shopType: "Groceries",
        shopImg : "product_img7.jpg",
        shopName : "The Bingo Shop", 
        shopDesc : "Burger & Wraps, Sides.. ",
        shopLocation : "l.s Road 5.3 Km",
    },
    {
        shopId : "shop12",
        shopLike : "",
        shopRate: "3.7",
        shopCategory : "Groceries",
        shopType: "Groceries",
        shopImg : "product_img8.jpg",
        shopName : "FreshFrout", 
        shopDesc : "Apple, Banana, Mango... ",
        shopLocation : "l.s Road 5.3 Km",
    },
]

export default apiData;