import React, { useEffect, useState } from 'react'
import { Location, Clock } from 'iconsax-react';
import { useLocation, useNavigate } from 'react-router-dom';
import moment from 'moment';
import { connect, useDispatch } from 'react-redux';
import { Button, Form, Input } from 'antd';
import EventService from '../../../services/events';
import PaymentSuccessfulModal from '../../../modals/PaymentSuccessfulModal';
import AddCardModal from '../../../modals/AddCardModal';
import PaymentFailedModal from '../../../modals/PaymentFailedModal';
import ConfirmModel from '../../../modals/ConfirmModel';
import ToastMe from '../../../common/ToastMe';
import UserSavedCard from '../Cart/UserSavedCard';
import StripeCheckout from 'react-stripe-checkout';
import PhoneInput from 'react-phone-input-2';
import DefaultImage from '../../../../assets/images/Event.svg';
const BaseUrl = process.env.REACT_APP_PROFILE_URL;

const EventDetails = (props) => {
	const userData = props.userData.userData;
	const { state } = useLocation();
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const [form] = Form.useForm();
	const [eventBook, setBookEventFlag] = useState(0);
	const [pay, setPayamount] = useState(0);
	const [payButtonFlag, setPayButtonFlag] = useState(0);
	const [addCardModalOpen, setAddCardModalOpen] = useState(false);
	const [paymentSuccessModalOpen, setPaymentSuccessModalOpen] = useState(false);
	const [paymentFailedModalOpen, setPaymentFailedModalOpen] = useState(false);
	const [eventDetail, setEventDetail] = useState({});
	const [cardData, setCardData] = useState([]);
	const [isSelected, setIsSelected] = useState({ payment_name: '', id: '' });
	const [stripeToken, setStripeToken] = useState('');
	const [paymentData, setPaymentData] = useState([]);
	const [removeData, setRemoveData] = useState();
	const [noOfPeoples, setNoOfPeoples] = useState();
	const [total, setTotal] = useState();
	const [deleteId, setDeleteId] = useState();
	const [ConfirmModalOpen, SetConfirmModalOpen] = useState(false);
	const [buttonLoading, setButtonLoading] = useState(false);
	const [phoneValue, setPhoneValue] = useState();

	const RedirectToLogin = () => {
		navigate('/login');

	}
	const bookEvent = () => {
		setBookEventFlag(!eventBook);
	}
	const noOfPeople = (e) => {
		setNoOfPeoples(e.target.value);
		setTotal(e.target.value * state?.offerPrice);
	}

	const onFinish = (data) => {
		data['event_id'] = state._id;
		data['source'] = isSelected.id;
		data['payment_type'] = 1;
		data['mobile'] = phoneValue;
		setPaymentData(data);
		setPayButtonFlag(1);
	};

	const getCard = () => {
		var myHeaders = new Headers();
		myHeaders.append("Authorization", `Bearer ${process.env.REACT_APP_STRIPE_SECRET}`);
		myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
		var requestOptions = { method: 'GET', headers: myHeaders, redirect: 'follow' };

		if (userData?.customer_id) {
			fetch('https://api.stripe.com/v1/customers/' + userData?.customer_id + '/sources', requestOptions)
				.then(response => response.text())
				.then((result) => {
					let responseData = JSON.parse(result).data;
					setCardData(responseData)
					setIsSelected({ ...isSelected, payment_name: 'card', id: responseData[0].id })
				})
				.catch(error => console.log('error', error));
		}
	}

	useEffect(() => {
		if (stripeToken) {
			var myHeaders = new Headers();
			myHeaders.append("Authorization", `Bearer ${process.env.REACT_APP_STRIPE_SECRET}`);
			myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

			var urlencoded = new URLSearchParams();
			urlencoded.append("source", stripeToken);

			var requestOptions = { method: 'POST', headers: myHeaders, body: urlencoded, redirect: 'follow' };

			fetch('https://api.stripe.com/v1/customers/' + userData?.customer_id + '/sources', requestOptions)
				.then((response) => {
					response.text();
					getCard();
				})
				.catch((error) => { console.log('error', error) });
		}
	}, [stripeToken])

	const onPaymentMethodClick = (payment_name, id) => {
		setIsSelected({ ...isSelected, payment_name: payment_name, id: id })
	}

	const onToken = (token) => setStripeToken(token.id)

	const placeOrder = () => {
		if (cardData.length > 0) {
			setButtonLoading(true)
			dispatch(EventService.bookEvent(paymentData))
				.then((res) => {
					setButtonLoading(false)
					setPaymentSuccessModalOpen(true);
					document.getElementById("event_book_form").reset();
				})
				.catch((errors) => {
					setButtonLoading(false)
					console.log({ errors })
				})
		} else {
			ToastMe('First you have to add your card', 'error');
		}

	}

	const confirmModalClose = () => {
		SetConfirmModalOpen(false);
	}

	useEffect(() => {
		getCard();
	}, [])

	useEffect(() => {
		setEventDetail(preState => ({ ...eventDetail, subTotal: state.offerPrice, persons: noOfPeoples, total: total }))
	}, [state, noOfPeoples, total])

	const RemoveDetails = () => {
		setRemoveData(1);
		setPaymentSuccessModalOpen(false)
		setNoOfPeoples('');
		setTotal('');
		navigate("/order-history", { state: { category: 3 } });
	}
	const handlePhoneValue = (e) => {
		setPhoneValue(e);
	};
	return (
		<>
			<section className='event_details_hero_section'>
				<div className='container'>
					<div className='banner_wrapper' style={{ backgroundImage: `url(${(state?.image ? BaseUrl + 'eventImage/' + state?.image : DefaultImage)})` }}>
						<div className='event_date_absolute'>
							<span className='numeric_value'>
								{moment(state.time).format("D")}
							</span>
							<span>
								{moment(state.time).format("MMM")}
							</span>
						</div>
					</div>
					<div className='text_content_bottom'>
						<div className=''>
							<h3>

								{state.name}
							</h3>
							<p>
								<span>
									<Clock size="18" color="#818181" />
									{moment(state.time).format('h:mm a')}
								</span>
								<span>
									<Location
										size="18"
										color="#818181"
									/>
									{state.city}
								</span>
							</p>
							<div className='price_numaric'>
								<span><span className='unvalide_price'><del>£{(state.price).toFixed(2)}</del></span>£{(state.offerPrice).toFixed(2)}<p>/per person</p></span>
							</div>
						</div>
						{localStorage.getItem('jwt_token') ?
							eventBook != 1 ?
								<button className='button button-2' onClick={bookEvent}>Book Event</button>
								: ''
							:
							<button className='button button-2' onClick={RedirectToLogin}>Book Event</button>
						}
					</div>
				</div>
			</section>
			<section className='event_details_about gap50'>
				<div className='container'>
					<div className='row'>
						<div className='col-lg-7 col-xl-8'>
							<div className='text_paragraph_wrapper'>
								<h4>About :-</h4>
								<p>{state.detail}</p>
							</div>
						</div>
						<div className='col-lg-5 col-xl-4' >
							{eventBook == 1 ?
								<div className='cart_sidebar sidebar_wrapper'>
									<div className='sidebar_widget'>
										<div className='form_wrapper'>
											<Form form={form} autoComplete="off" id="event_book_form" onFinish={onFinish}
												className="details_form"
											>
												{payButtonFlag != 1 ?
													<div className=''>
														<ul>
															<li>
																<label for="">Name</label>
																<Form.Item
																	name="name"
																	rules={[{ required: true, message: 'Please enter your name!' },
																	{ max: 30, message: 'You can not enter more than 30 characters' },
																	{ pattern: new RegExp("[a-zA-Z]+$"), message: 'Please enter only characters' }]}
																>
																	<Input className="input-control" />
																</Form.Item>
															</li>
															<li>
																<label for="">Mobile Number</label>
																<Form.Item
																	name="mobile"
																	rules={[{ required: true, message: 'Please enter your mobile number!' }]}
																>
																	<PhoneInput
																		country={'us'}
																		specialLabel="phone"
																		onChange={(e) => handlePhoneValue(e)}
																	/>
																</Form.Item>
															</li>
															<li>
																<label for="">Number of People</label>
																<Form.Item
																	name="no_of_people"
																	rules={[{ required: true, message: 'Please enter number of people' },
																	{ pattern: new RegExp("^[1-9]"), message: 'Number of people should be greater then 1.' }]}
																>
																	<Input type='number' max={5} onChange={(e) => noOfPeople(e)} className="input-control" />
																</Form.Item>
															</li>
															<li>
																<div className="billtotal_table_wrapper">
																	<table className="table mb30">
																		<tbody>
																			<tr>
																				<th>Event Charge</th>
																				<td>£{(state.offerPrice).toFixed(2)}</td>
																			</tr>
																			<tr>
																				<th>Person</th>
																				<td>{noOfPeoples ? noOfPeoples : 0}</td>
																			</tr>
																		</tbody>
																		<tfoot>
																			<tr>
																				<th>Total</th>
																				<td className='text_green'>£{total ? total.toFixed(2) : (state.offerPrice).toFixed(2)}</td>
																			</tr>
																		</tfoot>
																	</table>

																</div>
															</li>
														</ul>

														<button className="button button-2 w-100" type='submit'>Pay</button>
													</div>

													:
													removeData != 1 ?
														<div className="sidebar_widget">
															<h5 className='mb-4 fs18 fw600 lh_1_5'>Select Your Card</h5>
															<div className="custom_radio_wrap payment_method_card mb30">
																{
																	cardData && cardData.map((item, i) => {
																		return (
																			<UserSavedCard cardUserName={item.name} cardId={item.id} cardExpiryDate={`${item.exp_month}/${item.exp_year}`} cardLast4Digit={item.last4} cardBrand={item.brand} isSelected={isSelected} onCardClick={onPaymentMethodClick} index={i} />
																		)
																	})
																}
																<StripeCheckout
																	stripeKey={process.env.REACT_APP_STRIPE_KEY}
																	token={onToken}
																	email={userData?.email}
																	name={userData?.fullname}
																	panelLabel="Save Card"
																	style={{ color: '#fff', fontSize: 18 }}
																>
																	<div className="">
																		<input type="radio" className="radio_input" id='payment_card3' name="payment_radio" />
																		<label htmlFor="payment_card3" className="radio_label" onClick={() => setAddCardModalOpen(false)}>
																			<div className="payment_card_box flex_item_cs gap-3">
																				<img src={require("../../../../assets/images/icons/icon_add_card.svg").default} className="img-fluid" alt="" />
																				<p className="mb-0 fs18 fw500"> Add New Card</p>
																			</div>
																		</label>
																	</div>
																</StripeCheckout>
															</div>
															{/* <button className="button button-2 w-100" onClick={() => placeOrder()}>Pay</button> */}
															<Button onClick={() => placeOrder()} loading={buttonLoading} className="button button-2 w-100">
																Pay
															</Button>
														</div>
														:
														''
												}
											</Form>
										</div>

									</div>
								</div>
								:
								''
							}


						</div>
					</div>
				</div>
			</section>
			<PaymentFailedModal paymentFailedModalOpen={paymentFailedModalOpen} setPaymentFailedModalOpen={setPaymentFailedModalOpen} />
			<PaymentSuccessfulModal eventDetail={eventDetail} paymentSuccessModalOpen={paymentSuccessModalOpen} setPaymentSuccessModalOpen={setPaymentSuccessModalOpen} onClickBtn={() => RemoveDetails()} />
			<AddCardModal addCardModalOpen={addCardModalOpen} setAddCardModalOpen={setAddCardModalOpen} />
			<ConfirmModel ConfirmModalOpen={ConfirmModalOpen} ConfirmModalCloseEvent={confirmModalClose} />
		</>
	)
}

const mapStateToProps = state => {
	return {
		isAuthenticated: state.Auth.isAuthenticated,
		userData: state.Auth.userData
	}
}

export default connect(mapStateToProps)(EventDetails)