import React from 'react'

const HowitsWorkStep2 = () => {
    return (
        <div className="step_2">
            <div className="row align-items-center gy-4 flex-lg-row-reverse">
                <div className="col-lg-5">
                    <div className="img_content_resp text-center">
                        <img alt="img" className="mobile_img2 img-fluid" src={require('../../assets/images/illustartion_1_new.png')} />
                    </div>
                </div>
                <div className="col-lg-6 offset-lg-1">
                    <div className="text_content">
                        <h2 className="fs32 fw600 text_dark mb-3 maxw_60  lh_1_3">Find the Best Deal near you</h2>
                        <p className="fs16 fw500 text_dark mb-3">Search or browse categories to choose your preferred restaurants, grocery stores, or events from our extensive list. </p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default HowitsWorkStep2