import React, { createContext, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import SearchData from '../../common/SearchData';
import Footer from '../master/Footer';
import OrderFooter from './OrderFooter';
import OrderHeader from './OrderHeader';

export const orderMainContext = createContext(null)
const OrderMaster = (props) => {
    const { ptitle, path, title, description, keyword, module } = props;
    const [loading, setLoading] = useState(true);
    const searchData = props?.searchData;
    const [searchDatas, setSearchData] = useState(searchData);
    const [isAuthenticated, setIsAuthenticated] = useState(props.isAuthenticated);
    const location = useLocation();

    useEffect(() => {
        if (props.isAuthenticated == false) {
            setIsAuthenticated(false);
        }
    }, [props])

    useEffect(() => {
        setLoading(true);
        setTimeout(() => {
            setLoading(false);
        }, 1000);
    }, [ptitle]);

    useEffect(() => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth'
        });
    }, [location]);

    useEffect(() => {
        setSearchData(undefined)
    }, [location])

    useEffect(() => {
        setSearchData(searchData)
    }, [searchData])

    const navigate = useNavigate()
    return (
        <>
            <orderMainContext.Provider >
                <div className="main-wrapper">
                    {/* <Header handleLogoutLoader={handleLogoutLoader} /> */}
                    <OrderHeader />
                    <div className="main-content-sections">
                        {

                            searchDatas !== undefined ? (
                                navigate("search-result",{state:{}})
                            ) : (
                                <Outlet />
                            )
                        }

                    </div>
                    <OrderFooter />
                </div>
            </orderMainContext.Provider>
        </>
    )
}

const mapStateToProps = (state) => {
    return {
        isAuthenticated: state.Auth.isAuthenticated,
        searchData: state.Auth.searchData,
        // filter: state.filter
    };
};

export default connect(mapStateToProps)(OrderMaster);