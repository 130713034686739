import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { ArrowLeft2, ArrowRight2, Clock, Heart, Location } from "iconsax-react";
import Slider from "react-slick";
import restaurantService from "../../../services/restruants";
import eventService from "../../../services/events";
import { useDispatch } from "react-redux";
import { connect } from "react-redux";
import moment from "moment";
import { Empty,Input,Button } from "antd";
import groceryService from "../../../services/groceries";
import DefaultImage from "../../../../assets/images/default_image.jpg";
import PageLoader from "../../../common/PageLoader";
const BaseUrl = process.env.REACT_APP_PROFILE_URL;

const Orders = (props) => {
  const searchDatas = props?.searchData;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [restruants, setRestrunts] = useState([]);
  const [events, setEvents] = useState([]);
  const [charities, setCharities] = useState([]);
  const [groceries, setGroceries] = useState([]);
  const [favoriteData, setFavoriteData] = useState([]);
  const [specialOffer, setSpecialOffer] = useState([]);
  const [bestBargainsNearYou, setBestBargainsNearYou] = useState([]);
  const [familySavings, setFamilySavings] = useState([]);
  const [drinksAndMore, setDrinksAndMore] = useState([]);
  const [fastestFingers, setFastestFingers] = useState([]);
  const [search, setSearch] = useState("");
  const [filterDataNew, setfilterDataNew] = useState(null);
  const [loading, setLoding] = useState(true);
  const slider = useRef(null);

  function getWidthCont(params) {
    let containerOutWidth = document.getElementsByClassName('container');
    containerOutWidth = containerOutWidth[0].clientWidth

    let mainWidth = window.outerWidth
    mainWidth = ((mainWidth - containerOutWidth) / 2)

    let allSlider = document.querySelectorAll('.offers_slider_section .slick-track');
    allSlider.forEach(element => {
      element.style.paddingRight = `${mainWidth}px`
    });
  }

  setTimeout(() => {
    setLoding(false);
  }, [1000])

  useEffect(() => {
    getWidthCont()
    window.addEventListener('resize', (e) => {
      getWidthCont()
    })
  }, [])

  const getrestrunts = (category_id = "") => {
    dispatch(restaurantService.getRestaurants(category_id))
      .then((res) => {
        setRestrunts(res.data);
      })
      .catch((errors) => {
        console.log({ errors });
      });
  };

  const getGroceryies = (searchData) => {
    dispatch(groceryService.getGroceryies(searchData))
      .then((res) => {
        setGroceries(res.data);
      })
      .catch((errors) => {
        console.log({ errors });
      });
  };

  const getSpecialOffers = () => {
    const type = 5;
    dispatch(groceryService.getSpecialOffer(type))
      .then((res) => {
        setSpecialOffer(res.data);
      })
      .catch((errors) => {
        console.log({ errors });
      });
  };

  const getBestbargainsNearYou = () => {
    const type = 4;
    dispatch(groceryService.getSpecialOffer(type))
      .then((res) => {
        setBestBargainsNearYou(res.data);
      })
      .catch((errors) => {
        console.log({ errors });
      });
  };

  const getFamilySavings = () => {
    const type = 1;
    dispatch(groceryService.getSpecialOffer(type))
      .then((res) => {
        setFamilySavings(res.data);
      })
      .catch((errors) => {
        console.log({ errors });
      });
  };

  const getDrinksAndMore = () => {
    const type = 2;
    dispatch(groceryService.getSpecialOffer(type))
      .then((res) => {
        setDrinksAndMore(res.data);
      })
      .catch((errors) => {
        console.log({ errors });
      });
  };

  const getFastestFinger = () => {
    const type = 3;
    dispatch(groceryService.getSpecialOffer(type))
      .then((res) => {
        setFastestFingers(res.data);
      })
      .catch((errors) => {
        console.log({ errors });
      });
  };

  //events
  const getevents = (searchData) => {
    dispatch(eventService.getevents(searchData))
      .then((res) => {
        setEvents(res.data);
      })
      .catch((errors) => {
        console.log({ errors });
      });
  };

  // charity
  const getCharity = (searchData) => {
    dispatch(eventService.getCharity(searchData))
      .then((res) => {
        setCharities(res.data);
      })
      .catch((errors) => {
        console.log({ errors });
      });
  };

  //wishlist add
  const wishlistAdd = (data, isFound) => {
    var data_ = {};
    data_["vendor_id"] = data._id;
    data_["is_fav"] = isFound == false ? true : false;
    dispatch(restaurantService.addToFavorite(data_))
      .then((res) => {
        // ToastMe(res.data.message);
        getFavorite();
      })
      .catch((errors) => {
        console.log(errors);
      });
  };
  const getFavorite = () => {
    dispatch(restaurantService.getFavorite())
      .then((res) => {
        setFavoriteData(res.data);
      })
      .catch((errors) => {
        console.log({ errors });
      });
  };
  const RedirectToLogin = () => {
    navigate("/login");
  };

  const showCharityDetails = (id) => {
    dispatch(eventService.getCharityWithId(id))
      .then((res) => {
        navigate("/charity-details", { state: res.data });
      })
      .catch((errors) => {
        console.log({ errors });
      });
  };
  const showEventDetails = (id) => {
    dispatch(eventService.getEventById(id))
      .then((res) => {
        navigate("/event-details", { state: res.data });
      })
      .catch((errors) => {
        console.log({ errors });
      });
  };
  const filterData = (e) => {
    if (e) {
      
        setSearch(e.target.value)
    }
    let data = {
        // type:1,
        search: search,
    }
    // searchData.search = search
    dispatch(eventService.filterData(data))
        .then((res) => {
          // console.log("Filter data")
          // let filteredData ={[]};
          // let RfilteredData =[];
          // let GfilteredData =[];
          // if(res?.data?.restaurant?.length){
          //   RfilteredData = res?.data?.restaurant
          // }
          // if(res?.data?.grocery?.length){
          //   GfilteredData = res?.data?.grocery
          // }
       
          // filteredData = RfilteredData.concat(GfilteredData)
          console.log(res.data)
          setfilterDataNew(res.data)
        })
        .catch((errors) => {
            console.log({ errors })
        })
}
  useEffect(() => {
    getrestrunts();
    getevents();
    getCharity();
    getrestrunts();
    getGroceryies();
    getSpecialOffers();
    getFavorite();
    getBestbargainsNearYou();
    getFamilySavings();
    getDrinksAndMore();
    getFastestFinger();
    // navigate("/orders", {});
  }, []);

  function nearYou(e){
    const section = document.getElementById("#search");
    if (section) {
      window.scrollTo({
        top: section.offsetTop,
        behavior: 'smooth',
      });
    }
  }
  const ctgSliderSetting = {
    slidesToShow: 4,
    arrows: false,
    slidesToScroll: 1,
    // infinite: false,
    dots: false,
    responsive: [
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 3,
        },
      },
    ],
  };
  const singleSlideSetting = {
    slidesToShow: 1,
    arrows: false,
    slidesToScroll: 1,
    // infinite: false,
    draggable: false,
    speed: 300,
    dots: false,
  };
  const offerSliderSetting = {
    slidesToShow: 3,
    slidesToScroll: 1,
    infinite: false,
    arrows: false,
    draggable: false,
    dots: true,
    responsive: [
      {
        breakpoint: 1921,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 575,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };
  return (
    <>
    <PageLoader loading={loading} />
    <section className="section gap60 landing_section">
        <div className="container">
          <div className="landing_section_main">
            <h1 className="fs48 fw700 text-center lh_1_3 mb-4 maxw_60 mx-auto"><span className="text-white">Find The Best</span> Food, Groceries, Events <span className="text-white"> Around You</span></h1>
            <div className="search_item_form maxw_60 mb-4">
              <div className="form-group mb-0">
                <Input 
                onChange={(e) => filterData(e)}              
                placeholder="Search your Items" suffix={<Button className="button button-2" onClick={e=>nearYou(e)}><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M21.7043 20.3458L17.6523 16.2938L17.7726 16.1365C19.1289 14.3677 19.7625 12.1495 19.545 9.93115C19.3276 7.71277 18.2753 5.6599 16.6012 4.18814C14.9272 2.71638 12.7564 1.93569 10.5285 2.00415C8.30049 2.07261 6.18178 2.9851 4.60124 4.55686C3.0207 6.12861 2.09642 8.24221 2.01556 10.4698C1.93469 12.6973 2.70326 14.8724 4.16567 16.5546C5.62808 18.2368 7.67505 19.3005 9.89219 19.5304C12.1093 19.7602 14.331 19.139 16.1074 17.7925L16.2739 17.6722L20.3259 21.7242C20.5117 21.9013 20.7585 22 21.0151 22C21.2717 22 21.5185 21.9013 21.7043 21.7242C21.7952 21.6339 21.8673 21.5266 21.9166 21.4083C21.9658 21.29 21.9912 21.1631 21.9912 21.035C21.9912 20.9069 21.9658 20.78 21.9166 20.6617C21.8673 20.5434 21.7952 20.4361 21.7043 20.3458V20.3458ZM15.6263 15.6462C14.516 16.7619 13.0531 17.4579 11.4869 17.6155C9.92084 17.773 8.34859 17.3823 7.03836 16.5101C5.72813 15.6378 4.76112 14.338 4.30223 12.8324C3.84334 11.3267 3.92102 9.70849 4.52202 8.25373C5.12302 6.79897 6.21011 5.59779 7.59787 4.85504C8.98562 4.1123 10.5881 3.87403 12.1319 4.18086C13.6757 4.48768 15.0653 5.3206 16.0635 6.53755C17.0618 7.7545 17.607 9.28011 17.606 10.8541C17.607 12.6507 16.8949 14.3742 15.6263 15.6462V15.6462Z" fill="#333030" />
                </svg>
                </Button>} className="form-control" />
              </div>
            </div>
            <div className="order_ctg_filter_wrapper">
              <div className="row justify-content-center">
                <div className="col-lg-7 col-xl-7 col-xxl-6 col-md-10">
                  <div className="">
                    <div className="row">
                      <div className="col-4">
                        <div className="ctg_item">
                          <Link
                            role={"button"}
                            to="/categories"
                            state={{ flug: "food" }}>
                            <div className="ctg_icon mx-auto mb-3 bg_white">
                              <img
                                src={require("../../../../assets/images/BinoxHompageBurger.jpg")}
                                className="img-fluid"
                                alt="icn_food"
                              />
                            </div>
                            <div className="ctg_text">
                              <h4 className="mb-0 text-center fs18 text-white">Food</h4>
                            </div>
                          </Link>
                        </div>
                      </div>
                      <div className="col-4">
                        <div className="ctg_item">
                          <Link
                            role={"button"}
                            to="/categories"
                            state={{ flug: "grocery" }}>
                            <div className="ctg_icon mx-auto mb-3 bg_white">
                              <img
                                src={require("../../../../assets/images/basket.jpg")}
                                className="img-fluid"
                                alt="icn_food"
                              />
                            </div>
                            <div className="ctg_text">
                              <h4 className="mb-0 text-center fs18 text-white">Groceries</h4>
                            </div>
                          </Link>
                        </div>
                      </div>
                      <div className="col-4">
                        <div className="ctg_item">
                          <Link role={"button"} to="/events">
                            <div className="ctg_icon mx-auto mb-3 bg_white">
                              <img
                                src={require("../../../../assets/images/BBEventhomepageimage.jpg")}
                                className="img-fluid"
                                alt="icn_food"
                              />
                            </div>
                            <div className="ctg_text">
                              <h4 className="mb-0 text-center fs18 text-white">Event</h4>
                            </div>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
    </section>
      <section className="section gap60 offers_slider_section">
        <div className="custom_container">
          <div className="section_title">
            <h2>Special Offers</h2>
          </div>
          <div className="offers_slider_main text-center">
            <Slider
              {...offerSliderSetting}
              className="slide_gap30 custom_dots slider_flex">
              {specialOffer && specialOffer.length != 0 ? (
                specialOffer.map((data, index) => {
                  return (
                    <div className="offers_slider_item" key={index}>
                      <div
                        className="offers_card"
                        style={{
                          // backgroundImage: `url(${require("../../../../assets/images/offer_card_bg2.jpg")})` 
                          backgroundImage: `url(${BaseUrl + "advertisement/" + data.image})`
                        }}>
                        <div className="text_part">
                          <h3 className="fw600 mb20 text-white">
                            {data.title}
                            {/* <span className="text_orange">Pizza</span> <span className='text_green d-block fw600'>50% Off</span> */}
                          </h3>
                          {data?.vendor_data?.category_type === "3" ? (
                            <>
                              <p className="text-white">{data.description}</p>
                              <button
                                role="button"
                                className="button button-2"
                                onClick={() =>
                                  showEventDetails(data?.event_id)
                                }>
                                Explore Now
                              </button>
                            </>
                          ) : data?.vendor_data?.category_type === "2" ? (
                            <>
                              <p className="text-white">{data.description}</p>
                              <Link
                                role={"button"}
                                to={
                                  "/shop/" +
                                  data?.vendor_data?.businessName +
                                  ""
                                }
                                state={{
                                  shopDetails: data?.vendor_data,
                                  type: "grocery",
                                }}>
                                <button
                                  role="button"
                                  className="button button-2">
                                  Explore Now
                                </button>
                              </Link>
                            </>
                          ) : (
                            <Link
                              role={"button"}
                              to={
                                "/shop/" +
                                data?.vendor_data?.businessName +
                                ""
                              }
                              state={{
                                shopDetails: data?.vendor_data,
                                type: "food",
                              }}>
                              <>
                                <p className="text-white">{data.description}</p>
                                <button
                                  role="button"
                                  className="button button-2">
                                  Explore Now
                                </button>
                              </>
                            </Link>
                          )}
                        </div>
                        {/* <div className="image_part">
                                <img src={BaseUrl + "advertisement/" + data.image} className="img-fluid" alt="" />
                            </div> */}
                      </div>
                    </div>
                  );
                })
              ) : (
                <div>
                  <Empty
                    description={<p>Not Found</p>}
                    image={Empty.PRESENTED_IMAGE_SIMPLE}
                  />
                </div>
              )}
            </Slider>
          </div>
        </div>
      </section>
      <section className="section gap60 bg_lightgray" id="#search">
        <div className="container">
          <div className="section_title">
            <h2>Restaurants</h2>
          </div>
          <div className="products_grids_main">
            <div className="row">
              {!search && restruants && restruants.length != 0 ? (
                restruants.map((pData, ind) => {
                  const isFound = favoriteData?.some((element) => {
                    if (element._id == pData._id && element.is_fav == 1) {
                      return true;
                    }
                  });
                  return (
                    <div className="col-lg-4 col-6" key={ind}>
                      <div className="products_cards">
                        <div className="products_card_image mb20">
                          <Link
                            role={"button"}
                            to={"/shop/" + pData.businessName + ""}
                            state={{ shopDetails: pData, type: "food" }}>
                            <img
                              src={
                                pData.image != null
                                  ? BaseUrl + "vendor/" + pData.image
                                  : DefaultImage
                              }
                              height="100px"
                              width="100px"
                              className="img-fluid"
                            />
                          </Link>
                          <div className="product_action">
                            {localStorage.getItem("jwt_token") ? (
                              <a
                                role={"buttton"}
                                onClick={(e) => wishlistAdd(pData, isFound)}
                                className="wishlist_icon">
                                {isFound === true ? (
                                  <Heart
                                    size="16"
                                    color="#f0140c"
                                    variant="Bold"
                                  />
                                ) : (
                                  <Heart
                                    size="16"
                                    color="#AAAAAA"
                                    variant="Bold"
                                  />
                                )}
                              </a>
                            ) : (
                              <a
                                role={"buttton"}
                                onClick={RedirectToLogin}
                                className="wishlist_icon">
                                <Heart
                                  size="16"
                                  color="#AAAAAA"
                                  variant="Bold"
                                />
                              </a>
                            )}
                          </div>
                        </div>
                        <div className="products_card_text flex_item_sb gap-2">
                          <div className="left_part">
                            <h5 className="fs20">
                              <Link
                                to={"/shop/" + pData.businessName + ""}
                                state={{
                                  shopDetails: pData,
                                  type: "food",
                                }}>
                                {pData.businessName}
                              </Link>
                            </h5>
                            <p>{pData.shopDesc}</p>
                            <p className="flex_item_cs gap-2">
                              <Location size="20px" color="#818181" />{" "}
                              {pData.businessAddress}
                            </p>
                          </div>
                          <div className="right_part">
                            <div className="rating_badge flex_item_cc gap-1">
                              <img
                                src={
                                  require("../../../../assets/images/icons/icn_rate.svg")
                                    .default
                                }
                                className="img-fluid"
                                alt=""
                              />
                              <p>{pData.rating}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })
              ) : 
              search && filterDataNew && filterDataNew?.restaurant.length != 0 ? (
                filterDataNew?.restaurant.map((pData, ind) => {
                  const isFound = favoriteData?.some((element) => {
                    if (element._id == pData._id && element.is_fav == 1) {
                      return true;
                    }
                  });
                  return (
                    <div className="col-lg-4 col-6" key={ind}>
                      <div className="products_cards">
                        <div className="products_card_image mb20">
                          <Link
                            role={"button"}
                            to={"/shop/" + pData.businessName + ""}
                            state={{ shopDetails: pData, type: "food" }}>
                            <img
                              src={
                                pData.image != null
                                  ? BaseUrl + "vendor/" + pData.image
                                  : DefaultImage
                              }
                              height="100px"
                              width="100px"
                              className="img-fluid"
                            />
                          </Link>
                          <div className="product_action">
                            {localStorage.getItem("jwt_token") ? (
                              <a
                                role={"buttton"}
                                onClick={(e) => wishlistAdd(pData, isFound)}
                                className="wishlist_icon">
                                {isFound === true ? (
                                  <Heart
                                    size="16"
                                    color="#f0140c"
                                    variant="Bold"
                                  />
                                ) : (
                                  <Heart
                                    size="16"
                                    color="#AAAAAA"
                                    variant="Bold"
                                  />
                                )}
                              </a>
                            ) : (
                              <a
                                role={"buttton"}
                                onClick={RedirectToLogin}
                                className="wishlist_icon">
                                <Heart
                                  size="16"
                                  color="#AAAAAA"
                                  variant="Bold"
                                />
                              </a>
                            )}
                          </div>
                        </div>
                        <div className="products_card_text flex_item_sb gap-2">
                          <div className="left_part">
                            <h5 className="fs20">
                              <Link
                                to={"/shop/" + pData.businessName + ""}
                                state={{
                                  shopDetails: pData,
                                  type: "food",
                                }}>
                                {pData.businessName}
                              </Link>
                            </h5>
                            <p>{pData.shopDesc}</p>
                            <p className="flex_item_cs gap-2">
                              <Location size="20px" color="#818181" />{" "}
                              {pData.businessAddress}
                            </p>
                          </div>
                          <div className="right_part">
                            <div className="rating_badge flex_item_cc gap-1">
                              <img
                                src={
                                  require("../../../../assets/images/icons/icn_rate.svg")
                                    .default
                                }
                                className="img-fluid"
                                alt=""
                              />
                              <p>{pData.rating}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })):
              (
                <div>
                  <Empty
                    description={<p>Not Found</p>}
                    image={Empty.PRESENTED_IMAGE_SIMPLE}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </section>
      <section className="section gap60 offers_slider_section">
        <div className="custom_container">
          <div className="section_title">
            <h2>Family Savings</h2>
          </div>
          <div className="offers_slider_main">
            <Slider
              {...offerSliderSetting}
              className="slide_gap30 custom_dots slider_flex">
              {familySavings && familySavings.length != 0 ? (
                familySavings.map((data, index) => {
                  return (
                    <div className="offers_slider_item" key={index}>
                      <div
                        className="offers_card"
                        style={{
                          backgroundImage: `url(${BaseUrl + "advertisement/" + data.image
                            })`,
                        }}>
                        <div className="text_part">
                          <h3 className="fw600 mb20 text-white">
                            {data.title}
                            {/* <span className="text_orange">Pizza</span> <span className='text_green d-block fw600'>50% Off</span> */}
                          </h3>
                          {data?.vendor_data?.category_type === "3" ? (
                            <>
                              <p className="text-white">{data.description}</p>
                              <button
                                role="button"
                                className="button button-2"
                                onClick={() =>
                                  showEventDetails(data?.event_id)
                                }>
                                Explore Now
                              </button>
                            </>
                          ) : data?.vendor_data?.category_type === "2" ? (
                            <>
                              <p className="text-white">{data.description}</p>
                              <Link
                                role={"button"}
                                to={
                                  "/shop/" +
                                  data?.vendor_data?.businessName +
                                  ""
                                }
                                state={{
                                  shopDetails: data?.vendor_data,
                                  type: "grocery",
                                }}>
                                <button
                                  role="button"
                                  className="button button-2">
                                  Explore Now
                                </button>
                              </Link>
                            </>
                          ) : (
                            <Link
                              role={"button"}
                              to={
                                "/shop/" +
                                data?.vendor_data?.businessName +
                                ""
                              }
                              state={{
                                shopDetails: data?.vendor_data,
                                type: "food",
                              }}>
                              <>
                                <p className="text-white">{data.description}</p>
                                <button
                                  role="button"
                                  className="button button-2">
                                  Explore Now
                                </button>
                              </>
                            </Link>
                          )}
                        </div>
                        {/* <div className="image_part">
                                                <img src={require("../../../../assets/images/offer_1.png")} className="img-fluid" alt="" />
                                            </div> */}
                      </div>
                    </div>
                  );
                })
              ) : (
                <div>
                  <Empty
                    description={<p>Not Found</p>}
                    image={Empty.PRESENTED_IMAGE_SIMPLE}
                  />
                </div>
              )}
            </Slider>
          </div>
        </div>
      </section>
      <section className="section gap60 offers_slider_section">
        <div className="custom_container">
          <div className="section_title">
            <h2>Drinks & More</h2>
          </div>
          <div className="offers_slider_main">
            <Slider
              {...offerSliderSetting}
              className="slide_gap30 custom_dots slider_flex">
              {drinksAndMore && drinksAndMore.length != 0 ? (
                drinksAndMore.map((data, index) => {
                  return (
                    <div className="offers_slider_item" key={index}>
                      <div
                        className="offers_card"
                        style={{
                          backgroundImage: `url(${BaseUrl + "advertisement/" + data.image
                            })`,
                        }}>
                        <div className="text_part">
                          <h3 className="fw600 mb20 text-white">
                            {data.title}
                            {/* <span className="text_orange">Pizza</span> <span className='text_green d-block fw600'>50% Off</span> */}
                          </h3>
                          {data?.vendor_data?.category_type === "3" ? (
                            <>
                              <p className="text-white">{data.description}</p>
                              <button
                                role="button"
                                className="button button-2"
                                onClick={() =>
                                  showEventDetails(data?.event_id)
                                }>
                                Explore Now
                              </button>
                            </>
                          ) : data?.vendor_data?.category_type === "2" ? (
                            <>
                              <p className="text-white">{data.description}</p>
                              <Link
                                role={"button"}
                                to={
                                  "/shop/" +
                                  data?.vendor_data?.businessName +
                                  ""
                                }
                                state={{
                                  shopDetails: data?.vendor_data,
                                  type: "grocery",
                                }}>
                                <button
                                  role="button"
                                  className="button button-2">
                                  Explore Now
                                </button>
                              </Link>
                            </>
                          ) : (
                            <Link
                              role={"button"}
                              to={
                                "/shop/" +
                                data?.vendor_data?.businessName +
                                ""
                              }
                              state={{
                                shopDetails: data?.vendor_data,
                                type: "food",
                              }}>
                              <>
                                <p className="text-white">{data.description}</p>
                                <button
                                  role="button"
                                  className="button button-2">
                                  Explore Now
                                </button>
                              </>
                            </Link>
                          )}
                        </div>
                        {/* <div className="image_part">
                                                <img src={require("../../../../assets/images/offer_1.png")} className="img-fluid" alt="" />
                                            </div> */}
                      </div>
                    </div>
                  );
                })
              ) : (
                <div>
                  <Empty
                    description={<p>Not Found</p>}
                    image={Empty.PRESENTED_IMAGE_SIMPLE}
                  />
                </div>
              )}
            </Slider>
          </div>
        </div>
      </section>
      <section className="section gap60">
        <div className="container">
          <div className="section_title">
            <h2>Groceries</h2>
          </div>
          <div className="products_grids_main">
            <div className="row">
              {!search && groceries && groceries.length > 0 ?
                groceries.map((pData, ind) => {
                  const isFound = favoriteData?.some((element) => {
                    if (element._id == pData._id && element.is_fav == 1) {
                      return true;
                    }
                  });
                  return (
                    <div className="col-lg-4 col-6" key={ind}>
                      <div className="products_cards">
                        <div className="products_card_image mb20">
                          <Link
                            role={"button"}
                            to={"/shop/" + pData.businessName + ""}
                            state={{
                              shopDetails: pData,
                              type: "grocery",
                            }}>
                            <img
                              src={
                                pData.image != null
                                  ? BaseUrl + "vendor/" + pData.image
                                  : DefaultImage
                              }
                              className="img-fluid"
                              alt=""
                            />
                          </Link>
                          <div className="product_action">
                            {localStorage.getItem("jwt_token") ? (
                              <a
                                role={"buttton"}
                                onClick={(e) =>
                                  wishlistAdd(pData, isFound)
                                }
                                className="wishlist_icon">
                                {isFound === true ? (
                                  <Heart
                                    size="16"
                                    color="#f0140c"
                                    variant="Bold"
                                  />
                                ) : (
                                  <Heart
                                    size="16"
                                    color="#AAAAAA"
                                    variant="Bold"
                                  />
                                )}
                              </a>
                            ) : (
                              <a
                                role={"buttton"}
                                onClick={RedirectToLogin}
                                className="wishlist_icon">
                                <Heart
                                  size="16"
                                  color="#AAAAAA"
                                  variant="Bold"
                                />
                              </a>
                            )}
                          </div>
                        </div>
                        <div className="products_card_text flex_item_sb gap-2">
                          <div className="left_part">
                            <h5 className="fs20">
                              <Link
                                to={"/shop/" + pData.businessName + ""}
                                state={{
                                  shopDetails: pData,
                                  type: "grocery",
                                }}>
                                {pData.businessName}
                              </Link>
                            </h5>
                            <p>{pData.shopDesc}</p>
                            <p className="flex_item_ss gap-2">
                              <Location size="20px" color="#818181" />{" "}
                              {pData.businessAddress}
                            </p>
                          </div>
                          <div className="right_part">
                            <div className="rating_badge flex_item_cc gap-1">
                              <img
                                src={
                                  require("../../../../assets/images/icons/icn_rate.svg")
                                    .default
                                }
                                className="img-fluid"
                                alt=""
                              />
                              <p>{pData.rating}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                }) : 
                search && filterDataNew && filterDataNew?.grocery.length != 0 ?
                filterDataNew?.grocery.map((pData, ind) => {
                  const isFound = favoriteData?.some((element) => {
                    if (element._id == pData._id && element.is_fav == 1) {
                      return true;
                    }
                  });
                  return (
                    <div className="col-lg-4 col-6" key={ind}>
                      <div className="products_cards">
                        <div className="products_card_image mb20">
                          <Link
                            role={"button"}
                            to={"/shop/" + pData.businessName + ""}
                            state={{
                              shopDetails: pData,
                              type: "grocery",
                            }}>
                            <img
                              src={
                                pData.image != null
                                  ? BaseUrl + "vendor/" + pData.image
                                  : DefaultImage
                              }
                              className="img-fluid"
                              alt=""
                            />
                          </Link>
                          <div className="product_action">
                            {localStorage.getItem("jwt_token") ? (
                              <a
                                role={"buttton"}
                                onClick={(e) =>
                                  wishlistAdd(pData, isFound)
                                }
                                className="wishlist_icon">
                                {isFound === true ? (
                                  <Heart
                                    size="16"
                                    color="#f0140c"
                                    variant="Bold"
                                  />
                                ) : (
                                  <Heart
                                    size="16"
                                    color="#AAAAAA"
                                    variant="Bold"
                                  />
                                )}
                              </a>
                            ) : (
                              <a
                                role={"buttton"}
                                onClick={RedirectToLogin}
                                className="wishlist_icon">
                                <Heart
                                  size="16"
                                  color="#AAAAAA"
                                  variant="Bold"
                                />
                              </a>
                            )}
                          </div>
                        </div>
                        <div className="products_card_text flex_item_sb gap-2">
                          <div className="left_part">
                            <h5 className="fs20">
                              <Link
                                to={"/shop/" + pData.businessName + ""}
                                state={{
                                  shopDetails: pData,
                                  type: "grocery",
                                }}>
                                {pData.businessName}
                              </Link>
                            </h5>
                            <p>{pData.shopDesc}</p>
                            <p className="flex_item_ss gap-2">
                              <Location size="20px" color="#818181" />{" "}
                              {pData.businessAddress}
                            </p>
                          </div>
                          <div className="right_part">
                            <div className="rating_badge flex_item_cc gap-1">
                              <img
                                src={
                                  require("../../../../assets/images/icons/icn_rate.svg")
                                    .default
                                }
                                className="img-fluid"
                                alt=""
                              />
                              <p>{pData.rating}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })
                :
                (
                  <div>
                    <Empty
                      description={<p>Not Found</p>}
                      image={Empty.PRESENTED_IMAGE_SIMPLE}
                    />
                  </div>
                )}
            </div>
          </div>
        </div>
      </section>
      <section className="section gap60 offers_slider_section">
        <div className="custom_container">
          <div className="section_title">
            <h2>Fastest Fingers</h2>
          </div>
          <div className="offers_slider_main">
            <Slider
              {...offerSliderSetting}
              className="slide_gap30 custom_dots slider_flex">
              {fastestFingers && fastestFingers.length != 0 ? (
                fastestFingers.map((data, index) => {
                  return (
                    <div className="offers_slider_item" key={index}>
                      <div
                        className="offers_card"
                        style={{
                          backgroundImage: `url(${BaseUrl + "advertisement/" + data.image
                            })`,
                        }}>
                        <div className="text_part">
                          <h3 className="fw600 mb20 text-white">
                            {data.title}
                            {/* <span className="text_orange">Pizza</span> <span className='text_green d-block fw600'>50% Off</span> */}
                          </h3>
                          {data?.vendor_data?.category_type === "3" ? (
                            <>
                              <p className="text-white">{data.description}</p>
                              <button
                                role="button"
                                className="button button-2"
                                onClick={() =>
                                  showEventDetails(data?.event_id)
                                }>
                                Explore Now
                              </button>
                            </>
                          ) : data?.vendor_data?.category_type === "2" ? (
                            <>
                              <p className="text-white">{data.description}</p>
                              <Link
                                role={"button"}
                                to={
                                  "/shop/" +
                                  data?.vendor_data?.businessName +
                                  ""
                                }
                                state={{
                                  shopDetails: data?.vendor_data,
                                  type: "grocery",
                                }}>
                                <button
                                  role="button"
                                  className="button button-2">
                                  Explore Now
                                </button>
                              </Link>
                            </>
                          ) : (
                            <Link
                              role={"button"}
                              to={
                                "/shop/" +
                                data?.vendor_data?.businessName +
                                ""
                              }
                              state={{
                                shopDetails: data?.vendor_data,
                                type: "food",
                              }}>
                              <>
                                <p className="text-white">{data.description}</p>
                                <button
                                  role="button"
                                  className="button button-2">
                                  Explore Now
                                </button>
                              </>
                            </Link>
                          )}
                        </div>
                        {/* <div className="image_part">
                                                <img src={require("../../../../assets/images/offer_1.png")} className="img-fluid" alt="" />
                                            </div> */}
                      </div>
                    </div>
                  );
                })
              ) : (
                <div>
                  <Empty
                    description={<p>Not Found</p>}
                    image={Empty.PRESENTED_IMAGE_SIMPLE}
                  />
                </div>
              )}
            </Slider>
          </div>
        </div>
      </section>
      <section className="section gap60 offers_slider_section">
        <div className="custom_container">
          <div className="section_title">
            <h2>Best Bargains Near You</h2>
          </div>
          <div className="offers_slider_main">
            <Slider
              {...offerSliderSetting}
              className="slide_gap30 custom_dots slider_flex">
              {bestBargainsNearYou && bestBargainsNearYou.length != 0 ? (
                bestBargainsNearYou.map((data, indxe) => {
                  return (
                    <div className="offers_slider_item">
                      <div
                        className="offers_card"
                        style={{
                          backgroundImage: `url(${BaseUrl + "advertisement/" + data.image
                            })`,
                        }}>
                        <div className="text_part">
                          <h3 className="fw600 mb20 text-white">
                            {data.title}
                            {/* <span className="text_orange">Pizza</span> <span className='text_green d-block fw600'>50% Off</span> */}
                          </h3>
                          {data?.vendor_data?.category_type === "3" ? (
                            <>
                              <p className="text-white">{data.description}</p>
                              <button
                                role="button"
                                className="button button-2"
                                onClick={() =>
                                  showEventDetails(data?.event_id)
                                }>
                                Explore Now
                              </button>
                            </>
                          ) : data?.vendor_data?.category_type === "2" ? (
                            <>
                              <p className="text-white">{data.description}</p>
                              <Link
                                role={"button"}
                                to={
                                  "/shop/" +
                                  data?.vendor_data?.businessName +
                                  ""
                                }
                                state={{
                                  shopDetails: data?.vendor_data,
                                  type: "grocery",
                                }}>
                                <button
                                  role="button"
                                  className="button button-2">
                                  Explore Now
                                </button>
                              </Link>
                            </>
                          ) : (
                            <Link
                              role={"button"}
                              to={
                                "/shop/" +
                                data?.vendor_data?.businessName +
                                ""
                              }
                              state={{
                                shopDetails: data?.vendor_data,
                                type: "food",
                              }}>
                              <>
                                <p className="text-white">{data.description}</p>
                                <button
                                  role="button"
                                  className="button button-2">
                                  Explore Now
                                </button>
                              </>
                            </Link>
                          )}
                        </div>
                        <div className="image_part">
                          <img
                            src={require("../../../../assets/images/offer_1.png")}
                            className="img-fluid"
                            alt=""
                          />
                        </div>
                      </div>
                    </div>
                  );
                })
              ) : (
                <div>
                  <Empty
                    description={<p>Not Found</p>}
                    image={Empty.PRESENTED_IMAGE_SIMPLE}
                  />
                </div>
              )}
            </Slider>
          </div>
        </div>
      </section>
      <section className="section gap60 offers_slider_section">
        <div className="custom_container">
          <div className="section_title">
            <h2>Charity</h2>
          </div>
          <div className="offers_slider_main">
            <Slider
              {...offerSliderSetting}
              className="slide_gap30 custom_dots slider_flex">
              {!search && charities.length != 0 ? (
                charities.map((data, index) => {
                  return (
                    // <div key={index}>
                    //     <div className="offers_slider_item" onClick={() => showCharityDetails(data._id)}>
                    //         <div className="offers_card p-0">
                    //             <div className="d-block w-100" role="button">
                    //                 <img src={(BaseUrl + 'ngoItems/' + data.image)} className="img-fluid w-100"/></div>
                    //         </div>
                    //     </div>
                    // </div>
                    <div className="offers_slider_item">
                      <div
                        className="offers_card"
                        style={{
                          backgroundImage: `url(${BaseUrl + "ngoItems/" + data.image
                            })`,
                        }}>
                        <div className="text_part">
                          <h3 className="fw600 mb20 text-white">
                            {data.title}
                          </h3>
                          <p className="text-white">{data.description}</p>
                          <button
                            role="button"
                            className="button button-2"
                            onClick={() => showCharityDetails(data._id)}>
                            Donate
                          </button>
                        </div>
                        {/* <div className="image_part">
                                                    <img src={(BaseUrl + 'ngoItems/' + data.image)} className="img-fluid" alt="" />
                                                </div> */}
                      </div>
                    </div>
                  );
                })
              ) : 
              search && filterDataNew && filterDataNew?.NGo.length != 0 ? (
                filterDataNew?.NGo.map((data, index) => {
                  return (
                    // <div key={index}>
                    //     <div className="offers_slider_item" onClick={() => showCharityDetails(data._id)}>
                    //         <div className="offers_card p-0">
                    //             <div className="d-block w-100" role="button">
                    //                 <img src={(BaseUrl + 'ngoItems/' + data.image)} className="img-fluid w-100"/></div>
                    //         </div>
                    //     </div>
                    // </div>
                    <div className="offers_slider_item">
                      <div
                        className="offers_card"
                        style={{
                          backgroundImage: `url(${BaseUrl + "ngoItems/" + data.image
                            })`,
                        }}>
                        <div className="text_part">
                          <h3 className="fw600 mb20 text-white">
                            {data.title}
                          </h3>
                          <p className="text-white">{data.description}</p>
                          <button
                            role="button"
                            className="button button-2"
                            onClick={() => showCharityDetails(data._id)}>
                            Donate
                          </button>
                        </div>
                        {/* <div className="image_part">
                                                    <img src={(BaseUrl + 'ngoItems/' + data.image)} className="img-fluid" alt="" />
                                                </div> */}
                      </div>
                    </div>
                  );
                })
              )
              :
              (
                <div>
                  <Empty
                    description={<p>Charity Not Found</p>}
                    image={Empty.PRESENTED_IMAGE_SIMPLE}
                  />
                </div>
              )}
            </Slider>
          </div>
        </div>
      </section>
      <section className="section gap60 no-top">
        <div className="container">
          <div className="section_title flex_item_sb">
            <h2>Events</h2>
            <div className="custom_nav_arrows d-lg-none d-flex">
              <button
                className="custom_nav_arrow_left"
                onClick={() => slider?.current?.slickPrev()}>
                <ArrowLeft2 size="28" color="#000000" variant="Bold" />
              </button>
              <button className="custom_nav_arrow_right">
                <ArrowRight2
                  size="28"
                  color="#000000"
                  variant="Bold"
                  onClick={() => slider?.current?.slickNext()}
                />
              </button>
              {/* <div className='slick_custom_arrow flex_item_cc'>
                                    <a role={"button"} className='arrow_icon' onClick={() => slider?.current?.slickPrev()}><i className="ri-arrow-left-line"></i></a>
                                    <a role={"button"} className='arrow_icon' onClick={() => slider?.current?.slickNext()}><i className="ri-arrow-right-line"></i></a>
                                </div> */}
            </div>
          </div>
          <div className="custom_slider_wrapper">
            <div className="custom_nav_arrows d-lg-block d-none">
              <button
                className="custom_nav_arrow_left"
                onClick={() => slider?.current?.slickPrev()}>
                <ArrowLeft2 size="28" color="#000000" variant="Bold" />
              </button>
              <button className="custom_nav_arrow_right">
                <ArrowRight2
                  size="28"
                  color="#000000"
                  variant="Bold"
                  onClick={() => slider?.current?.slickNext()}
                />
              </button>
              {/* <div className='slick_custom_arrow flex_item_cc'>
                                    <a role={"button"} className='arrow_icon' onClick={() => slider?.current?.slickPrev()}><i className="ri-arrow-left-line"></i></a>
                                    <a role={"button"} className='arrow_icon' onClick={() => slider?.current?.slickNext()}><i className="ri-arrow-right-line"></i></a>
                                </div> */}
            </div>
            <div className="single_slide_slider_wrapper">
              <Slider
                {...singleSlideSetting}
                className="slider_flex"
                ref={slider}>
                {!search && events.length != 0 ? (
                  events.map((data, index) => {
                    return (
                      <Link
                        onClick={() => showEventDetails(data._id)}
                        key={index}>
                        <div
                          className="individual_slide_linear_bg"
                          style={{
                            backgroundImage: `url(${BaseUrl + "eventImage/" + data.image
                              })`,
                          }}>
                          <div className="text_content">
                            <div className="right_text">
                              <h4>{data.name}</h4>
                              <p>
                                <span>
                                  <Clock size="18" color="#ffffff" />
                                  {moment(data.time).format("h:mm a")}
                                </span>
                                <span>
                                  <Location size="18" color="#ffffff" />
                                  {data.city}
                                </span>
                              </p>
                            </div>
                            <p className="left_text">
                              <span>
                                <del>£{data.price?.toFixed(2)}</del>
                              </span>
                              <span>£{data.offerPrice?.toFixed(2)}</span>
                            </p>
                          </div>
                        </div>
                      </Link>
                    );
                  })
                ) : 
                search && filterDataNew && filterDataNew?.event.length != 0 ? (
                  filterDataNew?.event.map((data, index) => {
                    return (
                      <Link
                        onClick={() => showEventDetails(data._id)}
                        key={index}>
                        <div
                          className="individual_slide_linear_bg"
                          style={{
                            backgroundImage: `url(${BaseUrl + "eventImage/" + data.image
                              })`,
                          }}>
                          <div className="text_content">
                            <div className="right_text">
                              <h4>{data.name}</h4>
                              <p>
                                <span>
                                  <Clock size="18" color="#ffffff" />
                                  {moment(data.time).format("h:mm a")}
                                </span>
                                <span>
                                  <Location size="18" color="#ffffff" />
                                  {data.city}
                                </span>
                              </p>
                            </div>
                            <p className="left_text">
                              <span>
                                <del>£{data.price?.toFixed(2)}</del>
                              </span>
                              <span>£{data.offerPrice?.toFixed(2)}</span>
                            </p>
                          </div>
                        </div>
                      </Link>
                    );
                  })
                )
                :
                (
                  <div>
                    <Empty
                      description={<p>Events Not Found</p>}
                      image={Empty.PRESENTED_IMAGE_SIMPLE}
                    />
                  </div>
                )}
              </Slider>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    isAuthenticated: state.Auth.isAuthenticated,
    isVerify: state.Auth.isVerify,
    needToVerify: state.Auth.needToVerify,
    isAdministrator: state.Auth.isAdministrator,
    searchData: state.Auth.searchData,
  };
};

export default connect(mapStateToProps)(Orders);
